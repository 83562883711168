import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import {css} from 'aphrodite';

import {Recipient} from './Recipient';
import {AlimtalkPreview} from './AlimtalkPreview';
import {DefaultButton} from '../../shared/components/DefaultButton';
import {NoticePopup} from '../../shared/components/NoticePopup';
import Loading from '../../shared/components/Loading';
import {CreateBetaStyle} from '../../shared/CreateStyle';
import {getError} from '../helper/ServerErrorPolicy';

import {
  getAlimtalkSendInput,
  IAlimtalkInputProps,
  ITemplateInfo,
  sendAlimtalk,
  updateStatusByRecordId
} from '../services/AlimtalkService';

export const AlimtalkView = () => {
  const [status, setStatus] = useState({
    status: 'loading',
    message: '',
  })
  const [recordId, setRecordId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [values, setValues] = useState<string[]>([]);
  const [afterStatus, setAfterStatus] = useState('');
  const [templateCode, setTemplateCode] = useState('');
  const [isPopupOn, setIsPopupOn] = useState(false);

  const today = moment().format('YYYY년 MM월 DD일');

  const {id} = useParams<{ id: string }>();

  useEffect(() => {
    if (id === '' || id == null) {
      setStatus({
        status: 'error',
        message: '레코드 아이디가 없습니다',
      })
      return;
    }
    setRecordId(id);
    setIsLoading(true);
    getAlimtalkSendInput(id ?? '')
        .then((result?: IAlimtalkInputProps) => {
          setName(result?.name ?? '');
          setPhone(result?.phone ?? '');
          setValues(result?.values ?? []);
          setTemplateCode(result?.code ?? '');
          setAfterStatus(result?.afterStatus ?? '');
          setTemplate(result?.template ?? {});
        }).catch((e) => {
      setStatus({
        status: 'error',
        message: getError(e)
      });
      setIsPopupOn(true);
    }).finally(() => setIsLoading(false));
  }, [id])

  const [template, setTemplate] = useState<ITemplateInfo | any>({})

  const onClickSend = async () => {
    if(templateCode === undefined || templateCode === null || templateCode === '') {
      setStatus({
        status: 'error',
        message: '설정된 템플릿이 없습니다.'
      });
      setIsPopupOn(true);
      return;
    }

    try {
      setIsLoading(true)
      await sendAlimtalk({
        templateCode,
        phone,
        value: values,
      })
      setStatus({
        status: 'success',
        message: '발송완료🥳',
      })
      await updateStatusByRecordId({recordId, status: afterStatus})
    } catch (e: any) {
      if (e.error === 'INVALID_MULTIPLE_CHOICE_OPTIONS') {
        setStatus({status: 'error', message: `알림톡 발송 완료\n진행상태 변경 실패\n(${afterStatus})`})
        return;
      }
      setStatus({status: 'error', message: e.message})
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {
        status.status !== 'loading'
          && isPopupOn
          && <NoticePopup content={status.message} onClickConfirm={() => setIsPopupOn(false)}/>
      }
      {isLoading && <Loading/>}
      <div className={css(styles.alimtalkView)}>
        <Recipient
            title={'알림톡 발송'}
            name={name}
            phone={phone}
        />
        <div className={css(styles.preview)}>
          <div className={css(styles.previewTitle)}>메시지 미리보기</div>
          <div className={css(styles.kakaoPreviewFrame)}>
            <div className={css(styles.kakaoPreview)} id={'preview'}>
              <div className={css(styles.kakaoPreviewDateBox)}>
                <div className={css(styles.dateLine)}/>
                {today}
                <div className={css(styles.dateLine)}/>
              </div>
              <AlimtalkPreview template={template}/>
            </div>
          </div>
          {DefaultButton(
            {
              title: status.status === 'success' ? '발송완료!' : '발송하기',
              onClick: () => onClickSend(),
              success: status.status === 'success'
            })}
        </div>
      </div>
    </>
  )
}

const styles = CreateBetaStyle({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '15px'
  },
  alimtalkView: {
    width: '80vw',
    height: '95vh',

    borderRadius: '10px',

    backgroundColor: 'white',

    boxSizing: 'border-box',

    padding: '46px 60px',

    fontFamily: 'Pretendard',

    display: 'flex',
  },
  preview: {
    marginTop: '15px',
  },
  previewTitle: {
    color: '#666973',
    fontSize: '15px',
    fontWeight: 500,

    marginBottom: '16px',
  },
  kakaoPreviewFrame: {
    width: '400px',
    height: '650px',

    position: 'relative',

    marginBottom: '21px',
  },
  kakaoPreview: {
    width: '100%',
    height: '100%',

    backgroundColor: '#B9CBDA',

    borderRadius: '15px',

    paddingTop: '30px',
    // paddingBottom: '32px',

    boxSizing: 'border-box',

    // position: 'relative',

    overflowY: 'scroll',

    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: 'rgba(69, 79, 93, 0.9)',
      borderRadius: '15px',
      border: '3px solid transparent',
    },
    '::-webkit-scrollbar-track': {
      padding: '10px',

      background: 'transparent',
    },
  },
  kakaoPreviewDateBox: {
    boxSizing: 'border-box',
    width: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    color: '#666973',
    fontSize: '11px',
    fontWeight: 600,

    padding: '0px 13px',
    marginBottom: '29px',
  },
  dateLine: {
    width: '130px',
    borderTop: 'solid 1px #A6B6C4',
  },
});
