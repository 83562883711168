import React from 'react';
import * as _ from 'lodash';

export interface ImageProps {
  src: any,
  srcSet?: string,
  webp?: any,
  alt: string,
  className?: string,
  style?: any,
  onClick?: () => void,
  width?: string,
  height?: string,
  id?: string,
  ref?: any,
}

export const adjustImage = (img: any): any => {
  if (process.env.MOBILE != null && _.isString(img) && img.startsWith('/')) {
    return img.replace('/', '');
  }

  if (_.isString(img)) {
    return img;
  }
  return img?.default;
};

export function Image({src, ref, alt, ...props}: ImageProps) {
  return (
    <img
      alt={alt}
      src={adjustImage(src)}
      ref={ref}
      {...props}
    />
  );
}
