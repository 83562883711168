import React from 'react';
import {css} from 'aphrodite';

import {CreateBetaStyle} from '../../shared/CreateStyle';

export const Recipient = (
  {
    title,
    name,
    phone,
  }: {
    title: string,
    name: string,
    phone: string,
  }) => {

  const INFO_LIST = [
    {
      title: '수신자',
      value: name,
    },
    {
      title: '연락처',
      value: phone,
    },
  ]

  return (
    <div className={css(styles.frame)}>
      <div className={css(styles.title)}>{title}</div>
      {INFO_LIST.map((item, index) =>
        <div key={'basic-info_' + index} className={css(styles.infoBox)}>
          <p className={css(styles.infoTitle)}>{item.title}</p>
          <p>{item.value}</p>
        </div>
      )}
    </div>
  )
}

const styles = CreateBetaStyle({
  frame: {
    marginRight: '6vw'
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,

    marginBottom: '39px',
  },
  infoBox: {
    height: '56px',
    width: '400px',

    display: 'flex',
    alignItems: 'center',

    marginBottom: '18px',

    borderBottom: 'solid 1px #F2F2F2',

    fontSize: '15px',
    fontWeight: 500,
  },
  infoTitle: {
    width: '106px',
    color: '#666973',
  },
  valuesFrame: {
    width: '100%',
    height: '580px',

    paddingTop: '20px',

    boxSizing: 'border-box',

    overflowY: 'scroll',

    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: 'rgba(69, 79, 93, 0.9)',
      borderRadius: '15px',
      border: '3px solid transparent',
    },
    '::-webkit-scrollbar-track': {
      padding: '10px',

      background: 'transparent',
    },
  },
})
