import {css} from 'aphrodite';
import {CreateBetaStyle} from '../../shared/CreateStyle';

export const BasicInfo = ({title, children}: {title: string, children: any}) => {
  return (
    <div className={css(styles.frame)}>
      <div className={css(styles.frameTitle)}>{title}</div>
      {children}
    </div>
  )
}

const styles = CreateBetaStyle({
  frame: {
    width: '244px',
    height: '80px',

    backgroundColor: 'white',

    padding: '14px 15px 20px',

    border: 'solid 1px #D8D6ED',
    borderRadius: '5px',

    boxSizing: 'border-box',

    filter: 'drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.063))',

    fontFamily: 'Pretendard',
  },
  frameTitle: {
    fontSize: '13px',
    color: '#777777',
  },
})
