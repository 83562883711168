import Airtable from 'airtable';
import moment from 'moment';

const base = new Airtable({apiKey: process.env.REACT_APP_AIRTABLE_API_KEY}).base(process.env.REACT_APP_AIRTABLE_BASE_ID ?? '');

export async function getCustomerInfo(recordId: string) {
  const table = base(process.env.REACT_APP_AIRTABLE_BASIC_TABLE_ID!);
  const basic = await table.select({
      filterByFormula: `RECORD_ID() = '${recordId}'`,
      returnFieldsByFieldId: true,
      maxRecords: 1,
    }
  ).firstPage();
  const fields: any = basic[0].fields;
  return {
    tabletNum: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_TABLET_AMOUNT_FIELD_ID!] ?? 0,
    masterTabletNum: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_MASTER_TABLET_AMOUNT_FIELD_ID!] ?? 0,
    manager: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_BUSINESS_MANAGER_FIELD_ID!]?.name,
    contractPeriod: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CONTRACT_PERIOD_FIELD_ID!],
    shopkeeper: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHOP_KEEPER_FIELD_ID!],
    owner: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHOP_OWNER_FIELD_ID!],
    shopName: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHOP_LOCATION_NAME_FIELD_ID!],
    meetingDate: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_MEETING_DATE_FIELD_ID!],
    key: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_KEY_FIELD_ID!],
    shop: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHOP_NAME_FIELD_ID!],
    phone: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHOP_PHONE_FIELD_ID!],
    electronType: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_ELECTRON_TYPE_FIELD_ID!] ?? ' ',
    holderColor: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_HOLDER_COLOR_FIELD_ID!] ?? ' ',
    paymentType: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_PAYMENT_TYPE_FIELD_ID!] ?? ' ',
    visitManager: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_VISIT_MANAGER_FIELD_ID!] ?? ' ',
    visitManagerPhoneNumber: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_VISIT_MANAGER_PHONE_NUMBER_FIELD_ID!] ?? ' ',
    visitDate: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_VISIT_DATE_FIELD_ID!] ?? ' ',

    masterTabletCount1: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_MASTER_TABLET_COUNT1_FIELD_ID!],
    finalTabletCost1: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FINAL_TABLET_COST1_FIELD_ID!],
    tabletCount2: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_TABLET_COUNT2_FIELD_ID!],
    finalTabletCost2: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FINAL_TABLET_COST2_FIELD_ID!],
    tabletCount3: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_TABLET_COUNT3_FIELD_ID!],
    finalTabletCost3: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FINAL_TABLET_COST3_FIELD_ID!],
    masterTabletCount3: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_MASTER_TABLET_COUNT3_FIELD_ID!],
    promotion: fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_PROMOTION_FIELD_ID!],
  };
}

export async function getGoodsList() {
  const table = base(process.env.REACT_APP_AIRTABLE_GOODS_TABLE_ID!);
  const allList = await table.select({returnFieldsByFieldId: true}).all();
  const res = allList.map((item: any, index: number) => {
    if (!item.fields[process.env.REACT_APP_AIRTABLE_GOODS_TABLE_DEV_TYPE_FIELD_ID!] || !item.fields[process.env.REACT_APP_AIRTABLE_GOODS_TABLE_ESTIMATE_TYPE_FIELD_ID!]) {
      return;
    }
    return {
      id: item.id,
      type: item.fields[process.env.REACT_APP_AIRTABLE_GOODS_TABLE_DEV_TYPE_FIELD_ID!].split('.')[1],
      name: item.fields[process.env.REACT_APP_AIRTABLE_GOODS_TABLE_ESTIMATE_TYPE_FIELD_ID!],
      color: item.fields[process.env.REACT_APP_AIRTABLE_GOODS_TABLE_ESTIMATE_COLOR_FIELD_ID!],
    };
  }).filter((v: any) => v != null);
  const sortedRes = res.reduce((acc: any, cur: any) => {
    const type = cur.type;
    const existingItem = acc.find((item: any) => item.type === type);

    if (existingItem) {
      existingItem.items.push(cur);
    } else {
      acc.push({type, items: [cur]});
    }
    return acc;
  }, []);
  const groupedItems = sortedRes.map((group: any) => ({
    ...group,
    items: group.items.reduce((acc: any, curr: any) => {
      const existingItem = acc.find((item: any) => item.name === curr.name);
      if (existingItem) {
        existingItem.color.push(curr.color);
      } else {
        acc.push({
          ...curr,
          color: [curr.color],
        });
      }
      return acc;
    }, []),
  }));
  return groupedItems;
}

/*
export async function saveEstimateByRecord(
  {recordId, fileLink}: { recordId?: string, fileLink: string }
) {
  if (recordId == null) {
    return;
  }
  const table = base(process.env.REACT_APP_AIRTABLE_BASIC_TABLE_ID!);
  const time = moment().format('YY-MM-DD-hh-mm-ss');
  const url = await uploadImageToS3(fileLink, `${recordId}-${time}-estimate.png`);

  const beforeCustomer = await table.find(recordId);
  const beforeEstimate = beforeCustomer.fields['선납금 견적서'];
  await table.update([{
    id: recordId,
    fields: {
      ['fldkb8SAKEiUI4r6H']: beforeEstimate ? [
        // @ts-ignore
        {
          url,
        },
        // @ts-ignore
        ...beforeCustomer.fields['선납금 견적서']
      ] : [{url}]
    },
  }]);
  return url;
}
*/
