import React, {useEffect, useMemo, useState} from "react";
import {css} from "aphrodite";
import * as _ from "lodash";
import moment from "moment";
import {useParams} from "react-router-dom";

import {BasicInfo} from "../components/BasicInfo";
import {SectionFrame} from "../components/SectionFrame";

import {ACCOUNTS, ACCOUNTS_SMART_STORE} from "../constants/EstimateConstant";
import {
  CONTRACT_INFO,
  CONTRACT_PERIOD_OPTIONS,
  ELECTRON_DEADLINES,
  getAccountInfo,
  getCustomer,
  getGoodsInfo, GOV_SUPPORT_TYPES,
  INITIAL_COMMENT,
  INSTALL_INFO,
  LAST_INFO,
  INSTALLMENT_OPTIONS,
  INSTALLMENT_DEFAULT_OPTIONS,
  SUB_GOODS_DEFAULT_OPTIONS,
  THIRD_COMMENT,
  WORKING_TIME_INFO,
  YOUTUBE_INFO
} from "../constants/SmartStoreConstant";

import {
  copyLocalImageToClipboard,
  copyText,
  getToLocalString,
  saveImage,
  saveImageInClipBoard
} from "../helpers/EstimateHelper";

import {CreateBetaStyle} from "../../shared/CreateStyle";
import {Image} from "../../shared/components/Image";

import Delete from "../assets/images/svgs/red-delete.svg";
import Check from "../assets/images/svgs/check_white.svg";
import Logo from "../assets/images/svgs/torderLogo.svg";
import ShinhanLogo from "../assets/images/pngs/shinhan-logo.png";
import IbkLogo from "../assets/images/pngs/ibk-logo.png";
import Card from "../assets/images/pngs/business-card.png";
import Select from "../assets/images/pngs/select.png";
import RadioDefault from "../assets/images/svgs/icon_radio_default_20x20.svg";
import RadioBlue from "../assets/images/svgs/icon_radio_blue_20x20.svg";

export const SmartStore = ({customerInfoProps, goodsListProps}: any) => {
  const copyComments = () => [
    {
      text: INITIAL_COMMENT,
      height: 40,
    },
    {
      image: Card,
      height: 150,
    },
    {
      text: getCustomer(customer.phone, customer.key),
      height: 40,
    },
    {
      text: THIRD_COMMENT,
      height: 40,
    },
    {
      text: getGoodsInfo({
        period: installment[0] ? installment[0].month : '',
        tablet: installment[0] ? installment[0].quantity : '',
        masterTablet: installment[1] ? installment[1].quantity : '',
        price: installment[0] ? getToLocalString(installment[0].price) : '',
        customer,
      }),
      height: 30,
    },
    {
      text: getAccountInfo(getToLocalString(Math.floor(
        totalCostForTabs + (showSubGoods ? subGoodsTotal : 0)
      ))),
      height: 160,
    },
    {
      image: Select,
      height: 150,
    },
    {
      text: WORKING_TIME_INFO,
      height: 300,
    },
    {
      text: YOUTUBE_INFO,
      height: 50,
    },
    {
      text: LAST_INFO,
      height: 50,
    },
  ];

  // 조회된 데이터로 월 할부금 초기화
  const initInstallmentData = (data: any) => {
    const newArray = [];
    if (
      (data.tabletCount2 !== undefined && data.tabletCount2 !== null)
      || (data.finalTabletCost2 !== undefined && data.finalTabletCost2 !== null)
    ) {
      newArray.push({
        name: '태블릿',
        month: '36',
        price: data.finalTabletCost2,
        quantity: data.tabletCount2,
        total: '',
      });
    }
    if (
      (data.tabletCount3 !== undefined && data.tabletCount3 !== null)
      || (data.finalTabletCost3 !== undefined && data.finalTabletCost3 !== null)
    ) {
      newArray.push({
        name: '태블릿',
        month: '36',
        price: data.finalTabletCost3,
        quantity: data.tabletCount3,
        total: '',
      });
    }
    if (
      (data.masterTabletCount1 !== undefined && data.masterTabletCount1 !== null)
      || (data.finalTabletCost1 !== undefined && data.finalTabletCost1 !== null)
    ) {
      newArray.push({
        name: '마스터 태블릿',
        month: '36',
        price: data.finalTabletCost1,
        quantity: data.masterTabletCount1,
        total: '',
      });
    }
    if (data.masterTabletCount3 !== undefined && data.masterTabletCount3 !== null) {
      newArray.push({
        name: '마스터 태블릿',
        month: '36',
        price: '0',
        quantity: data.masterTabletCount3,
        total: '',
      });
    }
    if (newArray.length > 0) {
      setInstallment(newArray);
    }
  }

  // 조회된 데이터로 기본 부자재 구성 초기화
  const initBasicSubGoods = (data: any) => {
    if (data.paymentType !== '선결제') {
      return;
    }

    const newArray = [];
    let cardReaderCount = 0;
    let cardReaderCaseCount = 0;

    if (data.masterTabletCount1 !== undefined && data.masterTabletCount1 !== null) {
      cardReaderCount += data.masterTabletCount1;
      cardReaderCaseCount += data.masterTabletCount1;
    }
    if (data.tabletCount2 !== undefined && data.tabletCount2 !== null) {
      cardReaderCount += data.tabletCount2;
      cardReaderCaseCount += data.tabletCount2;
    }
    if (data.tabletCount3 !== undefined && data.tabletCount3 !== null) {
      cardReaderCount += data.tabletCount3;
      cardReaderCaseCount += data.tabletCount3;
    }

    newArray.push({
      type: '카드리더기',
      name: '',
      color: '',
      price: '',
      quantity: cardReaderCount,
      total: '',
    });
    newArray.push({
      type: '카드리더기 케이스',
      name: '',
      color: '',
      price: '',
      quantity: cardReaderCaseCount,
      total: '',
    });
    setBasicSubGoods(newArray);
  }


  const today = moment();

  const {id} = useParams<{ id: string }>();

  /** 접수 정보 **/
  const [customer, setCustomer] = useState<any>({});

  /** 수신자 정보 **/
  // 표시 여부
  const [showReceiverInfo, setShowReceiverInfo] = useState(true);

  const [deadlineDate, setDeadlineDate] = useState(14);
  const [deadline, setDeadline] = useState('');
  const [electronDeadlineNum, setElectronDeadlineNum] = useState<any>(0);
  const [goodsList, setGoodsList] = useState<any>([]);

  /** 월 할부금 **/
  // 표시 여부
  const [showInstallment, setShowInstallment] = useState(true);
  const [showInstallmentDetail, setShowInstallmentDetail] = useState(true);
  const [installment, setInstallment] = useState<any[]>([]);
  // 품목 옵션
  const [tabletOptions, setTabletOptions] = useState(-1);
  // 약정 옵션
  const [periodOptions, setPeriodOptions] = useState(-1);


  /** 기본 부자재 구성 **/
  // 표시 여부
  const [showBasicSubGoods, setShowBasicSubGoods] = useState(true);
  const [showBasicSubGoodsDetail, setShowBasicSubGoodsDetail] = useState(true);
  // const [basicSubGoods, setBasicSubGoods] = useState<any[]>(BASIC_SUB_GOODS_INITIAL);
  const [basicSubGoods, setBasicSubGoods] = useState<any[]>(SUB_GOODS_DEFAULT_OPTIONS);
  // 품목 옵션
  const [basicSubType, setBasicSubType] = useState(-1);
  // 모델명 옵션
  const [basicSubName, setBasicSubName] = useState(-1);
  // 색상 옵션
  const [basicSubColor, setBasicSubColor] = useState(-1);

  /** 부가세 **/
  // 표시 여부
  const [showVat, setShowVat] = useState(true);
  // 비율
  const [vatRatio, setVatRatio] = useState(10);
  // 부가세 금액
  const [vatAmount, setVatAmount] = useState(0);

  /** 국가지원 **/
  // 표시 여부
  const [showGovSupport, setShowGovSupport] = useState(true);
  // 선택된 국가지원 타입
  const [govSupportTypeIndex, setGovSupportTypeIndex] = useState(0);
  // 비율
  const [govSupportRatio, setGovSupportRatio] = useState(GOV_SUPPORT_TYPES[0].supportRatio);
  // 최대 지원금액
  const [maxGovSupportAmount, setMaxGovSupportAmount] = useState(GOV_SUPPORT_TYPES[0].maxSupportAmount);
  // 국가지원 금액
  const [govSupportAmount, setGovSupportAmount] = useState(0);

  /** 자부담 **/
  // 표시 여부
  const [showSelfPayment, setShowSelfPayment] = useState(true);
  // 비율
  const [selfPaymentRatio, setSelfPaymentRatio] = useState(30);
  // 자부담 금액
  const [selfPaymentAmount, setSelfPaymentAmount] = useState(0);

  /** 부가세 자부담 **/
  // 표시 여부
  const [showVatSelfPayment, setShowVatSelfPayment] = useState(true);

  /** 태블릿 합계 금액 **/
  const [totalCostForTabs, setTotalCostForTabs] = useState(0);

  /** 태블릿, 기본 부자재 합계 금액 **/
  const [totalCostForTabsAndBasicSubGoods, setTotalCostForTabsAndBasicSubGoods] = useState(0);


  /** 스마트 상점 전용 입금 계좌번호 **/
  // 표시 여부
  const [showAccountForSmartStore, setShowAccountForSmartStore] = useState(true);
  // 선택된 계좌번호 인덱스
  const [accountIndexForSmartStore, setAccountIndexForSmartStore] = useState(0);
  // 선택된 계좌번호
  const [accountForSmartStore, setAccountForSmartStore] = useState<any>(ACCOUNTS_SMART_STORE[0]);


  /** 부자재 구성 **/
  // 표시 여부
  const [showSubGoods, setShowSubGoods] = useState(true);
  const [showSubGoodsDetail, setShowSubGoodsDetail] = useState(true);
  const [subGoods, setSubGoods] = useState<any[]>(SUB_GOODS_DEFAULT_OPTIONS);
  // 품목 옵션
  const [subType, setSubType] = useState(-1);
  // 모델명 옵션
  const [subName, setSubName] = useState(-1);
  // 색상 옵션
  const [subColor, setSubColor] = useState(-1);

  /** 전기공사비용 **/
  // 표시 여부
  const [showElecInstallCost, setShowElecInstallCost] = useState(true);
  // 전기공사비용 금액
  const [elecInstallCost, setElecInstallCost] = useState(0);

  /** 부자재/전기공사비용 입금 계좌번호 **/
  // 표시 여부
  const [showAccountForSubGoodsAndElecInstall, setShowAccountForSubGoodsAndElecInstall] = useState(true);
  // 선택된 계좌번호 인덱스
  const [accountIndexForSubGoodsAndElecInstall, setAccountIndexForSubGoodsAndElecInstall] = useState(0);
  // 선택된 계좌번호
  const [accountForSubGoodsAndElecInstall, setAccountForSubGoodsAndElecInstall] = useState<any>(ACCOUNTS[0]);

  /** 부자재, 전기공사비용 합계 금액 **/
  const [totalCostForSubGoodsAndElecInstall, setTotalCostForSubGoodsAndElecInstall] = useState(0);


  /** 안내사항 **/
  const [showInfo, setShowInfo] = useState(true);
  const [info, setInfo] = useState(CONTRACT_INFO);
  /** 설치관련 유의사항 **/
  const [showInstallInfo, setShowInstallInfo] = useState(true);
  const [installInfo, setInstallInfo] = useState(INSTALL_INFO);
  /** A/S 기간 안내 **/
  const [showElectronDeadline, setShowElectronDeadline] = useState(true);
  const [electronDeadline, setElectronDeadline] = useState<any>(ELECTRON_DEADLINES[0].content);


  const [isCopy, setIsCopy] = useState(false);

  // const ACCOUNT = '140-012-934957';
  // const [isLoading, setIsLoading] = useState(false);
  // const [isPopupOn, setIsPopupOn] = useState(false);
  // const [message, setMessage] = useState('');

  const subGoodsTotal = useMemo(() => {
    let total = 0;
    subGoods.map((item: any) => {
      total = total + item.quantity * item.price;
    })
    return total;
  }, [subGoods]);

  const basicSubGoodsTotal = useMemo(() => {
    let total = 0;
    basicSubGoods.map((item: any) => {
      total = total + item.quantity * item.price;
    })
    return total;
  }, [basicSubGoods]);

  useEffect(() => {
    setCustomer(customerInfoProps);

    initInstallmentData(customerInfoProps);
    initBasicSubGoods(customerInfoProps);

    setGoodsList(goodsListProps);
  }, [customerInfoProps, goodsListProps]);

  /** 견적서 유효기간 계산 **/
  useEffect(() => {
    const deadline = moment(today)
      .add(deadlineDate, 'days')
      .format('YYYY년 MM월 DD일');
    setDeadline(deadline);
  }, [deadlineDate]);

  /** 태블릿 합계 금액 계산 **/
  useEffect(() => {
    let totalCost = 0;
    installment.map((item: any) => {
      totalCost = totalCost + (item.quantity * item.price);
    });
    setTotalCostForTabs(Math.floor(totalCost));
  }, [installment]);

  /** 태블릿, 기본 부자재 합계 금액 계산 **/
  useEffect(() => {
    setTotalCostForTabsAndBasicSubGoods(Math.floor(
      totalCostForTabs + (showBasicSubGoods ? basicSubGoodsTotal : 0)
    ));
  }, [totalCostForTabs, showBasicSubGoods, basicSubGoodsTotal]);

  /** 부가세 금액 계산 **/
  useEffect(() => {
    let newVatAmount = totalCostForTabsAndBasicSubGoods * vatRatio / 100;
    setVatAmount(newVatAmount);
  }, [installment, totalCostForTabsAndBasicSubGoods, vatRatio]);

  /** 국가지원, 자부담 금액 계산 **/
  useEffect(() => {
    let newGovSupportAmount = totalCostForTabs * govSupportRatio / 100;
    let newSelfPaymentAmount = totalCostForTabs - newGovSupportAmount;
    setGovSupportAmount(newGovSupportAmount);
    setSelfPaymentAmount(newSelfPaymentAmount);
  }, [installment, totalCostForTabs, govSupportRatio, selfPaymentRatio]);

  /** 부자재/전기공사비용 합계 금액 계산 **/
  useEffect(() => {
    let newTotalCostForSubGoodsAndElecInstall = subGoodsTotal + elecInstallCost;
    setTotalCostForSubGoodsAndElecInstall(newTotalCostForSubGoodsAndElecInstall);
  }, [subGoodsTotal, elecInstallCost]);

  return (
    <>
      {
        <div
          className={css(
            styles.notification,
            isCopy && styles.notificationDisplay
          )}
        >
          복사완료
        </div>
      }

      <div className={css(styles.estimateWriteFrame)}>

        <div className={css(styles.estimateWriteBasic)}>
          <BasicInfo title={'견적 담당자'}>
            <div className={css(styles.estimateWriteBasicManager)}>{customer.manager}</div>
          </BasicInfo>
          <BasicInfo title={'견적일'}>
            <div className={css(styles.estimateWriteBasicDate)}>{today.format('YYYY/MM/DD')}</div>
          </BasicInfo>
          <BasicInfo title={'견적서 유효기간'}>
            <div className={css(styles.estimateWriteBasicDeadline)}>
              <div>견적일로 부터</div>
              <input
                className={css(styles.estimateWriteBasicDeadlineInput)}
                value={deadlineDate}
                onChange={(e: any) => {
                  setDeadlineDate(e.target.value)
                }}
              />
              <div>일 이후 까지</div>
            </div>
          </BasicInfo>
        </div>

        <SectionFrame
          title={'스마트 상점'}
          isShown={showInstallment}
          onClickHidden={() => setShowInstallment(!showInstallment)}
          isSheet={true}
          isSheetDetail={showInstallmentDetail}
          checkSheetDetail={() => {
            setShowInstallmentDetail(!showInstallmentDetail)
          }}
          addSheetList={() => {
            const newArray = _.cloneDeep(installment);
            newArray.push(INSTALLMENT_DEFAULT_OPTIONS[0])
            setInstallment(newArray);
          }}
          frameZIndex40={true}
        >
          <div>
            <div className={css(styles.sheetFrame)}>
              <div className={css(styles.sheetHeader)}>
                <div className={css(styles.sheetHeaderType)}>품목</div>
                <div className={css(styles.sheetHeaderMonth)}>약정</div>
                <div className={css(styles.sheetHeaderPrice)}>
                  대당 월 금액<span className={css(styles.textRed)}>(VAT 미포함)</span>
                </div>
                <div className={css(styles.sheetHeaderQuantity)}>수량</div>
                <div className={css(styles.sheetHeaderTotal)}>월 할부금</div>
                <div className={css(styles.sheetHeaderDelete)}>삭제</div>
              </div>
              <div>
                {installment.map((item: any, index: number) =>
                  <div className={css(styles.sheetList)} key={'installment' + index}>
                    <div className={css(styles.sheetDropDownFrame)}>
                      <input
                        className={css(styles.sheetHeaderType, styles.sheetInput)}
                        value={item.name}
                        onChange={(e) => {
                          const newArray = _.cloneDeep(installment);
                          newArray[index].name = e.target.value;
                          setInstallment(newArray);
                        }}
                        onClick={() => {
                          if (tabletOptions !== -1) {
                            setTabletOptions(-1);
                            return;
                          }
                          setTabletOptions(index);
                        }}
                      />
                      {/*<div*/}
                      {/*  className={css(styles.sheetHeaderType, styles.sheetInput)}*/}
                      {/*  onClick={() => setTabletOptions(index)}*/}
                      {/*>*/}
                      {/*  {item.name}*/}
                      {/*</div>*/}
                      {index === tabletOptions
                        && (
                          <div className={css(styles.dropdownFrame)}>
                            {INSTALLMENT_OPTIONS.map((item02, index02) =>
                              <div
                                className={css(styles.dropdownItem)}
                                onClick={() => {
                                  const newArray = _.cloneDeep(installment);
                                  newArray[index].name = item02;
                                  setInstallment(newArray);
                                  setTabletOptions(-1)
                                }}
                              >
                                {item02}
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                    <div className={css(styles.sheetDropDownFrame)}>
                      <div
                        className={css(
                          styles.sheetHeaderMonth,
                          styles.sheetInput,
                          styles.sheetInputTextRight
                        )}
                        onClick={() => setPeriodOptions(index)}
                      >
                        {item.month} 개월
                      </div>
                      {index === periodOptions
                        && (
                          <div className={css(styles.dropdownFrame)}>
                            {CONTRACT_PERIOD_OPTIONS.map((item02, index02) =>
                              <div
                                className={css(styles.dropdownItem)}
                                onClick={() => {
                                  const newArray = _.cloneDeep(installment);
                                  newArray[index].month = item02;
                                  setInstallment(newArray);
                                  setPeriodOptions(-1)
                                }}
                              >
                                {item02}
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                    <input
                      value={getToLocalString(item.price)}
                      placeholder={'월 금액을 입력해주세요.'}
                      className={css(
                        styles.sheetHeaderPrice,
                        styles.sheetInput,
                        styles.sheetInputTextRight
                      )}
                      onChange={(e) => {
                        const newArray = _.cloneDeep(installment);
                        newArray[index].price = e.target.value.replace(/[^0-9]/g, '');
                        setInstallment(newArray);
                      }}
                    />
                    <input
                      value={getToLocalString(item.quantity)}
                      placeholder={'00'}
                      className={css(
                        styles.sheetHeaderQuantity,
                        styles.sheetInput,
                        styles.sheetInputTextRight
                      )}
                      onChange={(e) => {
                        const newArray = _.cloneDeep(installment);
                        newArray[index].quantity = e.target.value.replace(/[^0-9]/g, '');
                        setInstallment(newArray);
                      }}
                    />
                    <input
                      value={getToLocalString(
                        item.quantity * item.price
                      )}
                      className={css(
                        styles.sheetHeaderTotal,
                        styles.sheetInput,
                        styles.sheetInputTextRight
                      )}
                      style={{color: '#403CFF'}}
                    />
                    <div
                      className={css(styles.sheetHeaderDeleteBox)}
                      onClick={() => {
                        const newArray = _.cloneDeep(installment);
                        setInstallment(newArray.filter((value, idx) => idx !== index));
                      }}
                    >
                      <Image src={Delete} alt={'delete'}/>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </SectionFrame>

        <SectionFrame
          title={'기본 부자재 구성'}
          isShown={showBasicSubGoods}
          onClickHidden={() => setShowBasicSubGoods(!showBasicSubGoods)}
          isSheet={true}
          isSheetDetail={showBasicSubGoodsDetail}
          checkSheetDetail={() => setShowBasicSubGoodsDetail(!showBasicSubGoodsDetail)}
          addSheetList={() => {
            const newArray = _.cloneDeep(subGoods);
            newArray.push(SUB_GOODS_DEFAULT_OPTIONS[0])
            setBasicSubGoods(newArray);
          }}
          frameZIndex20={true}
        >
          <div>
            <div className={css(styles.sheetFrame)}>
              <div className={css(styles.sheetHeader)}>
                <div style={{width: '110px'}}>품목</div>
                <div style={{width: '120px'}}>모델명</div>
                <div style={{width: '70px'}}>색상</div>
                <div style={{width: '120px'}}>대당금액</div>
                <div style={{width: '40px'}}>수량</div>
                <div style={{width: '120px'}}>금액</div>
                <div style={{width: '31px'}}>삭제</div>
              </div>
              <div>
                {basicSubGoods.map((item, index) =>
                  <div className={css(styles.sheetList)} key={'basicSubGoods' + index}>
                    <div className={css(styles.sheetDropDownFrame)}>
                      <input
                        style={{width: '110px'}}
                        className={css(styles.sheetInput)}
                        placeholder={'품목명을 입력해주세요.'}
                        value={item.type}
                        onChange={(e) => {
                          const newArray = _.cloneDeep(basicSubGoods);
                          newArray[index].type = e.target.value;
                          setBasicSubGoods(newArray);
                        }}
                        onFocus={() => {
                          setBasicSubName(-1);
                          setBasicSubColor(-1)
                        }}
                        onClick={() => {
                          if (basicSubType !== -1) {
                            setBasicSubType(-1)
                            return;
                          }
                          setBasicSubType(index)
                        }}
                        // onBlur={() => setBasicSubType(-1)}
                      />
                      {(basicSubType === index
                        && goodsList.filter(
                          (v: any) => v.type.includes(item.type)
                        ).length > 0
                      ) && (
                        <div
                          className={css(styles.dropdownFrame)}
                          style={{
                            height: `calc(42px + ${goodsList.filter(
                              (v: any) => v.type.includes(item.type)
                            ).length > 5
                              ? '100px'
                              : `${(goodsList.filter(
                                (v: any) => v.type.includes(item.type)
                              ).length - 1) * 30}px`})`
                          }}
                        >
                          {goodsList.filter(
                            (v: any) => v.type.includes(item.type)
                          ).map((item02: any, index02: number) =>
                            <div
                              className={css(styles.dropdownItem)}
                              onClick={() => {
                                const newArray = _.cloneDeep(basicSubGoods);
                                newArray[index].type = item02.type;
                                setBasicSubGoods(newArray);
                                setBasicSubType(-1);
                              }}
                            >
                              {item02.type}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={css(styles.sheetDropDownFrame)}>
                      <input
                        style={{width: '120px'}}
                        className={css(styles.sheetInput)}
                        placeholder={'모델명을 입력해주세요.'}
                        value={item.name}
                        onChange={(e) => {
                          const newArray = _.cloneDeep(basicSubGoods);
                          newArray[index].name = e.target.value;
                          setBasicSubGoods(newArray);
                        }}
                        onFocus={() => {
                          setBasicSubType(-1);
                          setBasicSubColor(-1)
                        }}
                        onClick={() => {
                          if (basicSubName !== -1) {
                            setBasicSubName(-1);
                            return;
                          }
                          setBasicSubName(index)
                        }}
                      />
                      {goodsList.find((v: any) => v.type === item.type)
                        && basicSubName === index
                        && goodsList.find(
                          (v: any) => v.type === item.type
                        ).items.filter(
                          (v: any) => v.name.includes(item.name)
                        ).length > 0
                        && (
                          <div
                            className={css(styles.dropdownFrame)}
                            style={{
                              height: `calc(42px + ${
                                goodsList.find(
                                  (v: any) => v.type === item.type
                                ).items.filter(
                                  (v: any) => v.name.includes(item.name)
                                ).length > 5
                                  ? '100px'
                                  : `${(goodsList.find(
                                    (v: any) => v.type === item.type
                                  ).items.filter(
                                    (v: any) => v.name.includes(item.name)
                                  ).length - 1) * 30}px`
                              })`
                            }}
                          >
                            {goodsList.find(
                              (v: any) => v.type === item.type
                            ).items.filter(
                              (v: any) => v.name.includes(item.name)
                            ).map((item02: any, index02: number) => (
                              <div
                                key={`${item02.name}_${index02}`}
                                className={css(styles.dropdownItem)}
                                onClick={() => {
                                  const newArray = _.cloneDeep(basicSubGoods);
                                  newArray[index].name = item02.name;
                                  setBasicSubGoods(newArray);
                                  setBasicSubName(-1);
                                }}
                              >
                                {item02.name}
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                    <div className={css(styles.sheetDropDownFrame)}>
                      <input
                        style={{width: '70px'}}
                        className={css(styles.sheetInput)}
                        placeholder={'색상'}
                        value={item.color}
                        onFocus={() => {
                          setBasicSubType(-1);
                          setBasicSubName(-1)
                        }}
                        onClick={() => {
                          if (basicSubColor !== -1) {
                            setBasicSubColor(-1)
                            return;
                          }
                          setBasicSubColor(index)
                        }}
                        onChange={(e: any) => {
                          const newArray = _.cloneDeep(basicSubGoods);
                          newArray[index].color = e.target.value;
                          setBasicSubGoods(newArray);
                        }}
                      />
                      {(goodsList.find(
                          (v: any) => v.type === item.type
                        ) && goodsList.find(
                          (v: any) => v.type === item.type
                        ).items.find(
                          (v: any) => v.name === item.name
                        ) && basicSubColor === index
                      ) && (
                        <div
                          className={css(styles.dropdownFrame)}
                          style={{
                            height: `calc(42px + ${goodsList.find(
                              (v: any) => v.type === item.type
                            ).items.find(
                              (v: any) => v.name === item.name
                            ).length > 5
                              ? '100px'
                              : `${(goodsList.find(
                                (v: any) => v.type === item.type
                              ).items.find(
                                (v: any) => v.name === item.name
                              ).length - 1) * 30}px`})`
                          }}
                        >
                          {goodsList.find(
                            (v: any) => v.type === item.type
                          ).items.find(
                            (v: any) => v.name === item.name
                          ).color.map(
                            (item02: any, index02: number) =>
                              <div
                                className={css(styles.dropdownItem)}
                                onClick={() => {
                                  const newArray = _.cloneDeep(basicSubGoods);
                                  newArray[index].color = item02;
                                  setBasicSubGoods(newArray);
                                  setBasicSubColor(-1);
                                }}
                              >
                                {item02}
                              </div>
                          )}
                        </div>
                      )}
                    </div>
                    <input
                      style={{width: '120px', textAlign: 'right'}}
                      value={getToLocalString(item.price)}
                      className={css(styles.sheetInput)}
                      placeholder={'대당 금액을 입력해주세요.'}
                      onChange={(e: any) => {
                        const newArray = _.cloneDeep(basicSubGoods);
                        newArray[index].price = e.target.value.replace(/[^0-9]/g, '');
                        setBasicSubGoods(newArray);
                      }}
                    />
                    <input
                      style={{width: '40px', textAlign: 'right'}}
                      value={getToLocalString(item.quantity)}
                      className={css(styles.sheetInput)}
                      placeholder={'00'}
                      onChange={(e: any) => {
                        const newArray = _.cloneDeep(basicSubGoods);
                        newArray[index].quantity = e.target.value.replace(/[^0-9]/g, '');
                        setBasicSubGoods(newArray);
                      }}
                    />
                    <input
                      style={{width: '120px', color: '#403CFF', textAlign: 'right'}}
                      value={getToLocalString(item.price * item.quantity)}
                      className={css(styles.sheetInput)}
                    />
                    <div
                      className={css(styles.sheetHeaderDeleteBox)}
                      onClick={() => {
                        const newArray = _.cloneDeep(basicSubGoods);
                        setBasicSubGoods(newArray.filter((value, idx) => idx !== index));
                      }}
                    >
                      <Image src={Delete} alt={'delete'}/>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </SectionFrame>

        <SectionFrame
          title={'부가세'}
          isShown={showVat}
          onClickHidden={() => setShowVat(!showVat)}
        >
          <div>
            <div className={css(styles.sheetFrame)}>
              <div
                style={{
                  height: '30px',
                  backgroundColor: '#F4F4F8',
                  fontFamily: 'Pretendard',
                  fontSize: '11px',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                }}
              >
                <div style={{marginRight: '10px'}}>비율</div>
                <input
                  type={"number"}
                  min={"0"}
                  max={"100"}
                  value={vatRatio}
                  className={css(
                    styles.sheetHeaderQuantity,
                    styles.sheetInput,
                    styles.sheetInputTextRight
                  )}
                  onChange={(e) => {
                    const newVatRatio = parseInt(e.target.value);
                    if (!isNaN(newVatRatio) && newVatRatio >= 0 && newVatRatio <= 100) {
                      setVatRatio(newVatRatio);
                    }
                  }}
                />
                &nbsp;&nbsp;%
              </div>
            </div>
          </div>
        </SectionFrame>

        <SectionFrame
          title={'국가지원'}
          isShown={showGovSupport}
          onClickHidden={() => setShowGovSupport(!showGovSupport)}
        >
          <div>
            <div className={css(styles.asSelectFrame)}>
              {GOV_SUPPORT_TYPES.map((item, index) =>
                <div
                  onClick={() => {
                    setGovSupportTypeIndex(index);
                    setGovSupportRatio(item.supportRatio);
                    setMaxGovSupportAmount(item.maxSupportAmount);
                  }}
                  className={css(styles.asSelectList)}
                  key={'govSupportTypes' + index}
                >
                  <div
                    className={css(
                      styles.asSelectListCheckBox,
                      govSupportTypeIndex === index && styles.asSelectListCheckBoxSelected
                    )}
                  >
                    <div className={css(styles.asSelectListCheckCircle)}/>
                  </div>
                  {item.typeName}
                </div>
              )}
            </div>
            <div className={css(styles.sheetFrame)}>
              <div>
                <div className={css(styles.sheetList)}>
                  <div
                    style={{
                      height: '30px',
                      backgroundColor: '#F4F4F8',
                      fontFamily: 'Pretendard',
                      fontSize: '11px',
                      fontWeight: 600,
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                  >
                    <div style={{marginRight: '10px', width: '60px'}}>
                      비율
                    </div>
                    <input
                      type={"number"}
                      min={"0"}
                      max={"100"}
                      value={govSupportRatio}
                      className={css(
                        styles.sheetInput,
                        styles.sheetInputTextRight
                      )}
                      style={{width: '120px'}}
                      onChange={(e) => {
                        const newGovSupportRatio = parseInt(e.target.value);
                        if (!isNaN(newGovSupportRatio) && newGovSupportRatio >= 0 && newGovSupportRatio <= 100) {
                          setGovSupportRatio(newGovSupportRatio);
                        }
                      }}
                    />
                    &nbsp;&nbsp;%
                  </div>
                </div>

                <div className={css(styles.sheetList)}>
                  <div
                    style={{
                      height: '30px',
                      backgroundColor: '#F4F4F8',
                      fontFamily: 'Pretendard',
                      fontSize: '11px',
                      fontWeight: 600,
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}
                  >
                    <div style={{marginRight: '10px', width: '60px'}}>
                      최대 지원금액
                    </div>
                    <input
                      type={"number"}
                      // min={"0"}
                      // max={"100"}
                      value={maxGovSupportAmount}
                      className={css(
                        styles.sheetInput,
                        styles.sheetInputTextRight
                      )}
                      style={{width: '120px'}}
                      onChange={(e) => {
                        const newMaxGovSupportAmount = parseInt(e.target.value);
                        if (!isNaN(newMaxGovSupportAmount) && newMaxGovSupportAmount >= 0) {
                          setMaxGovSupportAmount(newMaxGovSupportAmount);
                        }
                      }}
                    />
                    &nbsp;&nbsp;원
                  </div>
                </div>

              </div>
            </div>
          </div>
        </SectionFrame>

        <SectionFrame
          title={'자부담'}
          isShown={showSelfPayment}
          onClickHidden={() => setShowSelfPayment(!showSelfPayment)}
        >
          <div>
            <div className={css(styles.sheetFrame)}>
              <div
                style={{
                  height: '30px',
                  backgroundColor: '#F4F4F8',
                  fontFamily: 'Pretendard',
                  fontSize: '11px',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                }}
              >
                <div style={{marginRight: '10px'}}>비율</div>
                <input
                  type={"number"}
                  min={"0"}
                  max={"100"}
                  value={selfPaymentRatio}
                  className={css(
                    styles.sheetHeaderQuantity,
                    styles.sheetInput,
                    styles.sheetInputTextRight
                  )}
                  onChange={(e) => {
                    const newSelfPaymentRatio = parseInt(e.target.value);
                    if (!isNaN(newSelfPaymentRatio) && newSelfPaymentRatio >= 0 && newSelfPaymentRatio <= 100) {
                      setSelfPaymentRatio(newSelfPaymentRatio);
                    }
                  }}
                  readOnly={true}
                  disabled={true}
                />
                &nbsp;&nbsp;%
              </div>
            </div>
          </div>
        </SectionFrame>

        <SectionFrame
          title={'부가세 자부담'}
          isShown={showVatSelfPayment}
          onClickHidden={() => setShowVatSelfPayment(!showVatSelfPayment)}
        />

        <SectionFrame
          title={'스마트 상점 전용 입금 계좌번호'}
          isShown={showAccountForSmartStore}
          onClickHidden={() => setShowAccountForSmartStore(!showAccountForSmartStore)}
        >
          <div>
            <div className={css(styles.sheetFrame)}>
              <div className={css(styles.sheetHeader)}>
                <div style={{width: '100px', marginLeft: '10px'}}>선택</div>
                <div style={{width: '200px'}}>은행</div>
                <div style={{width: '200px'}}>계좌번호</div>
                <div style={{width: '200px'}}>예금주명</div>
              </div>
              <div>
                {ACCOUNTS_SMART_STORE.map((item, index) =>
                  <div className={css(styles.sheetList)} key={'accountsSmartStore' + index}>
                    <div
                      style={{
                        width: '100px',
                        marginLeft: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setAccountIndexForSmartStore(index);
                        setAccountForSmartStore(ACCOUNTS_SMART_STORE[index]);
                      }}
                    >
                      <Image
                        src={accountIndexForSmartStore === index ? RadioBlue : RadioDefault}
                        alt={''}
                      />
                      <div
                        style={{
                          height: '30px',
                          backgroundColor: '#F4F4F8',
                          fontFamily: 'Pretendard',
                          fontSize: '11px',
                          fontWeight: 600,
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '5px',
                          paddingRight: '5px',
                        }}
                      >
                        {item.name}
                      </div>
                    </div>
                    <div style={{width: '180px'}} className={css(styles.sheetInput)}>
                      {item.bank === '신한은행' &&
                        <Image
                          src={ShinhanLogo}
                          alt={'bank'}
                          style={{width: '80px'}}
                        />
                      }
                      {item.bank === '기업은행' &&
                          <Image
                              src={IbkLogo}
                              alt={'bank'}
                              style={{width: '80px'}}
                          />
                      }
                    </div>
                    <div style={{width: '180px'}} className={css(styles.sheetInput)}>
                      {item.number}
                    </div>
                    <div style={{width: '180px'}} className={css(styles.sheetInput)}>
                      {item.owner}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </SectionFrame>

        <SectionFrame
          title={'부자재 구성'}
          isShown={showSubGoods}
          onClickHidden={() => setShowSubGoods(!showSubGoods)}
          isSheet={true}
          isSheetDetail={showSubGoodsDetail}
          checkSheetDetail={() => setShowSubGoodsDetail(!showSubGoodsDetail)}
          addSheetList={() => {
            const newArray = _.cloneDeep(subGoods);
            newArray.push(SUB_GOODS_DEFAULT_OPTIONS[0])
            setSubGoods(newArray);
          }}
          frameZIndex20={true}
        >
          <div>
            <div className={css(styles.sheetFrame)}>
              <div className={css(styles.sheetHeader)}>
                <div style={{width: '110px'}}>품목</div>
                <div style={{width: '120px'}}>모델명</div>
                <div style={{width: '70px'}}>색상</div>
                <div style={{width: '120px'}}>대당금액</div>
                <div style={{width: '40px'}}>수량</div>
                <div style={{width: '120px'}}>금액</div>
                <div style={{width: '31px'}}>삭제</div>
              </div>
              <div>
                {subGoods.map((item, index) =>
                  <div className={css(styles.sheetList)} key={'subGoods' + index}>
                    <div className={css(styles.sheetDropDownFrame)}>
                      <input
                        style={{width: '110px'}}
                        className={css(styles.sheetInput)}
                        placeholder={'품목명을 입력해주세요.'}
                        value={item.type}
                        onChange={(e) => {
                          const newArray = _.cloneDeep(subGoods);
                          newArray[index].type = e.target.value;
                          setSubGoods(newArray);
                        }}
                        onFocus={() => {
                          setSubName(-1);
                          setSubColor(-1)
                        }}
                        onClick={() => {
                          if (subType !== -1) {
                            setSubType(-1)
                            return;
                          }
                          setSubType(index)
                        }}
                        // onBlur={() => setSubType(-1)}
                      />
                      {(subType === index
                        && goodsList.filter(
                          (v: any) => v.type.includes(item.type)
                        ).length > 0
                      ) && (
                        <div
                          className={css(styles.dropdownFrame)}
                          style={{
                            height: `calc(42px + ${goodsList.filter(
                              (v: any) => v.type.includes(item.type)
                            ).length > 5
                              ? '100px'
                              : `${(goodsList.filter(
                                (v: any) => v.type.includes(item.type)
                              ).length - 1) * 30}px`})`
                          }}
                        >
                          {goodsList.filter(
                            (v: any) => v.type.includes(item.type)
                          ).map((item02: any, index02: number) =>
                            <div
                              className={css(styles.dropdownItem)}
                              onClick={() => {
                                const newArray = _.cloneDeep(subGoods);
                                newArray[index].type = item02.type;
                                setSubGoods(newArray);
                                setSubType(-1);
                              }}
                            >
                              {item02.type}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={css(styles.sheetDropDownFrame)}>
                      <input
                        style={{width: '120px'}}
                        className={css(styles.sheetInput)}
                        placeholder={'모델명을 입력해주세요.'}
                        value={item.name}
                        onChange={(e) => {
                          const newArray = _.cloneDeep(subGoods);
                          newArray[index].name = e.target.value;
                          setSubGoods(newArray);
                        }}
                        onFocus={() => {
                          setSubType(-1);
                          setSubColor(-1)
                        }}
                        onClick={() => {
                          if (subName !== -1) {
                            setSubName(-1);
                            return;
                          }
                          setSubName(index)
                        }}
                      />
                      {goodsList.find((v: any) => v.type === item.type)
                        && subName === index
                        && goodsList.find(
                          (v: any) => v.type === item.type
                        ).items.filter(
                          (v: any) => v.name.includes(item.name)
                        ).length > 0
                        && (
                          <div
                            className={css(styles.dropdownFrame)}
                            style={{
                              height: `calc(42px + ${
                                goodsList.find(
                                  (v: any) => v.type === item.type
                                ).items.filter(
                                  (v: any) => v.name.includes(item.name)
                                ).length > 5
                                  ? '100px'
                                  : `${(goodsList.find(
                                    (v: any) => v.type === item.type
                                  ).items.filter(
                                    (v: any) => v.name.includes(item.name)
                                  ).length - 1) * 30}px`
                              })`
                            }}
                          >
                            {goodsList.find(
                              (v: any) => v.type === item.type
                            ).items.filter(
                              (v: any) => v.name.includes(item.name)
                            ).map((item02: any, index02: number) => (
                              <div
                                key={`${item02.name}_${index02}`}
                                className={css(styles.dropdownItem)}
                                onClick={() => {
                                  const newArray = _.cloneDeep(subGoods);
                                  newArray[index].name = item02.name;
                                  setSubGoods(newArray);
                                  setSubName(-1);
                                }}
                              >
                                {item02.name}
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                    <div className={css(styles.sheetDropDownFrame)}>
                      <input
                        style={{width: '70px'}}
                        className={css(styles.sheetInput)}
                        placeholder={'색상'}
                        value={item.color}
                        onFocus={() => {
                          setSubType(-1);
                          setSubName(-1)
                        }}
                        onClick={() => {
                          if (subColor !== -1) {
                            setSubColor(-1)
                            return;
                          }
                          setSubColor(index)
                        }}
                        onChange={(e: any) => {
                          const newArray = _.cloneDeep(subGoods);
                          newArray[index].color = e.target.value;
                          setSubGoods(newArray);
                        }}
                      />
                      {(goodsList.find(
                          (v: any) => v.type === item.type
                        ) && goodsList.find(
                          (v: any) => v.type === item.type
                        ).items.find(
                          (v: any) => v.name === item.name
                        ) && subColor === index
                      ) && (
                        <div
                          className={css(styles.dropdownFrame)}
                          style={{
                            height: `calc(42px + ${goodsList.find(
                              (v: any) => v.type === item.type
                            ).items.find(
                              (v: any) => v.name === item.name
                            ).length > 5
                              ? '100px'
                              : `${(goodsList.find(
                                (v: any) => v.type === item.type
                              ).items.find(
                                (v: any) => v.name === item.name
                              ).length - 1) * 30}px`})`
                          }}
                        >
                          {goodsList.find(
                            (v: any) => v.type === item.type
                          ).items.find(
                            (v: any) => v.name === item.name
                          ).color.map(
                            (item02: any, index02: number) =>
                              <div
                                className={css(styles.dropdownItem)}
                                onClick={() => {
                                  const newArray = _.cloneDeep(subGoods);
                                  newArray[index].color = item02;
                                  setSubGoods(newArray);
                                  setSubColor(-1);
                                }}
                              >
                                {item02}
                              </div>
                          )}
                        </div>
                      )}
                    </div>
                    <input
                      style={{width: '120px', textAlign: 'right'}}
                      value={getToLocalString(item.price)}
                      className={css(styles.sheetInput)}
                      placeholder={'대당 금액을 입력해주세요.'}
                      onChange={(e: any) => {
                        const newArray = _.cloneDeep(subGoods);
                        newArray[index].price = e.target.value.replace(/[^0-9]/g, '');
                        setSubGoods(newArray);
                      }}
                    />
                    <input
                      style={{width: '40px', textAlign: 'right'}}
                      value={getToLocalString(item.quantity)}
                      className={css(styles.sheetInput)}
                      placeholder={'00'}
                      onChange={(e: any) => {
                        const newArray = _.cloneDeep(subGoods);
                        newArray[index].quantity = e.target.value.replace(/[^0-9]/g, '');
                        setSubGoods(newArray);
                      }}
                    />
                    <input
                      style={{width: '120px', color: '#403CFF', textAlign: 'right'}}
                      value={getToLocalString(item.price * item.quantity)}
                      className={css(styles.sheetInput)}
                    />
                    <div
                      className={css(styles.sheetHeaderDeleteBox)}
                      onClick={() => {
                        const newArray = _.cloneDeep(subGoods);
                        setSubGoods(newArray.filter((value, idx) => idx !== index));
                      }}
                    >
                      <Image src={Delete} alt={'delete'}/>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </SectionFrame>

        <SectionFrame
          title={'전기공사비용'}
          isShown={showElecInstallCost}
          onClickHidden={() => setShowElecInstallCost(!showElecInstallCost)}
        >
          <div>
            <div className={css(styles.sheetFrame)}>
              <div>
                <div
                  style={{
                    height: '30px',
                    backgroundColor: '#F4F4F8',
                    fontFamily: 'Pretendard',
                    fontSize: '11px',
                    fontWeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                  }}
                >
                  <div style={{marginRight: '10px'}}>
                    금액
                  </div>
                  <input
                    type={"number"}
                    value={elecInstallCost}
                    className={css(
                      styles.sheetInput,
                      styles.sheetInputTextRight
                    )}
                    style={{width: '120px'}}
                    onChange={(e) => {
                      const newElecInstallCost = parseInt(e.target.value);
                      if (!isNaN(newElecInstallCost) && newElecInstallCost >= 0) {
                        setElecInstallCost(newElecInstallCost);
                      }
                    }}
                    readOnly={true}
                    disabled={true}
                  />
                  &nbsp;&nbsp;원
                </div>
              </div>
            </div>
          </div>
        </SectionFrame>

        <SectionFrame
          title={'부자재/전기공사비용 입금 계좌번호'}
          isShown={showAccountForSubGoodsAndElecInstall}
          onClickHidden={() => setShowAccountForSubGoodsAndElecInstall(!showAccountForSubGoodsAndElecInstall)}
        >
          <div>
            <div className={css(styles.sheetFrame)}>
              <div className={css(styles.sheetHeader)}>
                <div style={{width: '100px', marginLeft: '10px'}}>선택</div>
                <div style={{width: '200px'}}>은행</div>
                <div style={{width: '200px'}}>계좌번호</div>
                <div style={{width: '200px'}}>예금주명</div>
              </div>
              <div>
                {ACCOUNTS.map((item, index) =>
                  <div className={css(styles.sheetList)} key={'accounts' + index}>
                    <div
                      style={{
                        width: '100px',
                        marginLeft: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setAccountIndexForSubGoodsAndElecInstall(index);
                        setAccountForSubGoodsAndElecInstall(ACCOUNTS[index]);
                      }}
                    >
                      <Image
                        src={accountIndexForSubGoodsAndElecInstall === index ? RadioBlue : RadioDefault}
                        alt={''}
                      />
                      <div
                        style={{
                          height: '30px',
                          backgroundColor: '#F4F4F8',
                          fontFamily: 'Pretendard',
                          fontSize: '11px',
                          fontWeight: 600,
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '5px',
                          paddingRight: '5px',
                        }}
                      >
                        {item.name}
                      </div>
                    </div>
                    <div style={{width: '180px'}} className={css(styles.sheetInput)}>
                      {item.bank === '신한은행' &&
                          <Image
                              src={ShinhanLogo}
                              alt={'bank'}
                              style={{width: '80px'}}
                          />
                      }
                      {item.bank === '기업은행' &&
                          <Image
                              src={IbkLogo}
                              alt={'bank'}
                              style={{width: '80px'}}
                          />
                      }
                    </div>
                    <div style={{width: '180px'}} className={css(styles.sheetInput)}>
                      {item.number}
                    </div>
                    <div style={{width: '180px'}} className={css(styles.sheetInput)}>
                      {item.owner}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </SectionFrame>

        <SectionFrame title={'안내사항'} isShown={showInfo} onClickHidden={() => setShowInfo(!showInfo)}>
          <div className={css(styles.sheetFrame)}>
            <textarea
              className={css(styles.textareaStyle)}
              value={info}
              onChange={(e) => setInfo(e.target.value)}
            />
          </div>
        </SectionFrame>

        <SectionFrame
          title={'설치관련 유의사항'}
          isShown={showInstallInfo}
          onClickHidden={() => setShowInstallInfo(!showInstallInfo)}
        >
          <div className={css(styles.sheetFrame)}>
            <textarea
              className={css(styles.textareaStyle)}
              value={installInfo}
              onChange={(e) => setInstallInfo(e.target.value)}
            />
          </div>
        </SectionFrame>

        <SectionFrame
          title={'A/S 기간 안내'}
          isShown={showElectronDeadline}
          onClickHidden={() => setShowElectronDeadline(!showElectronDeadline)}
        >
          <div>
            <div className={css(styles.asSelectFrame)}>
              {ELECTRON_DEADLINES.map((item, index) =>
                <div
                  onClick={() => {
                    setElectronDeadline(ELECTRON_DEADLINES[index].content);
                    setElectronDeadlineNum(index);
                  }}
                  className={css(styles.asSelectList)}
                  key={'electronDeadlines' + index}
                >
                  <div
                    className={css(
                      styles.asSelectListCheckBox,
                      index === electronDeadlineNum && styles.asSelectListCheckBoxSelected
                    )}
                  >
                    <div className={css(styles.asSelectListCheckCircle)}/>
                  </div>
                  {item.title}
                </div>
              )}
            </div>
            <div className={css(styles.sheetFrame)}>
              <textarea
                className={css(styles.textareaStyle)}
                value={electronDeadline}
                onChange={(e) => setElectronDeadline(e.target.value)}
              />
            </div>
          </div>
        </SectionFrame>

        <SectionFrame
          title={'수신자 정보'}
          isShown={showReceiverInfo}
          onClickHidden={() => setShowReceiverInfo(!showReceiverInfo)}
        >
          <div className={css(styles.sheetFrame)}>
            <div className={css(styles.sheetHeader)}>
              <div style={{width: '200px'}}>매장명</div>
              <div style={{width: '200px'}}>대표자명</div>
              <div style={{width: '200px'}}>연락처</div>
            </div>
            <div>
              <div className={css(styles.sheetList)}>
                <input
                  style={{width: '200px'}}
                  className={css(styles.sheetInput)}
                  placeholder={'매장명을 입력해주세요.'}
                  value={customer.shopName}
                  onChange={(e: any) => {
                    const newCustomer = _.cloneDeep(customer);
                    newCustomer.shopName = e.target.value;
                    setCustomer(newCustomer);
                  }}
                />
                <input
                  style={{width: '200px'}}
                  className={css(styles.sheetInput)}
                  placeholder={'대표자명을 입력해주세요.'}
                  value={customer.owner}
                  onChange={(e: any) => {
                    const newCustomer = _.cloneDeep(customer);
                    newCustomer.owner = e.target.value;
                    setCustomer(newCustomer);
                  }}
                />
                <input
                  style={{width: '200px'}}
                  className={css(styles.sheetInput)}
                  placeholder={'연락처를 입력해주세요.'}
                  value={customer.phone}
                  onChange={(e: any) => {
                    const newCustomer = _.cloneDeep(customer);
                    newCustomer.phone = e.target.value;
                    setCustomer(newCustomer);
                  }}
                />
              </div>
            </div>
          </div>
        </SectionFrame>

        <div className={css(styles.previewContentContour)}/>

        <SectionFrame title={'안내 멘트'} isShown={true}>
          <div className={css(styles.sheetFrame)}>
            {copyComments().map((item, index) =>
              <div className={css(styles.commentBox)} key={'comment_' + index}>
                {item.text
                  ? (
                    <>
                      <textarea
                        className={css(styles.commentTextarea)}
                        value={item.text}
                        style={{height: `${item.height}px`}}
                      />
                      <div
                        className={css(styles.commentCopyButton)}
                        onClick={() => {
                          copyText(item.text);
                          setIsCopy(true);
                          setTimeout(() => setIsCopy(false), 1000);
                        }}
                      >
                        복사
                      </div>
                    </>
                  )
                  : (
                    <>
                      <div
                        className={css(styles.commentTextarea, styles.commentImg)}
                        style={{height: `${item.height}px`}}
                      >
                        <Image src={item.image} alt={'img'} style={{width: '150px'}}/>
                      </div>
                      <div
                        className={css(styles.commentCopyButton)}
                        onClick={() => {
                          copyLocalImageToClipboard(item.image ?? '');
                          setIsCopy(true);
                          setTimeout(() => setIsCopy(false), 1000);
                        }}
                      >
                        복사
                      </div>
                    </>
                  )
                }
              </div>
            )}
          </div>
        </SectionFrame>

      </div>

      <div>

        <div className={css(styles.previewFrame)} id={'estimation'}>
          <div className={css(styles.previewHeaderFrame)}>
            <div>
              <Image src={Logo} alt={'logo'} className={css(styles.previewHeaderLogo)}/>
              <div className={css(styles.previewHeaderTitle)}>
                스마트 상점 견적서
              </div>
            </div>
            <div className={css(styles.previewHeaderRight)}>
              <div className={css(styles.previewHeaderRightSheet)}>
                <div className={css(styles.previewHeaderRightSheetList)}>
                  <div className={css(styles.previewHeaderRightSheetListTitle)}>견적담당자</div>
                  <div>{customer.manager}</div>
                </div>
                <div className={css(styles.previewHeaderRightSheetList)}>
                  <div className={css(styles.previewHeaderRightSheetListTitle)}>견적일</div>
                  <div>{today.format('YYYY년 MM월 DD일')}</div>
                </div>
              </div>
              <div className={css(styles.previewHeaderRightInfo)}>※ 해당 견적은 <span
                className={css(styles.textHighlight)}>{deadline}</span>까지 유효합니다.
              </div>
            </div>
          </div>

          <div className={css(styles.previewContent)}>

            {showInstallment &&
              <div className={css(styles.previewContentSubGoods)}>
                <div
                  className={css(
                    styles.previewContentSubGoodsTitle,
                    !showSubGoodsDetail && styles.borderBottomNone,
                    !showSubGoodsDetail && styles.marginBottomNone
                  )}
                >
                  <div>스마트 상점</div>
                  <div>
                    {totalCostForTabs.toLocaleString()}
                    <span className={css(styles.priceUnit)}>원</span>
                  </div>
                </div>
                {showSubGoodsDetail && (
                  <div className={css(styles.previewContentSubGoodsContent)}>
                    <div className={css(styles.previewContentSubGoodsContentTitle)}>
                      {'<상세내역>'}
                    </div>

                    <div
                      className={css(
                        styles.previewContentBlueSheetFrame,
                        styles.marginNone
                      )}
                    >
                      <div className={css(styles.previewContentSubGoodsContentSheet)}>
                        <div className={css(styles.previewContentSubGoodsContentSheetHeader)}>
                          <div style={{minWidth: '100px', width: '100px'}}>품목</div>
                          <div style={{minWidth: '60px', width: '60px', textAlign: 'center'}}>약정</div>
                          <div style={{minWidth: '160px', width: '160px', textAlign: 'center'}}>
                            대당 월 금액
                            <span className={css(styles.textRed)} style={{fontSize: '12px'}}>
                              (VAT 미포함)
                            </span>
                          </div>
                          <div style={{minWidth: '60px', width: '60px', textAlign: 'center'}}>수량</div>
                          <div style={{width: '105px', textAlign: 'right'}}>월 할부금</div>
                        </div>
                        {installment.map((item, index) =>
                          <div
                            className={css(styles.previewContentSubGoodsContentSheetList)}
                            key={'installment' + index}
                          >
                            <div style={{minWidth: '100px', width: '100px'}}>{item.name}</div>
                            <div style={{minWidth: '60px', width: '60px', textAlign: 'center'}}>
                              {item.month} 개월
                            </div>
                            <div style={{minWidth: '160px', width: '160px', textAlign: 'center'}}>
                              {Math.floor(item.price).toLocaleString()} 원
                            </div>
                            <div style={{minWidth: '60px', width: '60px', textAlign: 'center'}}>
                              {Math.floor(item.quantity).toLocaleString()} 개
                            </div>
                            <div style={{width: '105px', textAlign: 'right'}}>
                              {Math.floor(
                                item.price * item.quantity
                              ).toLocaleString()} 원
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                  </div>
                )}
              </div>
            }

            {showBasicSubGoods &&
              <div className={css(styles.previewContentSubGoods)}>
                <div
                  className={css(
                    styles.previewContentSubGoodsTitle,
                    !showSubGoodsDetail && styles.borderBottomNone,
                    !showSubGoodsDetail && styles.marginBottomNone
                  )}
                >
                  <div>기본 부자재 구성</div>
                  <div>
                    {getToLocalString(basicSubGoodsTotal)}
                    <span className={css(styles.priceUnit)}>원</span>
                  </div>
                </div>
                {showBasicSubGoodsDetail && (
                  <div className={css(styles.previewContentSubGoodsContent)}>
                    <div className={css(styles.previewContentSubGoodsContentTitle)}>
                      {'<상세내역>'}
                    </div>
                    <div className={css(styles.previewContentSubGoodsContentSheet)}>
                      <div className={css(styles.previewContentSubGoodsContentSheetHeader)}>
                        <div style={{width: '140px'}}>품목</div>
                        <div style={{width: '90px', textAlign: 'center'}}>모델명</div>
                        <div style={{width: '75px', textAlign: 'center'}}>색상</div>
                        <div style={{width: '100px', textAlign: 'center'}}>대당금액</div>
                        <div style={{width: '70px', textAlign: 'center'}}>수량</div>
                        <div style={{width: '110px', textAlign: 'right'}}>금액</div>
                      </div>
                      {basicSubGoods.map(
                        (item, index) => item.type
                          && (
                            <div
                              className={css(styles.previewContentSubGoodsContentSheetList)}
                              key={'basicSubGoods' + index}
                            >
                              <div style={{width: '140px'}}>{item.type}</div>
                              <div style={{width: '90px', textAlign: 'center'}}>
                                {item.name === '' ? '-' : item.name}
                              </div>
                              <div style={{width: '75px', textAlign: 'center'}}>
                                {item.color === '' ? '-' : item.color}
                              </div>
                              <div style={{width: '100px', textAlign: 'center'}}>
                                {getToLocalString(item.price, true, ' 원')}
                              </div>
                              <div style={{width: '70px', textAlign: 'center'}}>
                                {getToLocalString(item.quantity, true, ' 개')}
                              </div>
                              <div style={{width: '110px', textAlign: 'right'}}>
                                {getToLocalString(item.price * item.quantity, true, ' 원')}
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                )}
              </div>
            }

            {showVat &&
              <div
                style={{
                  width: '100%',
                  height: '115px',
                  backgroundColor: 'white',
                  borderRadius: '5px',
                  border: 'solid 1px #D8D6ED',
                  padding: '19px 16px',
                  marginBottom: ' 20px',
                  boxSizing: 'border-box',
                }}
              >
                <div className={css(styles.previewContentInitialDepositTitle)}>
                  <div>부가세 {vatRatio}%</div>
                  <div>
                    {getToLocalString(Math.floor(vatAmount))}
                    <span className={css(styles.priceUnit)}>원</span>
                  </div>
                </div>
                <div className={css(styles.previewContentInitialDepositInfo)}>
                  스마트 상점 + 기본 부자재 구성에 대한 부가세입니다.
                </div>
              </div>
            }

            <div className={css(styles.previewContentContour)}/>

            <div className={css(styles.previewContentTotal)}>
              <div className={css(styles.previewContentTotalTitle)}>
                합계&nbsp;
              </div>
              <div className={css(styles.previewContentTotalPrice)}>
                {getToLocalString(totalCostForTabsAndBasicSubGoods + vatAmount)}
                <span className={css(styles.previewContentTotalPriceUnit)}>원</span>
              </div>
            </div>

            {showGovSupport &&
              <div
                style={{
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '5px',
                  border: 'solid 1px #D8D6ED',
                  padding: '19px 16px',
                  marginBottom: ' 20px',
                  boxSizing: 'border-box',
                }}
              >
                <div className={css(styles.previewContentInitialDepositTitle)}>
                  <div>국가지원 {govSupportRatio}%</div>
                  <div>
                    {getToLocalString(Math.floor(govSupportAmount))}
                    <span className={css(styles.priceUnit)}>원</span>
                  </div>
                </div>
                <div className={css(styles.previewContentInitialDepositInfo)}>
                  국가지원 최대 지원금은 5,000,000원 까지 입니다.<br/>
                  초과된 금액은 자부담으로 진행됩니다.
                </div>
              </div>
            }

            {showSelfPayment &&
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#FFE8EA',
                  borderRadius: '5px',
                  border: 'solid 1px #FF0018',
                  padding: '19px 16px',
                  marginBottom: ' 20px',
                  boxSizing: 'border-box',
                }}
              >
                <div className={css(styles.previewContentInitialDepositTitle)}>
                  <div>자부담 {selfPaymentRatio}%</div>
                  <div>
                    {getToLocalString(Math.floor(selfPaymentAmount))}
                    <span className={css(styles.priceUnit)}>원</span>
                  </div>
                </div>
              </div>
            }

            {showVatSelfPayment &&
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#FFE8EA',
                  borderRadius: '5px',
                  border: 'solid 1px #FF0018',
                  padding: '19px 16px',
                  marginBottom: ' 20px',
                  boxSizing: 'border-box',
                }}
              >
                <div className={css(styles.previewContentInitialDepositTitle)}>
                  <div>부가세 자부담 {vatRatio}%</div>
                  <div>
                    {getToLocalString(Math.floor(vatAmount))}
                      <span className={css(styles.priceUnit)}>원</span>
                  </div>
                </div>
              </div>
            }

            {showAccountForSmartStore &&
              <div className={css(styles.previewContentBankAccount)}>
                <div className={css(styles.previewContentBankAccountTitle)}>
                  스마트 상점 전용 입금 계좌번호
                </div>
                {accountForSmartStore.bank === '신한은행' &&
                  <Image
                    src={ShinhanLogo}
                    alt={'bank'}
                    className={css(styles.previewContentBankAccountBankLogo)}
                  />
                }
                {accountForSmartStore.bank === '기업은행' &&
                  <Image
                    src={IbkLogo}
                    alt={'bank'}
                    className={css(styles.previewContentBankAccountBankLogo)}
                  />
                }
                <div className={css(styles.previewContentBankAccountAccount)}>
                  {accountForSmartStore.number}
                </div>
                <div className={css(styles.previewContentBankAccountOwner)}>
                  예금주 : {accountForSmartStore.owner}
                </div>
              </div>
            }

          </div>

          {(showSubGoods || showElecInstallCost) && (
            <div className={css(styles.previewContent)}>

              {showSubGoods
                && (
                  <div className={css(styles.previewContentSubGoods)}>
                    <div
                      className={css(
                        styles.previewContentSubGoodsTitle,
                        !showSubGoodsDetail && styles.borderBottomNone,
                        !showSubGoodsDetail && styles.marginBottomNone
                      )}
                    >
                      <div>부자재 구성</div>
                      <div>
                        {getToLocalString(subGoodsTotal)}
                        <span className={css(styles.priceUnit)}>원</span>
                      </div>
                    </div>
                    {showSubGoodsDetail && (
                      <div className={css(styles.previewContentSubGoodsContent)}>
                        <div className={css(styles.previewContentSubGoodsContentTitle)}>
                          {'<상세내역>'}
                        </div>
                        <div className={css(styles.previewContentSubGoodsContentSheet)}>
                          <div className={css(styles.previewContentSubGoodsContentSheetHeader)}>
                            <div style={{width: '140px'}}>품목</div>
                            <div style={{width: '90px', textAlign: 'center'}}>모델명</div>
                            <div style={{width: '75px', textAlign: 'center'}}>색상</div>
                            <div style={{width: '100px', textAlign: 'center'}}>대당금액</div>
                            <div style={{width: '70px', textAlign: 'center'}}>수량</div>
                            <div style={{width: '110px', textAlign: 'right'}}>금액</div>
                          </div>
                          {subGoods.map(
                            (item, index) => item.type
                              && (
                                <div className={css(styles.previewContentSubGoodsContentSheetList)}>
                                  <div style={{width: '140px'}}>{item.type}</div>
                                  <div style={{width: '90px', textAlign: 'center'}}>
                                    {item.name === '' ? '-' : item.name}
                                  </div>
                                  <div style={{width: '75px', textAlign: 'center'}}>
                                    {item.color === '' ? '-' : item.color}
                                  </div>
                                  <div style={{width: '100px', textAlign: 'center'}}>
                                    {getToLocalString(item.price, true, ' 원')}
                                  </div>
                                  <div style={{width: '70px', textAlign: 'center'}}>
                                    {getToLocalString(item.quantity, true, ' 개')}
                                  </div>
                                  <div style={{width: '110px', textAlign: 'right'}}>
                                    {getToLocalString(item.price * item.quantity, true, ' 원')}
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}

              {showElecInstallCost &&
                <div
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    border: 'solid 1px #D8D6ED',
                    padding: '19px 16px',
                    marginBottom: '20px',
                    boxSizing: 'border-box',
                  }}
                >
                  <div className={css(styles.previewContentInitialDepositTitle)}>
                    <div>전기공사비용</div>
                    <div>
                      {getToLocalString(Math.floor(elecInstallCost))}
                      <span className={css(styles.priceUnit)}>원</span>
                    </div>
                  </div>
                </div>
              }

              <div className={css(styles.previewContentContour)}/>

              <div className={css(styles.previewContentTotal)}>
                <div className={css(styles.previewContentTotalTitle)}>
                  합계&nbsp;
                </div>
                <div className={css(styles.previewContentTotalPrice)}>
                  {getToLocalString(totalCostForSubGoodsAndElecInstall)}
                  <span className={css(styles.previewContentTotalPriceUnit)}>원</span>
                </div>
              </div>

              {showAccountForSubGoodsAndElecInstall &&
                <div className={css(styles.previewContentBankAccount)}>
                  <div className={css(styles.previewContentBankAccountTitle)}>
                    부자재/전기공사비용 입금 계좌번호
                  </div>
                  {accountForSubGoodsAndElecInstall.bank === '신한은행' &&
                    <Image
                      src={ShinhanLogo}
                      alt={'bank'}
                      className={css(styles.previewContentBankAccountBankLogo)}
                    />
                  }
                  {accountForSubGoodsAndElecInstall.bank === '기업은행' &&
                    <Image
                      src={IbkLogo}
                      alt={'bank'}
                      className={css(styles.previewContentBankAccountBankLogo)}
                    />
                  }
                  <div className={css(styles.previewContentBankAccountAccount)}>
                    {accountForSubGoodsAndElecInstall.number}
                  </div>
                  <div className={css(styles.previewContentBankAccountOwner)}>
                    예금주 : {accountForSubGoodsAndElecInstall.owner}
                  </div>
                </div>
              }

            </div>
          )}

          {showInfo
            && (
              <div className={css(styles.previewContent, styles.previewContentInfo)}>
                <div className={css(styles.previewContentInfoTitle)}>안내사항</div>
                <div className={css(styles.previewContentInfoContent)}>
                  {info}
                </div>
              </div>
            )}
          {showInstallInfo
            && (
              <div className={css(styles.previewContent, styles.previewContentInfo)}>
                <div className={css(styles.previewContentInfoTitle)}>설치관련 유의사항</div>
                <div className={css(styles.previewContentInfoContent)}>
                  {installInfo}
                </div>
              </div>
            )}
          {showElectronDeadline
            && (
              <div className={css(styles.previewContent, styles.previewContentInfo)}>
                <div className={css(styles.previewContentInfoTitle)}>A/S 기간 안내</div>
                <div className={css(styles.previewContentInfoContent)}>
                  {electronDeadline}
                </div>
              </div>
            )}
          <div className={css(styles.previewContentDoubleInfo)}>

            {showReceiverInfo &&
                <div className={css(styles.previewContentDoubleInfoFrame)}>
                    <div className={css(styles.previewContentDoubleInfoFrameTitle)}>수신자 정보</div>
                    <div className={css(styles.previewContentDoubleInfoFrameList)}>
                        <div className={css(styles.previewContentDoubleInfoFrameListTitle)}>매장</div>
                        <div>
                          {customer.shopName === undefined
                          || customer.shopName === null
                          || customer.shopName === ''
                            ? <span style={{color: 'red'}}>매장명을 입력해주세요.</span>
                            : customer.shopName
                          }
                        </div>
                    </div>
                    <div className={css(styles.previewContentDoubleInfoFrameList)}>
                        <div className={css(styles.previewContentDoubleInfoFrameListTitle)}>대표자</div>
                        <div>
                          {customer.owner === undefined
                          || customer.owner === null
                          || customer.owner === ''
                            ? <span style={{color: 'red'}}>대표자명을 입력해주세요.</span>
                            : customer.owner + ' 대표님'
                          }
                        </div>
                    </div>
                    <div className={css(styles.previewContentDoubleInfoFrameList)}>
                        <div className={css(styles.previewContentDoubleInfoFrameListTitle)}>연락처</div>
                        <div>
                          {customer.phone === undefined
                          || customer.phone === null
                          || customer.phone === ''
                            ? '연락처를 입력해주세요.'
                            : customer.phone
                          }
                        </div>
                    </div>
                </div>
            }

            <div className={css(styles.previewContentDoubleInfoFrame)}>
              <div className={css(styles.previewContentDoubleInfoFrameTitle)}>공급자 정보</div>
              <div className={css(styles.previewContentDoubleInfoFrameList)}>
                <div className={css(styles.previewContentDoubleInfoFrameListProducerTitle)}>매장</div>
                <div>티오더</div>
              </div>
              <div className={css(styles.previewContentDoubleInfoFrameList)}>
                <div className={css(styles.previewContentDoubleInfoFrameListProducerTitle)}>주소</div>
                <div>서울특별시 영등포구 여의대로 108, 파크원 타워2 46층 티오더</div>
              </div>
              <div className={css(styles.previewContentDoubleInfoFrameList)}>
                <div className={css(styles.previewContentDoubleInfoFrameListProducerTitle)}>사업자번호
                </div>
                <div>861-81-01247</div>
              </div>
              <div className={css(styles.previewContentDoubleInfoFrameList)}>
                <div className={css(styles.previewContentDoubleInfoFrameListProducerTitle)}>대표자</div>
                <div>권성택</div>
              </div>
            </div>
          </div>
        </div>
        <div className={css(styles.buttonBox)}>
          <div
            onClick={() => saveImage(document.querySelector('#estimation')!, id!)}
            className={css(styles.button)} style={{backgroundColor: '#403CFF'}}
          >
            파일로 저장
          </div>

          <div
            onClick={() => {
              saveImageInClipBoard(document.querySelector('#estimation')!, id!)
              setIsCopy(true);
              setTimeout(() => setIsCopy(false), 1000)
            }}
            className={css(styles.button)}
            style={{backgroundColor: '#333333'}}
          >
            복사
          </div>

          {/*<div onClick={() => sendImageByAlimtalk()} className={css(styles.button)}*/}
          {/*     style={{backgroundColor: '#3A1D1D'}}>알림톡 전송*/}
          {/*</div>*/}
          {/*<div onClick={() => sendVisitCompleteAlimtalk()} className={css(styles.button)}*/}
          {/*     style={{backgroundColor: '#00AA00'}}>방문완료*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
}

const styles = CreateBetaStyle({
  estimateWriteFrame: {
    width: '770px',
    maxWidth: '920px',

    paddingRight: '20px',

    borderRight: 'solid 1px #C7C7C7',

    marginRight: '19px',

    boxSizing: 'border-box',
  },
  estimateWriteBasic: {
    width: '750px',

    display: 'flex',
    justifyContent: 'space-between',
  },
  estimateWriteBasicManager: {
    fontSize: '20px',
    fontWeight: 700,

    marginTop: '10px',
  },
  estimateWriteBasicDate: {
    fontSize: '20px',
    fontWeight: 700,

    marginTop: '10px',
  },
  estimateWriteBasicDeadline: {
    display: 'flex',
    alignItems: 'center',

    fontSize: '12px',
    fontWeight: 500,

    marginTop: '8px',
  },
  estimateWriteBasicDeadlineInput: {
    width: '40px',
    height: '27px',

    border: 'solid 1px #888895',
    borderRadius: '5px',

    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 700,

    marginRight: '5px',
    marginLeft: '8px',
  },
  asSelectFrame: {
    display: 'flex',

    marginBottom: '16px',
  },
  asSelectList: {
    display: 'flex',
    alignItems: 'center',

    marginRight: '20px',

    fontSize: '15px',

    cursor: 'pointer',
  },
  asSelectListCheckBox: {
    width: '15px',
    height: '15px',

    borderRadius: '100%',
    border: 'solid 1px #AAB3D1',

    marginRight: '5px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  asSelectListCheckBoxSelected: {
    backgroundColor: '#403CFF',
    borderColor: '#403CFF',
  },
  asSelectListCheckCircle: {
    width: '9px',
    height: '9px',

    backgroundColor: 'white',

    borderRadius: '100%',
  },
  sheetFrame: {
    width: '100%',

    backgroundColor: '#F4F4F8',

    borderRadius: '5px',
    border: 'solid 1px #DDDDDD',

    padding: '10px',
    marginBottom: '15px',

    boxSizing: 'border-box',
  },
  sheetHeader: {
    width: '100%',
    height: '32px',

    backgroundColor: 'white',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    border: 'solid 1px #D8D6ED',
    borderRadius: '5px',

    textAlign: 'center',

    fontSize: '12px',
    color: '#2F2F39',
    fontWeight: 700,

    marginBottom: '10px',
  },
  sheetInput: {
    height: '30px',

    backgroundColor: 'white',

    border: 'solid 1px #8E8E8E',
    borderRadius: '5px',

    fontFamily: 'Pretendard',
    fontSize: '11px',
    fontWeight: 600,

    display: 'flex',
    alignItems: 'center',

    paddingLeft: '5px',
    paddingRight: '5px',
  },
  sheetDropDownFrame: {
    position: 'relative',
  },
  sheetInputTextRight: {
    textAlign: 'right'
  },
  sheetHeaderType: {
    width: '170px',
  },
  sheetHeaderMonth: {
    width: '40px',
  },
  sheetHeaderPrice: {
    width: '170px',
  },
  sheetHeaderQuantity: {
    width: '40px',
  },
  sheetHeaderTotal: {
    width: '170px',
  },
  sheetHeaderDelete: {
    width: '30px',
  },
  sheetHeaderDeleteBox: {
    width: '31px',
    height: '31px',

    backgroundColor: 'white',

    borderRadius: '5px',

    filter: 'drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.173))',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    cursor: 'pointer',
  },
  sheetList: {
    width: '100%',

    display: 'flex',
    justifyContent: 'space-between',

    marginBottom: '10px',
  },
  textareaStyle: {
    width: '100%',
    height: '150px',

    borderRadius: '5px',
    border: 'solid 1px #8E8E8E',

    boxSizing: 'border-box',

    resize: 'none',

    padding: '9px 10px',

    fontSize: '13px',
    fontWeight: 500,
    fontFamily: 'Pretendard',
    lineHeight: '22px',

    '::-webkit-scrollbar': {
      width: '8px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: '#A8A8A8',
      borderRadius: '15px',
      border: '2px solid transparent',
    },
    '::-webkit-scrollbar-track': {
      // padding: '10px',

      background: 'transparent',
    },
  },
  previewFrame: {
    width: '632px',
    minWidth: '632px',

    backgroundColor: 'white',

    borderRadius: '5px',

    filter: 'drop-shadow(1px 1px 5px rgba(22, 22, 26, 0.224))',

    padding: '80px 20px 20px',
    marginBottom: '20px',

    boxSizing: 'border-box',

    fontFamily: 'Pretendard'
  },
  previewHeaderFrame: {
    width: '100%',

    display: 'flex',
    justifyContent: 'space-between',

    marginBottom: '41px',
  },
  previewHeaderTitle: {
    fontSize: '36px',
    fontWeight: 700,
  },
  previewHeaderLogo: {
    width: '150px',
    height: '36px',

    marginTop: '4px',
  },
  previewHeaderRight: {},
  previewHeaderRightSheet: {
    width: '300px',
    height: '86px',

    backgroundColor: '#F4F4F8',

    border: 'solid 1px #D8D6ED',
    borderRadius: '5px',

    padding: '14px 20px 16px',
    marginBottom: '14px',

    boxSizing: 'border-box',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  previewHeaderRightSheetList: {
    display: 'flex',

    fontSize: '16px',
  },
  previewHeaderRightSheetListTitle: {
    width: '109px',

    color: '#777777',
  },
  previewHeaderRightInfo: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#777777',
  },
  previewContent: {
    width: '592px',

    borderRadius: '5px',
    border: '1px solid black',

    backgroundColor: '#F4F4F8',

    padding: '20px 15px',
    marginBottom: '40px',

    boxSizing: 'border-box',
  },
  previewContentBlue: {
    width: '592px',

    borderRadius: '5px',
    border: '1px solid #403CFF',

    backgroundColor: '#ECEEFD',

    padding: '20px 15px',
    marginBottom: '40px',

    boxSizing: 'border-box',
  },
  previewContentBlueTitle: {
    fontSize: '22px',
    fontWeight: 700,

    display: 'flex',
    alignItems: 'center',

    marginBottom: '8px',
  },
  previewContentBlueTitleVat: {
    width: '65px',
    height: '20px',

    backgroundColor: '#333333',

    borderRadius: '100px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontSize: '11px',
    fontWeight: 700,
    color: 'white',

    marginLeft: '5px',
  },
  previewContentBlueInfo: {
    fontSize: '15px',
    color: '#555555',
    lineHeight: '26px',
    fontWeight: 500,

    marginBottom: '14px',

    whiteSpace: 'pre',
  },
  borderBottomNone: {
    border: 'none',
    marginBottom: 'none',
  },
  marginBottomNone: {
    marginBottom: 'none',
  },
  previewContentBluePrice: {
    width: '100%',
    height: '95px',

    textAlign: 'right',
    fontSize: '58px',
    color: '#403CFF',
    fontWeight: 700,

    borderBottom: 'dashed 1px #A4A4A4',

    marginBottom: '16px',
  },
  previewContentBluePriceUnit: {
    fontSize: '36px',
    color: 'black',
  },
  previewContentBlueSheetFrame: {
    width: '100%',

    backgroundColor: 'white',

    padding: '16px',

    marginBottom: '12px',

    borderRadius: '5px',
    border: 'solid 1px #D8D6ED',

    boxSizing: 'border-box',
  },
  marginNone: {
    marginBottom: '0px',
  },
  previewContentBlueSheetInfo: {
    fontSize: '15px',
    color: '#555555',
    fontWeight: 500,
    lineHeight: '28px',

    paddingLeft: '5px',

    wordBreak: 'keep-all',
  },
  previewContentInitialDeposit: {
    width: '100%',
    height: '115px',

    backgroundColor: '#ECEEFD',

    borderRadius: '5px',
    border: 'solid 1px #403CFF',

    padding: '19px 20px',
    marginBottom: ' 20px',

    boxSizing: 'border-box',
  },
  previewContentInitialDepositTitle: {
    fontSize: '28px',
    fontWeight: 700,

    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  priceUnit: {
    fontSize: '22px',
    fontWeight: 500,
    color: 'black',

    marginLeft: '3px',
  },
  previewContentInitialDepositInfo: {
    fontSize: '22px',
    fontWeight: 500,
    color: '#555555',
  },
  previewContentSubGoods: {
    width: '100%',

    backgroundColor: 'white',

    borderRadius: '5px',
    border: 'solid 1px #D8D6ED',

    padding: '19px 16px',

    boxSizing: 'border-box',

    marginBottom: '20px',
  },
  previewContentSubGoodsTitle: {
    height: '60px',

    borderBottom: '1px dashed #A4A4A4',

    fontSize: '28px',
    fontWeight: 700,

    display: 'flex',
    justifyContent: 'space-between',

    marginBottom: '19px',
  },
  previewContentSubGoodsContent: {},
  previewContentSubGoodsContentTitle: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#777777',

    marginBottom: '16px',
  },
  previewContentSubGoodsContentSheet: {},
  previewContentSubGoodsContentSheetHeader: {
    height: '38px',

    backgroundColor: '#F4F4F8',

    fontSize: '16px',
    color: '#777777',
    fontWeight: 500,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    borderTop: 'solid 1px #D8D6ED',
    borderBottom: 'solid 1px #D8D6ED',

    padding: '0px 15px',
  },
  previewContentSubGoodsContentSheetList: {
    minHeight: '45px',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    fontSize: '15px',
    fontWeight: 500,

    borderBottom: 'solid 1px #DDDDDD',

    padding: '5px 15px',
  },
  previewContentContour: {
    width: '100%',

    borderTop: '1px dashed #A4A4A4',
  },
  previewContentTotal: {
    width: '100%',
    height: '195px',

    backgroundColor: '#ECEEFD',

    borderRadius: '5px',
    border: 'solid 1px #403CFF',

    marginTop: '20px',
    marginBottom: '20px',
    padding: '20px 23px',

    boxSizing: 'border-box',

    position: 'relative',
  },
  previewContentTotalTitle: {
    fontSize: '26px',
    fontWeight: 700,

    marginBottom: '29px',
  },
  previewContentTotalTitleInfo: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#555555',
  },
  previewContentTotalPrice: {
    fontWeight: 700,
    fontSize: '70px',

    position: 'absolute',
    right: '23px',
  },
  previewContentTotalPriceUnit: {
    fontWeight: 500,
    fontSize: '46px',
  },
  previewContentBankAccount: {
    backgroundColor: 'white',

    borderRadius: '5px',
    border: 'solid 1px #D8D6ED',

    fontWeight: 700,

    padding: '20px 20px 32px',

    boxSizing: 'border-box',
  },
  previewContentBankAccountTitle: {
    fontSize: '26px',

    marginBottom: '21px',
  },
  previewContentBankAccountBankLogo: {
    width: '210px',

    marginBottom: '7px',
  },
  previewContentBankAccountAccount: {
    fontSize: '50px',

    marginBottom: '11px',
  },
  previewContentBankAccountOwner: {
    fontSize: '38px',
    fontWeight: 500,
  },
  previewContentInfo: {
    border: 'none',
  },
  previewContentInfoTitle: {
    fontSize: '26px',
    fontWeight: 700,

    marginBottom: '16px',
  },
  previewContentInfoContent: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '36px',

    whiteSpace: 'pre-wrap',
  },
  previewContentDoubleInfo: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-between',
  },
  previewContentDoubleInfoFrame: {
    width: '100%',
    height: '320px',

    backgroundColor: '#F4F4F8',

    borderRadius: '5px',

    padding: '20px',

    boxSizing: 'border-box',
  },
  previewContentDoubleInfoFrameTitle: {
    fontSize: '26px',
    fontWeight: 700,

    marginBottom: '20px',
  },
  previewContentDoubleInfoFrameList: {
    display: 'flex',

    fontSize: '17px',
    lineHeight: '25px',

    marginBottom: '15px',

    wordBreak: 'keep-all',
  },
  previewContentDoubleInfoFrameListTitle: {
    width: '60px',

    color: '#777777',
    fontWeight: 500,
  },
  previewContentDoubleInfoFrameListProducerTitle: {
    width: '114px',
    minWidth: '114px',

    color: '#777777',
    fontWeight: 500,
  },
  dropdownFrame: {
    width: '100%',
    // height: '72px',

    backgroundColor: 'white',

    borderRadius: '5px',
    border: 'solid 1px #8E8E8E',

    padding: '5px',

    overflowY: 'scroll',

    position: 'absolute',
    top: '35px',
    left: 0,
    zIndex: 200,

    boxSizing: 'border-box',

    '::-webkit-scrollbar': {
      width: '8px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: '#A8A8A8',
      borderRadius: '15px',
      border: '2px solid transparent',
    },
    '::-webkit-scrollbar-track': {
      // padding: '10px',

      background: 'transparent',
    },
  },
  dropdownItem: {
    width: '100%',
    height: '30px',

    display: 'flex',
    alignItems: 'center',

    fontSize: '11px',
    fontWeight: 500,

    paddingLeft: '8px',

    boxSizing: 'border-box',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: 'rgba(217, 217, 217, 0.21)',
    }
  },
  textHighlight: {
    color: '#FF0018',

    borderBottom: 'solid 1px #FF0018',
  },
  textRed: {
    color: '#FF0018',
  },
  buttonBox: {
    width: '635px',
    height: '40px',

    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: '310px',
    height: '60px',
    borderRadius: '5px',

    fontSize: '20px',
    color: 'white',
    fontWeight: 500,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    cursor: 'pointer',
  },
  commentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    marginBottom: '10px',
  },
  commentTextarea: {
    width: '90%',
    // height: 'max-content',

    resize: 'none',

    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Pretendard',

    borderRadius: '5px',
    border: 'solid 1px #888895',

    padding: '5px',
  },
  commentImg: {
    overflowY: 'scroll',

    backgroundColor: 'white',
  },
  commentCopyButton: {
    width: '40px',
    height: '30px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    borderRadius: '5px',

    backgroundColor: 'rgb(64, 60, 255)',

    color: 'white',
    fontSize: '14px',
    fontWeight: 500,

    cursor: 'pointer',
  },
  checkBoxFrame: {
    display: 'flex',
    alignItems: 'center',
  },
  checkBox: {
    width: '18px',
    height: '18px',

    backgroundColor: 'white',

    borderRadius: '5px',
    border: 'solid 1px #8E8E8E',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginRight: '5px',

    cursor: 'pointer',
  },
  checkBoxSelected: {
    backgroundColor: '#403CFF',

    border: 'solid 1px #403CFF'
  },

  notification: {
    width: '200px',
    height: '60px',

    backgroundColor: 'rgba(64, 60, 255, 0.6)',

    position: 'fixed',
    right: '-220px',
    top: '20px',
    opacity: 0,
    zIndex: 105,

    fontSize: '15px',
    color: 'white',
    fontWeight: 700,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    borderRadius: '5px',

    transition: 'opacity 0.3s, right 0.3s'
  },
  notificationDisplay: {
    right: '20px',
    opacity: 1,
    transition: 'opacity 0.3s, right 0.3s'
  },
});
