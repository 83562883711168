import React, {useEffect, useState} from 'react';
import {css} from 'aphrodite';
import moment from 'moment';
import {Helmet, HelmetProvider} from 'react-helmet-async';

import Loading from '../../shared/components/Loading';
import {CreateBetaStyle} from "../../shared/CreateStyle";
import {LogisticsNoticePopup} from './LogisticsNoticePopup';
import {GoodsPreview} from './SelectItem';
import {ShopInfoList} from './ShopInfoList';
import {RequestInfoList} from './RequestInfoList';
import {GoodsStock} from './GoodsStock';
import {GoodsList} from './GoodsList';

import {
  createCustomerInfoInLogisticsTable,
  getGoodsTypes,
  getOptionsByFieldId,
} from '../services/LogisticsService';

import {
  addIsErrorInObject,
  getGoodsList,
  setCustomerInfos
} from '../helpers/LogisticsHelper';

import Calendar from '../../../assets/images/svgs/calendar.svg';
import Team from '../../../assets/images/svgs/team.svg';
import User from '../../../assets/images/svgs/user.svg';
import Phone from '../../../assets/images/svgs/phone.svg';
import Check from '../../../assets/images/svgs/check.svg';
import Shop from '../../../assets/images/svgs/shop.svg';
import Text from '../../../assets/images/svgs/text.svg';
import Truck from '../../../assets/images/svgs/truck.svg';
import Install from '../../../assets/images/svgs/install.svg';

export const AdditionalForm = () => {
  const [popup, setPopup] = useState({isShow: false, message: '', buttonType: 'loading'});
  const [goodsList, setGoodsList] = useState([]);
  const [selected, setSelected] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [goodsFocus, setGoodsFocus] = useState('');
  const [dropDown, setDropDown] = useState('');
  const [requestInfo, setRequestInfo] = useState<any>([]);
  const [shopInfo, setShopInfo] = useState<any>([]);
  const [screw, setScrew] = useState('');
  const [typeDetailNum, setTypeDetailNum] = useState(0);
  const [preview, setPreview] = useState('');

  useEffect(() => {
    getOptionsByFieldId(
      {
        table: 'logistics',
        fieldId: [
          process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_REQUEST_TEAM_FIELD_ID!,
          process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_RELEASE_TYPE_FIELD_ID!,
          process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_INSTALLER_FIELD_ID!,
          process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_DELIVERY_TYPE_FIELD_ID!
        ],
      }
    ).then((options: any) => {
      const REQUEST_INFO = [
        {
          title: '작성일자',
          img: Calendar,
          value: moment().format('YYYY-MM-DD'),
          isEdit: false,
        },
        {
          title: '요청부서',
          img: Team,
          value: '',
          options: options.find((v: any) => (
              v.id === process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_REQUEST_TEAM_FIELD_ID!
            )).options.choices,
          isEdit: true,
          isDropdown: true,
        },
        {
          title: '담당자',
          img: User,
          value: '',
          isEdit: true,
        },
        {
          title: '연락처',
          img: Phone,
          value: '',
          isEdit: true,
        },
        {
          title: '출고유형',
          img: Check,
          value: '',
          options: [
            {name: '추가구매'},
            {name: 'A/S'},
            {name: '긴급민원'},
            // 230516 16:00 수정(보미님 요청) 긴급민원_고객지원팀 -> 긴급민원,
            {name: '샘플'},
            {name: '본사 출고(샘플X)'},
          ],
          isEdit: true,
          isDropdown: true,
        },
        {
          title: '배송방법',
          img: Truck,
          value: '',
          options: options.find((v: any) => (
              v.id === process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_DELIVERY_TYPE_FIELD_ID!
            )).options.choices,
          isEdit: true,
          isDropdown: true,
        },
        {
          title: '설치업체',
          img: Install,
          value: '',
          options: options.find((v: any) => (
              v.id === process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_INSTALLER_FIELD_ID!
            )).options.choices,
          isEdit: true,
          isDropdown: true,
        },
      ];

      const SHOP_INFO = [
        {
          title: '상호명',
          img: Shop,
          value: '',
          isRequired: true,
        },
        {
          title: '지점명',
          img: Text,
          value: '',
          isRequired: true,
        },
        {
          title: '배송지',
          img: Truck,
          value: '',
          isRequired: true,
        },
        {
          title: '연락처',
          img: Phone,
          value: '',
          isRequired: true,
        },
        {
          title: '요청사항(내부용)',
          img: Text,
          value: '',
          isTextArea: true,
          isRequired: false,
        },
        {
          title: '물류신청서 특이사항(인쇄용)',
          img: Text,
          value: '',
          isRequired: false,
        },
      ];

      setRequestInfo(REQUEST_INFO);
      setShopInfo(SHOP_INFO);
    })
  }, []);


  const onClickComplete = async () => {
    setIsLoading(true);

    try {
      // 작성완료 시, 신청 정보 유효성 체크
      const isValidForRequestInfo = requestInfo.filter((item: any) => (
        item.value === undefined
          || item.value === null
          || item.value === ''
        )).length === 0;

      // 작성완료 시, 매장 정보 유효성 체크
      const isValidForShopInfo = shopInfo.filter((item: any) => (
        item.isRequired === true
          && (
            item.value === undefined
            || item.value === null
            || item.value === ''
          )
        )).length === 0;

      if (!isValidForRequestInfo || !isValidForShopInfo) {
        throw Error('신청정보 혹은 매장정보에 \n누락된 정보가 있습니다.');
      }

      selected.map((item1: any) => {
        const filterByType: any = goodsList.filter((item2: any) => (
          item2.type === item1.type
        ));
        const filterById: any = filterByType[0].items.filter((item2: any) => (
          item2.id === item1.id
        ));

        if (item1.amount === 0 || item1.amount === '' || item1.amount === '0') {
          setSelected(addIsErrorInObject({
            array: selected,
            id: item1.id,
            add: ['isZero'],
            isAdd: true,
          }))
          throw Error('');
        }

        if (filterById[0].stock < item1.amount) {
          setSelected(addIsErrorInObject({
            array: selected,
            id: item1.id,
            add: ['isError'],
            isAdd: true,
          }))
          // throw Error(`${item.title}의 수량이\n재고를 초과했습니다.`);
          throw Error('');
        }
      });

      const goods = getGoodsList(selected, screw);
      await createCustomerInfoInLogisticsTable({
        info: setCustomerInfos({requestInfo, shopInfo}),
        goods,
      });

      setPopup({
        isShow: true,
        message: '작성완료🥳',
        buttonType: 'closed',
      });
    } catch (e: any) {
      if (e.message === '') {
        return;
      }
      setPopup({
        isShow: true,
        message: e.message,
        buttonType: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getGoodsTypes().then((goods: any) => {
      setGoodsList(goods)
    }).finally(() => setIsLoading(false))
  }, [])


  const onClickPreview = (item: any) => {
    if (item.photoUrl) {
      setPreview(item.photoUrl[0].url);
      return;
    }
    setPreview(item);
    return;
  }

  const initializeForm = () => {
    setSelected([]);
    setGoodsFocus('');
    setDropDown('');
    setScrew('');
    setTypeDetailNum(0);
    setPreview('');

    getOptionsByFieldId(
      {
        table: 'logistics',
        fieldId: [
          process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_REQUEST_TEAM_FIELD_ID!,
          process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_RELEASE_TYPE_FIELD_ID!,
          process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_INSTALLER_FIELD_ID!,
          process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_DELIVERY_TYPE_FIELD_ID!
        ],
      }
    ).then((options: any) => {
      const REQUEST_INFO = [
        {
          title: '작성일자',
          img: Calendar,
          value: moment().format('YYYY-MM-DD'),
          isEdit: false,
        },
        {
          title: '요청부서',
          img: Team,
          value: '',
          options: options.find((v: any) => (
              v.id === process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_REQUEST_TEAM_FIELD_ID!
            )).options.choices,
          isEdit: true,
          isDropdown: true,
        },
        {
          title: '담당자',
          img: User,
          value: '',
          isEdit: true,
        },
        {
          title: '연락처',
          img: Phone,
          value: '',
          isEdit: true,
        },
        {
          title: '출고유형',
          img: Check,
          value: '',
          options: [
            {name: '추가구매'},
            {name: 'A/S'},
            {name: '긴급민원'},
            // 230516 16:00 수정(보미님 요청) 긴급민원_고객지원팀 -> 긴급민원,
            {name: '샘플'},
            {name: '본사 출고(샘플X)'},
          ],
          isEdit: true,
          isDropdown: true,
        },
        {
          title: '배송방법',
          img: Truck,
          value: '',
          options: options.find((v: any) => (
              v.id === process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_DELIVERY_TYPE_FIELD_ID!
            )).options.choices,
          isEdit: true,
          isDropdown: true,
        },
        {
          title: '설치업체',
          img: Install,
          value: '',
          options: options.find((v: any) => (
              v.id === process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_INSTALLER_FIELD_ID!
            )).options.choices,
          isEdit: true,
          isDropdown: true,
        },
      ];

      const SHOP_INFO = [
        {
          title: '상호명',
          img: Shop,
          value: '',
          isRequired: true,
        },
        {
          title: '지점명',
          img: Text,
          value: '',
          isRequired: true,
        },
        {
          title: '배송지',
          img: Truck,
          value: '',
          isRequired: true,
        },
        {
          title: '연락처',
          img: Phone,
          value: '',
          isRequired: true,
        },
        {
          title: '요청사항(내부용)',
          img: Text,
          value: '',
          isTextArea: true,
          isRequired: false,
        },
        {
          title: '물류신청서 특이사항(인쇄용)',
          img: Text,
          value: '',
          isRequired: false,
        },
      ];

      setRequestInfo(REQUEST_INFO);
      setShopInfo(SHOP_INFO);
    });
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>출고신청(A/S, 추가구매)</title>
        </Helmet>
      </HelmetProvider>
      {isLoading && <Loading/>}
      {popup.isShow
        && (
          <LogisticsNoticePopup
            content={popup.message}
            button={'확인'}
            onClick={() => {
              setPopup({isShow: false, message: '', buttonType: ''});
              initializeForm();
            }}
          />
        )
      }
      <div className={css(styles.frame)}>
        {preview !== ''
          && (
            <GoodsPreview
              url={preview}
              onClickClose={() => onClickPreview('')}
            />
          )
        }
        <div className={css(styles.top)}>
          <div>
            <div className={css(styles.topCode)}/>
            <div className={css(styles.topName)}>
              출고신청(추가)
            </div>
          </div>
          <div className={css(styles.topCompleteButton)} onClick={onClickComplete}>
            출고신청서 작성완료
          </div>
        </div>
        <div className={css(styles.basicInfoFrame)}>
          <div className={css(styles.basicInfo)}>
            <RequestInfoList
              requestInfo={requestInfo}
              dropdown={dropDown}
              setDropdown={(value: string) => setDropDown(value)}
              setRequestInfo={(value) => setRequestInfo(value)}
            />
            <ShopInfoList
              shopInfo={shopInfo}
              setShopInfo={(value) => setShopInfo(value)}
            />
          </div>
          <GoodsList
            goodsList={goodsList}
            setGoodsList={(value: any) => setGoodsList(value)}
            typeDropdownNumber={typeDetailNum}
            setTypeDropdownNumber={(value: number) => setTypeDetailNum(value)}
            selected={selected}
            setSelected={(value) => setSelected(value)}
            screw={screw}
            setScrew={(value: string) => setScrew(value)}
            setGoodsFocus={(value: string) => setGoodsFocus(value)}
            onClickPreview={(value) => onClickPreview(value)}
          />
          <GoodsStock
            goodsList={goodsList}
            focusedGoodsType={goodsFocus}
          />
        </div>
      </div>
    </>
  )
}

const styles = CreateBetaStyle({
  frame: {
    width: '83vw',
    height: '95vh',

    borderRadius: '10px',

    backgroundColor: 'white',

    boxSizing: 'border-box',

    fontFamily: 'Pretendard',

    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',

    '@media(max-width: 1800px)': {
      width: '80vw',
    },
  },
  top: {
    width: '100%',
    height: '114px',

    borderBottom: 'solid 1px #E8E8E8',

    padding: '30px',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    boxSizing: 'border-box',
  },
  topCode: {
    fontSize: '18px',
    color: '#999999',
    fontWeight: 600,

    marginBottom: '10px',
  },
  topName: {
    fontSize: '24px',
    fontWeight: 700,
    color: '#2E2E2E',
  },
  topButtons: {
    display: 'flex',
  },
  topPreSaveButton: {
    border: 'solid 2px #666973',
    color: '#666973',
  },
  topPreviewButton: {
    width: '105px',
    height: '48px',

    borderRadius: '10px',
    border: 'solid 2px black',

    fontSize: '17px',
    letterSpacing: '-1%',
    fontWeight: 600,
    color: 'black',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    marginRight: '10px',

    cursor: 'pointer',
  },
  topCompleteButton: {
    width: '175px',
    height: '48px',

    borderRadius: '10px',
    border: 'solid 2px black',

    backgroundColor: 'black',

    fontSize: '17px',
    letterSpacing: '-1%',
    fontWeight: 600,
    color: 'white',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    cursor: 'pointer',
  },
  basicInfoFrame: {
    height: 'calc(100% - 114px)',
    display: 'flex',

    '@media(max-width: 1800px)': {
      overflowX: 'scroll',
      overflowY: 'hidden',

      '::-webkit-scrollbar': {
        height: '14px',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundClip: 'padding-box',
        backgroundColor: '#A8A8A8',
        borderRadius: '15px',
        border: '4px solid transparent',
      },
      '::-webkit-scrollbar-track': {
        padding: '10px',

        background: 'transparent',
      },
    },
  },
  basicInfo: {
    width: '725px',
    height: '100%',

    overflowY: 'scroll',

    paddingLeft: '30px',

    borderRight: 'solid 1px #E8E8E8',

    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: '#A8A8A8',
      borderRadius: '15px',
      border: '3px solid transparent',
    },
    '::-webkit-scrollbar-track': {
      padding: '10px',

      background: 'transparent',
    },

    '@media(max-width: 1800px)': {
      width: '35vw',
      minWidth: '35vw',
    },

    '@media(max-width: 1600px)': {
      width: '37vw',
    },
  },
  requestInfo: {
    width: '665px',

    borderBottom: 'solid 1px #F4F4F4',

    paddingBottom: '30px',
    paddingTop: '30px',

    '@media(max-width: 1800px)': {
      width: '33vw',
    },
  },
})
