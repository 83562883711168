import React, {useEffect, useMemo, useState} from 'react';
import {css} from 'aphrodite';
import {useParams} from 'react-router-dom';
import {Helmet, HelmetProvider} from 'react-helmet-async';

import {CreateBetaStyle} from '../../shared/CreateStyle';

import {
  getCustomerInfo,
  getGoodsList
} from '../services/EstimateService';
import Loading from '../../shared/components/Loading';
import {NoticePopup} from "../../shared/components/NoticePopup";
import {InstallmentPayment} from "./InstallmentPayment";
import {LumpSumPayment} from "./LumpSumPayment";
import {ServingRobot} from "./ServingRobot";
import {SmartStore} from "./SmartStore";
import {RenewalContract} from "./RenewalContract";
import {ConsumablePurchase} from "./ConsumablePurchase";

const ESTIMATION_TYPE_LIST = [
  '할부 견적서',
  '일시납 견적서',
  '소모품 구매 견적서',
  '재계약 견적서',
  '스마트 상점 견적서',
  '서빙로봇 견적서',
];

export const Estimate = () => {
  // const today = moment();

  const {id} = useParams<{ id: string }>();

  const [customerInfo, setCustomerInfo] = useState<any>({});
  // const [deadlineDate, setDeadlineDate] = useState(14);
  // const [deadline, setDeadline] = useState('');
  const [goodsList, setGoodsList] = useState<any>([]);

  // const [isPrePayment, setIsPrePayment] = useState(true);
  // const [isSubGoods, setIsSubGoods] = useState(true);

  // const [monthly, setMonthly] = useState<any[]>(MONTHLY_INITIAL);
  // const [subGoods, setSubGoods] = useState<any[]>(SUB_GOODS_INITIAL);

  const [loading, setLoading] = useState(false);

  // const [prePayment, setPrePayment] = useState(0);
  // const [monthlyPayment, setMonthlyPayment] = useState(0);
  // const [totalPrePayment, setTotalPrePayment] = useState(0);

  // const [isCopy, setIsCopy] = useState(false);
  const [isPopupOn, setIsPopupOn] = useState(false);
  const [message, setMessage] = useState('');

  const [selectedEstimationIndex, setSelectedEstimationIndex] = useState(0);

  // const [info, setInfo] = useState(CONTRACT_INFO);
  // const [installInfo, setInstallInfo] = useState(INSTALL_INFO);
  // const [electronDeadline, setElectronDeadline] = useState<any>(ELECTRON_DEADLINES[0].content);
  // const [electronDeadlineNum, setElectronDeadlineNum] = useState<any>(0);
  // const ACCOUNT = '140-012-934957';

  // const [isMonthly, setIsMonthly] = useState(true);
  // const [isMonthlyComment, setIsMonthlyComment] = useState(false);
  // const [isMonthlyDetail, setIsMonthlyDetail] = useState(true);
  // const [isSubGoodsDetail, setIsSubGoodsDetail] = useState(true);
  // const [isSale, setIsSale] = useState(false);
  // const [isSaleDetail, setIsSaleDetail] = useState(true);
  // const [isPromotion, setIsPromotion] = useState(true);
  // const [isPromotionDetail, setIsPromotionDetail] = useState(true);
  // const [isInfo, setIsInfo] = useState(true);
  // const [isInstallInfo, setIsInstallInfo] = useState(true);
  // const [isElectronDeadline, setIsElectronDeadline] = useState(true);

  // const [subType, setSubType] = useState(-1);
  // const [subName, setSubName] = useState(-1);
  // const [subColor, setSubColor] = useState(-1);

  // const [saleList, setSaleList] = useState(SALE_INITIAL);
  // const [promotionList, setPromotionList] = useState(PROMOTION_INITIAL);

  // const [monthlyDropDown, setMonthlyDropDown] = useState(-1);
  // const [periodDropDown, setPeriodDropDown] = useState(-1);

/*
  const saleTotal = useMemo(() => {
    let total = 0;
    saleList.map((item: any) => {
      total = total + item.quantity * item.price;
    })
    return total
  }, [saleList]);
*/

/*
  const promotionTotal = useMemo(() => {
    let total = 0;
    promotionList.map((item: any) => {
      total = total + item.quantity * item.price;
    })
    return total;
  }, [promotionList]);
*/

/*
  const subGoodsTotal = useMemo(() => {
    let total = 0;
    subGoods.map((item: any) => {
      total = total + item.quantity * item.price;
    })
    return total;
  }, [subGoods]);
*/

/*
  const copyComments = () => [
    {
      text: INITIAL_COMMENT,
      height: 40,
    },
    {
      image: Card,
      height: 150,
    },
    {
      text: getCustomer(customer.phone, customer.key),
      height: 40,
    },
    {
      text: THIRD_COMMENT,
      height: 40,
    },
    {
      text: getGoodsInfo({
        period: monthly[0] ? monthly[0].month : '',
        tablet: monthly[0] ? monthly[0].quantity : '',
        masterTablet: monthly[1] ? monthly[1].quantity : '',
        price: monthly[0] ? getToLocalString(monthly[0].price) : '',
        customer,
      }),
      height: 30,
    },
    {
      text: getAccountInfo(getToLocalString(Math.floor((isPrePayment ? prePayment : 0) + (isSubGoods ? subGoodsTotal : 0)))),
      height: 160,
    },
    {
      image: Select,
      height: 150,
    },
    {
      text: WORKING_TIME_INFO,
      height: 300,
    },
    {
      text: YOUTUBE_INFO,
      height: 50,
    },
    {
      text: LAST_INFO,
      height: 50,
    },
  ]
*/

/*
  const sendImageByAlimtalk = async () => {
    setIsLoading(true);
    setMessage("알림톡 전송완료!");
    setImage(document.querySelector('#estimation')!, id!)
        .then(async (link: any) => {
          const url = await saveEstimateByRecord({recordId: id, fileLink: link.href})
          const estimateUrl = url.replace('https://', '');

          sendAlimtalk({
            // templateCode: 'KA01TP230418045754053i1d8cggIAbS',
            templateCode: 'KA01TP230614013016863aBwasmUCtUS',
            // phone: '010-3192-9622',
            phone: customer.phone,
            value: ['신한은행' + ACCOUNT, totalPrePayment.toString(), customer.shopName, customer.manager, estimateUrl, estimateUrl],
          })
        })
        .finally(() => {
          setIsLoading(false);
          setIsPopupOn(true);
        });
  };
*/

/*
  const sendVisitCompleteAlimtalk = async () => {
    setIsLoading(true);
    setMessage("방문완료🥳")
    const customerInfo = await getCustomerInfo(id!);

    let visitManagerStr = '';
    for (let i = 0; i < customerInfo.visitManager.length; i++) {
      if(i === 0) {
        visitManagerStr += customerInfo.visitManager[i];
      }else{
        visitManagerStr += ', ' + customerInfo.visitManager[i];
      }
    }

    let visitManagerPhoneNumberStr = customerInfo.visitManagerPhoneNumber == null ? '' : customerInfo.visitManagerPhoneNumber;
    if (visitManagerPhoneNumberStr !== '') {
      visitManagerPhoneNumberStr = visitManagerPhoneNumberStr.split('  ')[0];
    }

    const base = new Airtable({apiKey: process.env.REACT_APP_AIRTABLE_API_KEY}).base(process.env.REACT_APP_AIRTABLE_BASE_ID ?? '');
    const table = base(process.env.REACT_APP_AIRTABLE_BASIC_TABLE_ID!);

    try {
      await sendAlimtalk({
        templateCode: "KA01TP2306120358134215BkG0W3kuhh",
        // phone: "010-3192-9622",
        phone: customerInfo.phone,
        value: [
            customerInfo.shopName,
            visitManagerStr,
            visitManagerPhoneNumberStr,
            customerInfo.visitDate,
        ]
      });

      await table.update([{
        id: id!,
        fields: {
          [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_STATUS_FIELD_ID!]: "3-2. 방문상담완료"
        }
      }]);
    }catch (error){
      console.log(error);
    }finally {
      setIsLoading(false);
      setIsPopupOn(true);
    }
  }
*/

/*
  useEffect(() => {
    getGoodsList().then((res: any) => {
      setGoodsList(res);
    })
  }, []);
*/

  useEffect(() => {
    if (id == null) {
      return;
    }

    setLoading(true);
    getCustomerInfo(id).then(res => {
      setCustomerInfo(res);
    }).finally(() => {
      setLoading(false);
    });

    setLoading(true);
    getGoodsList().then(res => {
      setGoodsList(res);
    }).finally(() => {
      setLoading(false);
    });
  }, [id]);

/*
  useEffect(() => {
    let total = 0;
    let total02 = 0;
    monthly.map((item: any) => {
      total = total + (Number(item.month) * item.quantity * item.price);
      total02 = total02 + (item.quantity * item.price);
    });
    setMonthlyPayment(isPrePayment ? total02 * 0.9 : total02);
    setPrePayment(Math.floor(total * 0.11))
  }, [monthly, isPrePayment]);
*/

/*
  useEffect(() => {
    setTotalPrePayment(Math.floor(
        (isPrePayment ? prePayment : 0) + (isSubGoods ? subGoodsTotal : 0)
    ))
  }, [isPrePayment, prePayment, isSubGoods, subGoodsTotal])
*/

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>견적서 계산기</title>
        </Helmet>
      </HelmetProvider>
      {loading && <Loading/>}
      {/*{<div className={css(styles.notification, isCopy && styles.notificationDisplay)}>복사완료</div>}*/}
      <div className={css(styles.background)}>
        {isPopupOn &&
            <NoticePopup
              content={message}
              onClickConfirm={() => setIsPopupOn(false)}
            />
        }
        <div className={css(styles.frame)}>
          <div className={css(styles.title)}>견적서 계산기</div>
          <div className={css(styles.navFrame)}>
            {ESTIMATION_TYPE_LIST.map((item, index) =>
              <div
                key={'estimation_type_list' + index}
                className={css(
                  styles.navList,
                  index === selectedEstimationIndex && styles.selectedEstimationType
                )}
                onClick={() => setSelectedEstimationIndex(index)}
              >
                {item}
              </div>
            )}
          </div>

          <div className={css(styles.content)}>
            {selectedEstimationIndex === 0 &&
              <InstallmentPayment
                customerInfoProps={customerInfo}
                goodsListProps={goodsList}
              />
            }
            {selectedEstimationIndex === 1 &&
              <LumpSumPayment
                customerInfoProps={customerInfo}
                goodsListProps={goodsList}
              />
            }
            {selectedEstimationIndex === 2 &&
              <ConsumablePurchase
                customerInfoProps={customerInfo}
                goodsListProps={goodsList}
              />
            }
            {selectedEstimationIndex === 3 &&
              <RenewalContract
                customerInfoProps={customerInfo}
                goodsListProps={goodsList}
              />
            }
            {selectedEstimationIndex === 4 &&
              <SmartStore
                customerInfoProps={customerInfo}
                goodsListProps={goodsList}
              />
            }
            {selectedEstimationIndex === 5 &&
              <ServingRobot
                customerInfoProps={customerInfo}
                goodsListProps={goodsList}
              />
            }

          </div>
        </div>
      </div>
    </>
  )
}

const styles = CreateBetaStyle({
  // notification: {
  //   width: '200px',
  //   height: '60px',
  //
  //   backgroundColor: 'rgba(64, 60, 255, 0.6)',
  //
  //   position: 'fixed',
  //   right: '-220px',
  //   top: '20px',
  //   opacity: 0,
  //   zIndex: 105,
  //
  //   fontSize: '15px',
  //   color: 'white',
  //   fontWeight: 700,
  //
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //
  //   borderRadius: '5px',
  //
  //   transition: 'opacity 0.3s, right 0.3s'
  // },
  // notificationDisplay: {
  //   right: '20px',
  //   opacity: 1,
  //   transition: 'opacity 0.3s, right 0.3s'
  // },
  background: {
    width: '100%',
    minHeight: '100vh',

    backgroundColor: '#F5F5F5',

    paddingTop: '28px',
    paddingLeft: '30px',
    paddingBottom: '100px',

    fontFamily: 'Pretendard',

    boxSizing: 'border-box',
  },
  frame: {
    // transformOrigin: '0 0',
    // scale: '0.8',
  },
  title: {
    fontSize: '32px',
    fontWeight: 700,
    color: '#000000',

    marginBottom: '15px',
  },
  navFrame: {
    display: 'flex',

    marginBottom: '29px',
  },
  navList: {
    height: '44px',

    backgroundColor: 'white',

    borderRadius: '5px',

    display: 'flex',
    alignItems: 'center',

    color: '#000000',
    fontSize: '15px',
    fontWeight: 700,

    padding: '0px 20px',
    marginRight: '5px',

    cursor: 'pointer',

    filter: 'drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.173))',
  },
  selectedEstimationType: {
    backgroundColor: '#403cff',
    color: '#ffffff',
  },
  content: {
    display: 'flex',
  },
});

// const popupStyle = CreateBetaStyle({
//   frame: {
//     width: '458px',
//     height: '65px',
//
//     backgroundColor: '#E20F23',
//
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//
//     color: 'white',
//     fontSize: '18px',
//     fontWeight: 600,
//     fontFamily: 'Pretendard',
//
//     borderRadius: '15px',
//
//     cursor: 'pointer',
//   },
//   successFrame: {
//     backgroundColor: '#666973',
//     color: 'white',
//   },
//   deactive: {
//     backgroundColor: '#D9D9D9',
//     color: 'white',
//   },
//   complete: {
//     backgroundColor: '#666973',
//   },
//   error: {
//     backgroundColor: 'black',
//   },
//   background: {
//     width: '100%',
//     height: '100%',
//
//     position: 'absolute',
//     left: 0,
//     top: 0,
//     zIndex: 100,
//
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//
//     backgroundColor: 'rgba(0, 0, 0, 0.4)',
//   }
// })
