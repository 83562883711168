import React from 'react';
import {css} from 'aphrodite';

import {Navigatior} from '../components/Navigator';
import {AlimtalkView} from '../components/AlimtalkView';
import {MasterAlimtalkView} from '../components/MasterAlimtalkView';
import {CreateBetaStyle} from '../../shared/CreateStyle';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import { FavoriteAlimtalkView } from '../components/FavoriteAlimtalkView';
import { useParams } from 'react-router-dom';

export const AlimtalkMain = ({type}: {type: string}) => {
  // const { type } = useParams();
  const viewType = type ?? 'alimtalk';

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>알림톡 매니저</title>
        </Helmet>
      </HelmetProvider>
      <div className={css(styles.main)}>
        <Navigatior type={viewType}/>
        {setView(viewType)}
      </div>
    </>
  )
}

function setView(type: string) {
  switch (type) {
    case 'master':
      return <MasterAlimtalkView/>;
    case 'favorite':
      return <FavoriteAlimtalkView/>;
    default:
      return <AlimtalkView/>;
  }
}


const styles = CreateBetaStyle({
  main: {
    width: '100%',
    height: '100vh',

    paddingLeft: '303px',

    backgroundColor: '#F8F9FD',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    boxSizing: 'border-box',
  }
})
