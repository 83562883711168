import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import { LogisticsMain } from './internal/logistics/LogisticsMain';
import { LogisticsPreview } from './internal/logistics/LogisticsPreview';
import { AlimtalkMain } from './internal/alimtalk/pages/AlimtalkMain';
import {Estimate} from "./internal/estimate/pages/Estimate";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="logistics/" element={<LogisticsMain/>}/>
        <Route path="logistics/:id" element={<LogisticsMain/>}/>
        <Route path="logistics/create" element={<LogisticsMain/>}/>
        <Route path="logistics/preview/" element={<LogisticsPreview/>}/>
        <Route path="logistics/preview/:id" element={<LogisticsPreview/>}/>

        <Route path="new-alimtalk/:id" element={<AlimtalkMain type={'alimtalk'}/>}/>
        <Route path="new-alimtalk/favorite/:id" element={<AlimtalkMain type={'favorite'}/>}/>

        <Route path="estimate" element={<Estimate/>}/>
        <Route path="estimate/:id" element={<Estimate/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
