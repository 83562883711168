import {css} from 'aphrodite';
import * as _ from 'lodash';
import React from 'react';

import {Image} from '../../shared/components/Image';
import {CreateBetaStyle} from '../../shared/CreateStyle';

export const ShopInfoList = (
  {
    shopInfo,
    setShopInfo
  }:{
    shopInfo: any,
    setShopInfo: (value: any) => void
  }) => {
  return (
    <div className={css(styles.shopInfo)}>
      <div className={css(styles.title)}>매장 정보</div>
      {
        shopInfo.map((item: any, index: number) => (
          <div key={'request_' + index} className={css(styles.requestList)}>
            <Image
              src={item.img}
              alt={'request-info_' + index}
              className={css(styles.requestListIcon)}
            />
            <div className={css(styles.requestListTitle)}>{item.title}</div>
            {item.isTextArea
              ? (
                <textarea
                  value={item.value}
                  placeholder={'내용을 입력해주세요'}
                  className={css(
                    styles.requestListContent,
                    styles.requestListContentTextarea
                  )}
                  onChange={(e) => {
                    const temp1 = _.cloneDeep(shopInfo);
                    const temp2 = temp1.find((v: any) => v.title === item.title);
                    temp2.value = e.target.value;
                    setShopInfo(temp1);
                  }}
                />
              )
              : (
                <input
                  value={item.value}
                  placeholder={'내용을 입력해주세요.'}
                  className={css(styles.requestListContent)}
                  onChange={(e) => {
                    const temp = _.cloneDeep(shopInfo);
                    const temp02 = temp.find((v: any) => v.title === item.title);
                    temp02.value = e.target.value;
                    setShopInfo(temp);
                  }}
                />
              )}
          </div>
        ))
      }
    </div>
  )
}

const styles = CreateBetaStyle({
  shopInfo: {
    paddingTop: '30px',
  },
  title: {
    fontSize: '17px',
    color: '#666973',
    fontWeight: 600,

    marginBottom: '20px',
  },
  requestList: {
    minHeight: '36px',

    display: 'flex',
    // alignItems: 'center',

    marginBottom: '5px',
  },
  requestListIcon: {
    width: '20px',
    height: '20px',

    marginRight: '5px',
    marginTop: '8px',
  },
  requestListTitle: {
    width: '205px',

    fontSize: '15px',
    color: '#666973',
    fontWeight: 500,

    marginTop: '10px',

    '@media(max-width: 1800px)': {
      width: '10vw',
    },
  },
  requestListContent: {
    width: '435px',
    minHeight: '36px',

    fontSize: '15px',
    fontWeight: 500,
    color: '#333333',
    fontFamily: 'Pretendard',

    whiteSpace: 'pre',

    border: 'none',
    borderRadius: '5px',
    padding: '0px 5px 0px 5px',
    boxSizing: 'border-box',

    ':focus': {
      outline: 'solid 1px #999999'
    },
    '::placeholder': {
      color: '#999999',
    },
    ':hover': {
      backgroundColor: '#F4F4F4',
    },
    ':disabled': {
      backgroundColor: 'transparent',
    },

    '@media(max-width: 1800px)': {
      width: '20vw',
    },
  },
  requestListContentTextarea: {
    height: '100px',

    resize: 'none',

    paddingTop: '5px',

    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar:horizontal': {
      height: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: '#A8A8A8',
      borderRadius: '15px',
      border: '3px solid transparent',
    },
    '::-webkit-scrollbar-track': {
      padding: '10px',

      background: 'transparent',
    },

    '@media(max-width: 1800px)': {
      height: '100px',
    },
  },
})
