import {useParams} from 'react-router-dom';
import {css} from 'aphrodite';

import {Image} from '../../shared/components/Image';
import {CreateBetaStyle} from '../../shared/CreateStyle';

import Logo from '../../../assets/images/svgs/torderLogo.svg';
import {ReactComponent as Kakao} from '../../../assets/images/svgs/kakao.svg';
import Out from '../../../assets/images/svgs/out.svg';

export const Navigatior = ({type}: {type: string
}) => {
  const closeBrowser = () => {
    window.open('', '_self', '');
    window.close();
  }
  const {id} = useParams<{ id: string }>();

  const goToMaster = () => {
    if (type === 'master') {
      return;
    }
    window.open('/new-alimtalk/master/' + id, '_self')
  }

  const goToFavorite = () => {
    if (type === 'favorite') {
      return;
    }
    window.open('/new-alimtalk/favorite/' + id, '_self')
  }

  const goToAlimtalk= () => {
    if (type === 'alimtalk') {
      return;
    }
    window.open('/new-alimtalk/' + id, '_self')
  }

  return (
    <div className={css(styles.frame)}>
      <Image src={Logo} alt={'logo'} className={css(styles.logo)}/>
      <div className={css(styles.button, type === 'alimtalk' && styles.buttonSelected)} onClick={goToAlimtalk}>
        <Kakao className={css(styles.buttonImg)}/>
        <p>알림톡</p>
      </div>
      {/*<div className={css(styles.button, type === 'master' && styles.buttonSelected)} onClick={goToMaster}>*/}
      {/*  <Kakao className={css(styles.buttonImg)}/>*/}
      {/*  <p>마스터 알림톡</p>*/}
      {/*</div>*/}
      <div
        className={css(styles.button, type === 'favorite' && styles.buttonSelected)}
        onClick={goToFavorite}
      >
        <Kakao className={css(styles.buttonImg)}/>
        <p>즐겨찾기 알림톡</p>
      </div>
      {/*<div className={css(styles.button)}>*/}
      {/*  <Image src={Message} alt={'message'} className={css(styles.buttonImg)}/>*/}
      {/*  <p>SMS</p>*/}
      {/*</div>*/}
      <div className={css(styles.out)} onClick={() => closeBrowser()}>
        <Image src={Out} alt={'out'} className={css(styles.outImg)}/>
        종료하기
      </div>
    </div>
  )
}

const styles = CreateBetaStyle({
  frame: {
    width: '272px',
    height: '100%',

    backgroundColor: 'white',

    padding: '34px',

    fontFamily: 'Pretendard',

    position: 'absolute',
    left: 0,
    top: 0,

    boxSizing: 'border-box',
  },
  logo: {
    width: '134px',
    height: '32px',

    objectFit: 'contain',

    marginBottom: '62px',
  },
  buttonBox: {},
  button: {
    width: '196px',
    height: '54px',

    display: 'flex',
    alignItems: 'center',

    paddingLeft: '29px',
    marginBottom: '20px',

    borderRadius: '10px',

    fontSize: '17px',
    fontWeight: 700,
    color: '#666973',

    cursor: 'pointer',

    boxSizing: 'border-box',

    fill: '#666973',
  },
  buttonSelected: {
    backgroundColor: '#E20F23',
    color: 'white',

    fill: 'white',
  },
  buttonImg: {
    width: '23px',
    height: '23px',

    fill: 'inherit',

    marginRight: '17px',
  },
  out: {
    display: 'flex',
    alignItems: 'center',

    color: '#666973',
    fontSize: '15px',
    fontWeight: 500,

    position: 'absolute',
    bottom: '46px',

    marginLeft: '30px',

    cursor: 'pointer',
  },
  outImg: {
    width: '20px',
    height: 'auto',

    objectFit: 'contain',

    marginRight: '13px',
  }
})
