import moment from 'moment';

export const getTime = (): string => {
  const hour = moment().format('HH');
  const minute = moment().format('mm');
  if (Number(hour) > 11) {
    return `오후 ${hour === '12' ? hour : Number(hour) - 12}:${minute}`;
  }
  return `오전 ${hour}:${minute}`;
}

export function getToday() {
  return moment().format('YYYY년 MM월 DD일');
}
