import * as _ from 'lodash';

import Calendar from '../../../assets/images/svgs/calendar.svg';
import Team from '../../../assets/images/svgs/team.svg';
import User from '../../../assets/images/svgs/user.svg';
import Phone from '../../../assets/images/svgs/phone.svg';
import Check from '../../../assets/images/svgs/check.svg';
import Shop from '../../../assets/images/svgs/shop.svg';
import Text from '../../../assets/images/svgs/text.svg';
import Truck from '../../../assets/images/svgs/truck.svg';
import Install from '../../../assets/images/svgs/install.svg';

export function setCustomerInfos(
  {
    requestInfo,
    shopInfo
  }: {
    requestInfo: any,
    shopInfo: any
  }) {
  return {
    date: requestInfo[0].value,
    requestTeam: requestInfo[1].value,
    manager: requestInfo[2].value,
    managerPhone: requestInfo[3].value,
    releaseType: requestInfo[4].value,
    deliveryType: requestInfo[5].value,
    installer: requestInfo[6].value,
    shopName: shopInfo[0].value,
    locationName: shopInfo[1].value,
    shippingAddress: shopInfo[2].value,
    shopPhone: shopInfo[3].value,
    requestMessage: shopInfo[4].value,
    significantMessage: shopInfo[5].value,
  }
}

export function getRequestInfo(value: any) {
  return [
    {
      title: '작성일자',
      img: Calendar,
      value: value.date,
      isEdit: false,
    },
    {
      title: '요청부서',
      img: Team,
      value: value.requestTeam,
      isEdit: true,
      isDropdown: true,
      options: value.requestTeams,
    },
    {
      title: '담당자',
      img: User,
      value: value.manager,
      isEdit: false,
    },
    {
      title: '연락처',
      img: Phone,
      value: value.managerPhone,
      isEdit: true,
    },
    {
      title: '출고유형',
      img: Check,
      value: value.releaseType,
      isEdit: true,
      isDropdown: true,
      options: value.releaseTypes,
    },
    {
      title: '배송방법',
      img: Truck,
      value: value.deliveryType,
      isEdit: true,
      isDropdown: true,
      options: value.deliveryTypes,
    },
    {
      title: '설치업체',
      img: Install,
      value: value.installer,
      isEdit: true,
      isDropdown: true,
      options: value.installers,
    },
  ]
}

export function getShopInfo(value: any) {
  return [
    {
      title: '상호명',
      img: Shop,
      value: value.shopName,
    },
    {
      title: '지점명',
      img: Text,
      value: value.locationName,
    },
    {
      title: '배송지',
      img: Truck,
      value: value.shippingAddress,
    },
    {
      title: '연락처',
      img: Phone,
      value: value.shopPhone,
    },
    {
      title: '요청사항(내부용)',
      img: Text,
      value: value.requestMessage,
      isTextArea: true,
    },
    {
      title: '물류신청서 특이사항(인쇄용)',
      img: Text,
      value: value.significantMessage,
    },
  ];
}

export function getGoodsList(selected: any, screw: string) {
  const holder = selected.filter((v: any) => v.type === '1.거치대');
  const masterHolder = selected.filter((v: any) => v.type === '2.마스터용 거치대');
  const case_ = selected.filter((v: any) => v.type === '3.케이스');
  const tablet = selected.filter((v: any) => v.type === '4.태블릿');
  const charge = selected.filter((v: any) => v.type === '5.충전기&케이블' && v.title === '충전기');
  const cable = selected.filter((v: any) => v.type === '5.충전기&케이블' && v.title !== '충전기');
  const share = selected.filter((v: any) => v.type === '6.공유기');
  const battery = selected.filter((v: any) => v.type === '7.보조배터리');
  const batteryCase = selected.filter((v: any) => v.type === '8.보조배터리 케이스');
  const card = selected.filter((v: any) => v.type === '9.카드리더기');
  const cardCase = selected.filter((v: any) => v.type === '10.카드리더기 케이스');

  const res = {
    holder,
    masterHolder,
    case_,
    tablet,
    charge,
    cable,
    share,
    battery,
    batteryCase,
    card,
    cardCase,
    screw
  };
  return res;
}

// 물류재고 테이블에서 가져온 레코드 객체 리스트(array)에서 선택한 항목 추가
// 화면에서 체크하는 경우 호출
export function addIsSelectedInObject(
  {
    array,
    target,
    isSelected
  }: {
    array: any,
    target: {
      type: string,
      item: string
    },
    isSelected: boolean
  }) {

  if (target.type === '12.나사') {
    return array;
  }

  const newArray = _.cloneDeep(array);
  const targetByType = newArray.find((obj: any) => obj.type === target.type);
  const targetByItem = targetByType.items.find((obj: any) => obj.item === target.item);

  if (targetByItem != null) {
    targetByItem.isSelected = isSelected;
  }
  return newArray;
}

//
export function addIsErrorInObject(
  {
    array,
    id,
    add,
    isAdd
  }: {
    array: any,
    id: string,
    add: string[],
    isAdd: boolean
  }) {

  const newArray = _.cloneDeep(array);
  const targetById = newArray.find((obj: any) => obj.id === id);
  targetById[add[0]] = isAdd;

  if (add[1] !== undefined) {
    targetById[add[1]] = isAdd;
  }
  return newArray;
}

// 에어테이블 2물류의 레코드 한개짜리 리스트를 받아 새로운 키값으로 매칭
export function customerInfoForLogistics(logistics: any) {
  return {
    key: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_KEY_FIELD_ID!],
    date: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_WRITE_DATE_FIELD_ID!],
    outDate: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_RELEASE_DATE_FIELD_ID!],
    requestTeam: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_REQUEST_TEAM_FIELD_ID!],
    manager: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_MANAGER_FIELD_ID!],
    managerPhone: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_MANAGER_PHONE_FIELD_ID!],
    releaseType: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_RELEASE_TYPE_FIELD_ID!],
    shopName: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHOP_NAME_FIELD_ID!],
    locationName: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_LOCATION_NAME_FIELD_ID!],
    shippingAddress: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHIPPING_ADDRESS_FIELD_ID!],
    shopPhone: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHOP_PHONE_FIELD_ID!],
    requestMessage: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_REQUEST_MESSAGE_FIELD_ID!],
    significantMessage: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SIGNIFICANT_MESSAGE_FIELD_ID!],
    installer: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_INSTALLER_FIELD_ID!],
    deliveryType: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_DELIVERY_TYPE_FIELD_ID!],
    goods: [
      {
        type: '거치대',
        value: [
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_HOLDER_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_HOLDER_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_HOLDER_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_HOLDER_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_THIRD_HOLDER_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_THIRD_HOLDER_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_MASTER_HOLDER_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_MASTER_HOLDER_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_MASTER_HOLDER_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_MASTER_HOLDER_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_CASE_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_CASE_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_CASE_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_CASE_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_THIRD_CASE_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_THIRD_CASE_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '태블릿',
        value: [
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_TABLET_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_TABLET_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_TABLET_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_TABLET_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CHARGE_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CHARGE_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CABLE_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CABLE_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '공유기',
        value: [
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE1_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE1_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE2_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE2_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE3_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE3_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE4_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE4_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '보조배터리',
        value: [
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_BATTERY_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_BATTERY_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_BATTERY_CASE_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_BATTERY_CASE_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_BATTERY_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_BATTERY_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_BATTERY_CASE_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_BATTERY_CASE_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '카드리더기',
        value: [
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER1_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER1_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER2_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER2_AMOUNT_FIELD_ID!],
          },
          // {
          //   id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER3_FIELD_ID!],
          //   amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER3_AMOUNT_FIELD_ID!],
          // },
        ]
      },
      {
        type: '카드리더기\n케이스',
        value: [
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE1_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE1_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE2_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE2_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE3_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE3_AMOUNT_FIELD_ID!],
          },
          {
            id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE4_FIELD_ID!],
            amount: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE4_AMOUNT_FIELD_ID!],
          },
        ]
      },
      // {
      //   type: '나사',
      //   value: [
      //     {
      //       id: logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SCREW_FIELD_ID!],
      //     },
      //   ]
      // }
    ],
  }
}

// 에어테이블 1접수의 레코드 한개짜리 리스트를 받아 새로운 키값으로 매칭
export function customerInfoForBasic(basic: any) {
  return {
    key: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_KEY_FIELD_ID!],
    date: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_WRITE_DATE_FIELD_ID!],
    outDate: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_RELEASE_DATE_FIELD_ID!],
    requestTeam: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_REQUEST_TEAM_FIELD_ID!],
    // manager: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_MANAGER_FIELD_ID!],
    manager: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_BUSINESS_MANAGER_FIELD_ID!]?.name,
    managerPhone: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_MANAGER_PHONE_FIELD_ID!],
    releaseType: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_RELEASE_TYPE_FIELD_ID!],
    shopName: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHOP_NAME_FIELD_ID!],
    locationName: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_LOCATION_NAME_FIELD_ID!],
    shippingAddress: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHIPPING_ADDRESS_FIELD_ID!],
    shopPhone: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHOP_PHONE_FIELD_ID!],
    requestMessage: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_REQUEST_MESSAGE_FIELD_ID!],
    significantMessage: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SIGNIFICANT_MESSAGE_FIELD_ID!],
    installer: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_INSTALLER_FIELD_ID!],
    deliveryType: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_DELIVERY_TYPE_FIELD_ID!],
    goods: [
      {
        type: '거치대',
        value: [
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_HOLDER_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_HOLDER_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_HOLDER_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_HOLDER_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_THIRD_HOLDER_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_THIRD_HOLDER_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_MASTER_HOLDER_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_MASTER_HOLDER_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_MASTER_HOLDER_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_MASTER_HOLDER_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_CASE_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_CASE_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_CASE_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_CASE_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_THIRD_CASE_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_THIRD_CASE_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '태블릿',
        value: [
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_TABLET_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_TABLET_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_TABLET_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_TABLET_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CHARGE_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CHARGE_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CABLE_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CABLE_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '공유기',
        value: [
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE1_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE1_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE2_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE2_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE3_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE3_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE4_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE4_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '보조배터리',
        value: [
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_BATTERY_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_BATTERY_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_BATTERY_CASE_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_BATTERY_CASE_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_BATTERY_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_BATTERY_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_BATTERY_CASE_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_BATTERY_CASE_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '카드리더기',
        value: [
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER1_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER1_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER2_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER2_AMOUNT_FIELD_ID!],
          },
          // {
          //   id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER3_FIELD_ID!],
          //   amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER3_AMOUNT_FIELD_ID!],
          // },
        ]
      },
      {
        type: '카드리더기\n케이스',
        value: [
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE1_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE1_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE2_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE2_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE3_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE3_AMOUNT_FIELD_ID!],
          },
          {
            id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE4_FIELD_ID!],
            amount: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE4_AMOUNT_FIELD_ID!],
          },
        ]
      },
      // {
      //   type: '나사',
      //   value: [
      //     {
      //       id: basic[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SCREW_FIELD_ID!],
      //     },
      //   ]
      // }
    ],
  }
}

export function sortItemsByBlack(values: any[]) {
  const blackItems = values.filter(item => item.item.includes('블랙'));
  const nonBlackItems = values.filter(item => !item.item.includes('블랙'));
  return blackItems.concat(nonBlackItems);
}
