import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {css} from 'aphrodite';
import moment from 'moment';
import * as _ from 'lodash';
import {Helmet, HelmetProvider} from 'react-helmet-async';

import {Image} from '../shared/components/Image';
import {CreateBetaStyle} from '../shared/CreateStyle';
import Loading from '../shared/components/Loading';
import {LogisticsNoticePopup} from './components/LogisticsNoticePopup';

import {
  getCustomerInfoForPreview,
  getGoodsNameByRecordId
} from './services/LogisticsService';

import Logo from '../../assets/images/pngs/logo.png';
import Truck from '../../assets/images/svgs/truck.svg';
import Phone from '../../assets/images/svgs/phone.svg';
import User from '../../assets/images/svgs/user.svg';

export const LogisticsPreview = () => {
  // const [recordId, setRecordId] = useState('');
  const [shopInfo, setShopInfo] = useState<any>([]);
  const [customerInfo, setCustomerInfo] = useState<any>([]);
  const [goodsInfo, setGoodsInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [popup, setPopup] = useState({
    isShow: false,
    message: '',
    buttonType: 'loading'
  });

  const {id} = useParams<{ id: string }>();

  useEffect(() => {
    if (id === '' || id == null) {
      setPopup({
        isShow: true,
        message: '레코드 아이디가 없습니다.',
        buttonType: 'closed',
      })
      return;
    }
    // setRecordId(id);
  }, [id]);

  useEffect(() => {
    if (id === undefined || id === null || id === '') {
      return;
    }

    setIsLoading(true);

    getCustomerInfoForPreview(id).then((res) => {
      if (res == null) {
        setPopup({
          isShow: true,
          message: '레코드 아이디에 해당하는 \n고객이 없습니다.',
          buttonType: 'closed',
        })
        return;
      }

      setCustomerInfo(res);

      const shopInfos = [
        {
          title: '배송지',
          img: Truck,
          value: res.shippingAddress,
        },
        {
          title: '지점 연락처',
          img: Phone,
          value: res.shopPhone,
        },
        {
          title: '담당자',
          img: User,
          value: res.manager,
        },
        {
          title: '담당자 연락처',
          img: Phone,
          value: res.managerPhone,
        },
      ];

      let goodsNew: any = _.cloneDeep(res.goods);
      // console.log(res.goods);
      // console.log(goodsNew);

      res.goods.map((item1: any) => {
        return (
          item1.value.map((item2: any) => {
            // if (item2.id == null) {
            //   return;
            // }
            const recordId = item2.id == null ? '' : item2.id[0];

            if (item1.type === '나사') {
              goodsNew = changeValue({
                array: goodsNew,
                target: {
                  id: item2.id,
                  type: item1.type,
                  item: item2.id,
                },
              });
              setGoodsInfo(goodsNew);
              return;
            }

            // if (item2.id === undefined) {
            //   goodsNew = changeValue({
            //     array: goodsNew,
            //     target: {
            //       id: item2.id,
            //       type: item.type,
            //       item: '-',
            //     },
            //   })
            //   setGoodsInfo(goodsNew);
            //   return;
            // }

            getGoodsNameByRecordId(recordId)
              .then((title: string) => {
                goodsNew = changeValue({
                  array: goodsNew,
                  target: {
                    id: item2.id,
                    type: item1.type,
                    item: title,
                  },
                });
                setGoodsInfo(goodsNew);
              });
          })
        );
      });
      setShopInfo(shopInfos);
    }).finally(() => {
      setIsLoading(false);
    })
  }, [id]);

  const onClickPrint = () => {
    let init_body = document.body.innerHTML;
    window.onbeforeprint = function () {
      init_body = document.body.innerHTML;
      document.body.innerHTML = document
        .getElementById('printArea')?.innerHTML ?? '';
    };
    window.onafterprint = function () {
      document.body.innerHTML = init_body;
    };
    window.print();
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>출고신청서 미리보기</title>
        </Helmet>
      </HelmetProvider>
      {isLoading && <Loading/>}
      {popup.isShow
        && (
          <LogisticsNoticePopup
            content={popup.message}
            button={'확인'}
            onClick={() => {
              if (popup.buttonType === 'closed') {
                window.close();
                return;
              }
              setPopup({
                isShow: false,
                message: '',
                buttonType: ''
              });
            }}
          />
        )
      }
      <div className={css(styles.background)}>
        <div className={css(styles.header)}>
          <div className={css(styles.headerTitle)}>
            출고신청서 미리보기
          </div>
          <div
            className={css(styles.headerButton)}
            onClick={onClickPrint}
          >
            인쇄하기
          </div>
        </div>
        <div className={css(styles.previewFrame)} id={'printArea'}>
          <div className={css(styles.previewHeader)}>
            <Image
              src={Logo}
              alt={'logo'}
              className={css(styles.previewHeaderLogo)}
            />
            <div className={css(styles.previewHeaderTitle)}>
              출고신청서
            </div>
          </div>
          <div className={css(styles.customerInfo)}>
            <div className={css(styles.customerInfoShop)}>
              <div className={css(styles.customerInfoShopName)}>
                {customerInfo.shopName}({customerInfo.locationName})
              </div>
              <div>
                {shopInfo.map((item: any, index: number) => (
                  <div
                    className={css(styles.customerInfoShopInfoList)}
                    key={'shop-info' + index}
                  >
                    <Image
                      src={item.img}
                      alt={'shop-info-img'}
                      className={css(styles.customerInfoShopInfoListImg)}
                    />
                    <div className={css(styles.customerInfoShopInfoListTitle)}>
                      {item.title}
                    </div>
                    <div className={css(styles.customerInfoShopInfoListValue)}>
                      {item.value}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={css(styles.customerInfoAirtable)}>
              {customerInfo.installer}/{customerInfo.deliveryType}<br/>
              {customerInfo.releaseType}<br/>
              {customerInfo.key}
            </div>
          </div>
          <div>
            <table className={css(styles.previewTable)}>
              <thead>
              <tr className={css(styles.previewTableHeader)}>
                <th style={{width: '138px'}}></th>
                <th style={{width: '208px'}}>품목</th>
                <th style={{width: '71px'}}>수량</th>
                <th style={{width: '71px'}}>실사용</th>
                <th>비고</th>
              </tr>
              </thead>
              <tbody>
              {goodsInfo.map((item1: any, index1: number) => (
                <>
                  <tr>
                    <td
                      rowSpan={item1.value.length + 1}
                      className={css(styles.previewType)}
                    >
                      {item1.type}
                    </td>
                  </tr>
                  {item1.value.map((item2: any, index2: number) =>
                    <tr style={{height: '32px'}}>
                      <td
                        className={css(
                          styles.previewTitle,
                          index2 === item2.length - 1
                            && styles.previewTypeBold,
                          item2.item?.includes('금장')
                            && styles.previewTypeGold,
                          item2.item?.includes('로즈골드')
                            && styles.previewTypeRoseGold,
                          item2.item?.includes('실버')
                            && styles.previewTypeSilver,
                          item2.item?.includes('브론즈')
                            && styles.previewTypeBronze,
                        )}
                      >
                        {item2.amount === 0 ? '' : item2.item}
                      </td>
                      <td
                        className={css(
                          styles.previewAmount,
                          index2 === item2.length - 1
                            && styles.previewTypeBold
                        )}>
                        {item2.amount === 0 ? '' : item2.amount}
                      </td>
                      <td/>
                      <td/>
                    </tr>
                  )}
                </>
              ))}
              <tr style={{height: '48px', borderBottom: 'solid 1px #c5c5c9'}}>
                <td className={css(styles.previewSignificantTitle)}>
                  특이사항
                </td>
                <td
                  colSpan={4}
                  className={css(styles.previewSignificantValue)}
                >
                  {customerInfo.significantMessage}
                </td>
              </tr>
              <tr/>
              </tbody>
            </table>
          </div>
          <table className={css(styles.signTable)}>
            <tbody>
            <tr className={css(styles.signHeader)}>
              <th>출고일</th>
              <th>출고담당자</th>
              <th>설치일자</th>
              <th>설치팀 서명란</th>
              <th>고객 서명란</th>
            </tr>
            <tr className={css(styles.signContent)}>
              <td>{moment().format('YYYY-MM-DD')}</td>
              <td/>
              <td/>
              <td/>
              <td/>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

function changeValue(
  {
    array,
    target,
  }: {
    array: any,
    target: {
      id: string,
      type: string,
      item: string
    },
  }) {
  if (target.id === undefined) {
    return array;
  }
  const newArray = _.cloneDeep(array);
  const targetByType = newArray.find((obj: any) => (
    obj.type === target.type
  ));
  const targetByItem = targetByType.value.find((obj: any) => {
    if (obj.id == null) {
      return false;
    }
    return obj.id[0] === target.id[0]
  });
  targetByItem.item = target.item;
  return newArray;
}

const styles = CreateBetaStyle({
  background: {
    width: '100%',
    minHeight: '100vh',

    backgroundColor: '#F8F9FD',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    paddingBottom: '53px',

    fontFamily: 'Pretendard',

    boxSizing: 'border-box',
  },
  header: {
    width: '1000px',
    height: '98px',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    marginBottom: '10px',
  },
  headerTitle: {
    fontSize: '30px',
    fontWeight: 700,
    color: '#2E2E2E',
  },
  headerButton: {
    width: '100px',
    height: '48px',

    background: '#000000',

    borderRadius: '10px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    fontSize: '17px',
    letterSpacing: '-1%',
    color: '#FFFFFF',
    fontWeight: 600,

    cursor: 'pointer',
  },
  previewFrame: {
    width: '1000px',
    height: '1414px',

    backgroundColor: '#FFFFFF',

    borderRadius: '10px',

    padding: '0px 40px 79px',

    boxSizing: 'border-box',

    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
  },
  previewHeader: {
    width: '920px',
    height: '66px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    borderBottom: 'solid 1px #c5c5c9',
  },
  previewHeaderLogo: {
    width: '120px',
    height: '29px',
  },
  previewHeaderTitle: {
    fontSize: '26px',
    fontWeight: 500,
    color: '#333333',
  },
  customerInfo: {
    width: '100%',

    display: 'flex',
    justifyContent: 'space-between',

    paddingTop: '20px',
    marginBottom: '17px',
  },
  customerInfoShop: {},
  customerInfoShopName: {
    color: '#111111',
    fontSize: '33px',
    fontWeight: 600,

    marginBottom: '31px',
  },
  customerInfoShopInfo: {},
  customerInfoShopInfoList: {
    display: 'flex',
    alignItems: 'center',

    marginBottom: '14px',
  },
  customerInfoShopInfoListImg: {
    width: '18px',

    marginRight: '12px',
  },
  customerInfoShopInfoListTitle: {
    width: '112px',
    color: '#666973',
    fontSize: '15px',
    fontWeight: 500,
  },
  customerInfoShopInfoListValue: {
    fontSize: '15px',
    color: '#333333',
    fontWeight: 600,
  },
  customerInfoAirtable: {
    width: '250px',
    height: '106px',

    backgroundColor: '#F4F4F8',

    borderRadius: '10px',
    border: '1px solid #c5c5c9',

    paddingLeft: '16px',

    display: 'flex',
    alignItems: 'center',

    fontSize: '16px',
    fontWeight: 500,
    color: '#2E2E2E',
    lineHeight: '26px',
  },
  previewTable: {
    width: '920px',
    borderCollapse: 'collapse',
    // ':nth-child(n) > tr, td, th': {
    //   border: '1px solid #b2bbd1',
    // },

    fontSize: '15px',
    fontWeight: 500,

    marginBottom: '20px'
  },
  previewTableHeader: {
    height: '36px',

    backgroundColor: '#F4F4F8',
    textAlign: 'center',
    fontSize: '16px',
    //
    // ':nth-child(n) > td': {
    //   borderRight: 'none',
    //   borderLeft: 'none',
    // }

    borderBottom: 'solid 1px #c5c5c9',
    borderTop: 'solid 1px #c5c5c9',
  },
  previewWarning: {
    width: '100%',

    textAlign: 'center',

    color: '#E20F23',
    fontSize: '18px',
    fontWeight: 600,

    marginBottom: '40px'
  },
  previewType: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 600,

    whiteSpace: 'pre-wrap',
  },
  previewTypeGold: {
    backgroundColor: 'rgba(255,215,0,0.4)',
  },
  previewTypeRoseGold: {
    backgroundColor: 'rgba(200,164,181,0.4)',
  },
  previewTypeSilver: {
    backgroundColor: 'rgba(192,192,192,0.4)',
  },
  previewTypeBronze: {
    backgroundColor: 'rgba(98,70,55,0.4)',
  },
  previewTitle: {
    paddingLeft: '10px',
    border: 'solid 1px #c5c5c9'
  },
  previewAmount: {
    textAlign: 'center',
    border: 'solid 1px #c5c5c9'
  },
  previewTypeBold: {
    borderBottom: 'solid 1px #A9A9A9'
  },
  previewSignificantTitle: {
    fontWeight: 600,
    fontSize: '18px',
    textAlign: 'center'
  },
  previewSignificantValue: {
    paddingLeft: '10px',
    color: '#333333',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',

    borderLeft: 'solid 1px #c5c5c9'
  },
  signTable: {
    width: '100%',
    borderCollapse: 'collapse',

    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 500,
    color: '#666666',
  },
  signHeader: {
    height: '35px',

    backgroundColor: '#F4F4F8',

    fontSize: '17px',
    ':nth-child(n) > tr, td, th': {
      border: '1px solid #c5c5c9',
    },
  },
  signContent: {
    height: '43px',
    fontSize: '17px',

    border: 'solid 1px #c5c5c9',

    color: '#333333',
  },
  borderBottom: {
    borderBottom: 'solid 1px #c5c5c9'
  },
});
