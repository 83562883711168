import React from 'react';
import {css} from 'aphrodite';

import {CreateBetaStyle} from '../../shared/CreateStyle';

export const DefaultButton = (
  {
    title,
    onClick,
    success,
    disabled
  }: {
    title: string,
    onClick: () => Promise<void> | void,
    success: boolean,
    disabled?: boolean,
  }) => {
  return (
    <button
      type="button"
      className={css(
        styles.frame,
        success && styles.successFrame,
        disabled ? styles.disabled : styles.available
      )}
      onClick={success ? undefined : onClick}
      disabled={disabled}
    >
      {title}
    </button>
  )
}

const styles = CreateBetaStyle({
  frame: {
    width: '158px',
    height: '65px',

    border: 'none',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    color: 'white',
    fontSize: '18px',
    fontWeight: 600,
    fontFamily: 'Pretendard',

    borderRadius: '15px',

    cursor: 'pointer',
  },
  available: {
    backgroundColor: '#E20F23',
  },
  disabled: {
    backgroundColor: '#D9D9D9',
  },
  successFrame: {
    backgroundColor: '#666973',
    color: 'white',
  },
  deactive: {
    backgroundColor: '#D9D9D9',
    color: 'white',
  },
  complete: {
    backgroundColor: '#666973',
  },
  error: {
    backgroundColor: 'black',
  }
})
