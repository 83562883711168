import React, {useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';
import {css} from 'aphrodite';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {Recipient} from './Recipient';
import {AlimtalkPreview} from './AlimtalkPreview';
import {DefaultButton} from '../../shared/components/DefaultButton';
import {MasterNoticePopup} from '../../shared/components/NoticePopup';
import Loading from '../../shared/components/Loading';
import {CreateBetaStyle} from '../../shared/CreateStyle';
import {Image} from '../../shared/components/Image';
import {getToday} from '../utils/DateTimeUtils';

import {
  getMasterTemplate,
  getTemplateDetail,
  ITemplateInfo,
  sendAlimtalk
} from '../services/AlimtalkService';

import Arrow from '../../../assets/images/svgs/arrow.svg'

export const MasterAlimtalkView = () => {
  const [slider, setSlider] = useState<any>();
  const [sliderIndex, setSliderIndex] = useState(0);
  const [recordId, setRecordId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [basicInfo, setBasicInfo] = useState({name: '', phone: ''});
  const [direction, setDirection] = useState('');
  const [values, setValues] = useState<string[]>([]);
  const [templateCode, setTemplateCode] = useState('');
  const [isLastTemplate, setIsLastTemplate] = useState(false);
  const [isFirstTemplate, setIsFirstTemplate] = useState(false);
  const [templateList, setTemplateList] = useState<any[]>([]);
  const [template, setTemplate] = useState<ITemplateInfo | any>({});
  const [status, setStatus] = useState({
    status: 'loading',
    message: '',
  })

  const selectCase = (index: number) => {
    slider.slickGoTo(index);
    setSliderIndex(index);
  };

  const {id} = useParams<{ id: string }>();

  useEffect(() => {
    if (id === '' || id == null) {
      setStatus({
        status: 'error',
        message: '레코드 아이디가 없습니다',
      })
      return;
    }
    setRecordId(id);
    setIsLoading(true);
    getMasterTemplate({recordId: id}).then((res: any) => {
      setBasicInfo({...res})
      setTemplateList(res.templateList);
      getTemplate({
        index: 0,
        templateList: res.templateList,
        basicInfo: {
          name: res.name,
          phone: res.phone
        }
      })
      // getTemplateDetail({
      //   recordId: id,
      //   templateCode: res.templateList[0].code,
      //   targetNumber: res.phone,
      //   values: res.templateList[0].values,
      // }).then((v: any) => {
      //   setTemplateCode(res.templateList[0].code);
      //   setValues(v.values)
      //   setTemplate(v.template);
      //   selectCase(0);
      // })
      //   .catch((e: any) => setStatus({
      //     status: 'error',
      //     message: `템플릿 아이디\n${res.templateList[0].code}\nmessage\n${e.message}`
      //   }))
    }).finally(() => setIsLoading(false))
  }, [id])

  const onClickSend = async () => {
    try {
      setIsLoading(true)
      await sendAlimtalk({
        templateCode,
        phone: basicInfo.phone,
        value: values,
      })
      setStatus({
        status: 'success',
        message: '발송완료🥳',
      })
    } catch (e: any) {
      setStatus({status: 'error', message: e.message})
    } finally {
      setIsLoading(false);
    }
  }

  const sliderSettings = {
    autoplay: false,
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 0,
    fade: true,
    pauseOnHover: false,
  };

  const getTemplate = (
    {
      index,
      templateList,
      basicInfo
    }: {
      index: number,
      templateList: any,
      basicInfo: any
    }) => {
    setIsLoading(true)
    getTemplateDetail({
      recordId,
      templateCode: templateList[index].code,
      targetNumber: basicInfo.phone,
      values: templateList[index].values,
    })
      .then((res: any) => {
        // selectCase(index);
        setTemplateCode(templateList[index].code);
        setValues(res.values)
        setTemplate(res.template);
      })
      .catch((e: any) => setStatus({
        status: 'error',
        message: `템플릿 아이디\n${templateList[index].code}\n메시지\n${e.message}`
      }))
      .finally(() => {
        selectCase(index);
        setIsLoading(false)
      })
  }

  const onClickNext = async () => {
    if (sliderIndex === templateList.length - 1) {
      setIsLastTemplate(true);
      return;
    }
    getTemplate({index: sliderIndex + 1, templateList, basicInfo});
    setDirection('front');
  };

  const onClickPre = () => {
    if (sliderIndex === 0) {
      setIsFirstTemplate(true);
      return;
    }
    getTemplate({index: sliderIndex - 1, templateList, basicInfo});
    setDirection('back');
  }

  return (
    <>{status.status !== 'loading' && (
      <MasterNoticePopup
        content={status.message}
        onClickConfirm={() => {
          setStatus({
            status: 'loading',
            message: '성공'
          })
          if (direction === 'back') {
            onClickPre();
            return;
          }
          onClickNext();
        }}
        button={status.status === 'success' ? undefined : '건너뛰기'}
        isLarge={status.status !== 'success'}
      />
    )}
      {isLastTemplate && (
        <MasterNoticePopup
          content={'마지막 알림톡입니다.'}
          button={[
            {
              title: '뒤로가기',
              onClick: () => {
                getTemplate({index: sliderIndex - 1, templateList, basicInfo});
                setIsLastTemplate(false);
              }
            },
            {
              title: '처음으로',
              onClick: () => {
                getTemplate({index: 0, templateList, basicInfo});
                setIsLastTemplate(false);
              }
            }
          ]}
        />
      )}
      {isFirstTemplate && (
        <MasterNoticePopup
          content={'첫 알림톡입니다.'}
          button={'뒤로가기'}
          onClickConfirm={() => {
            getTemplate({index: 1, templateList, basicInfo});
            setIsFirstTemplate(false)
          }}
        />
      )}
      {isLoading && <Loading/>}
      <div className={css(styles.alimtalkView)}>
        <Recipient
          title={'마스터 알림톡 발송'}
          name={basicInfo.name}
          phone={basicInfo.phone}
        />
        <div className={css(styles.preview)}>
          <div className={css(styles.previewTitle)}>메시지 미리보기 <span
            className={css(styles.templateCount)}>({sliderIndex + 1}/{templateList.length})</span></div>
          <div className={css(styles.kakaoPreviewFrame)}>
            <div className={css(styles.kakaoPreview)} id={'preview'}>
              {sliderIndex !== templateList.length - 1 && (
                <Image
                  src={Arrow}
                  alt={'arrow'}
                  className={css(styles.rightArrow)}
                  onClick={() => onClickNext()}
                />
              )}
              {sliderIndex !== 0 && (
                <Image
                  src={Arrow}
                  alt={'arrow'}
                  className={css(styles.leftArrow)}
                  onClick={onClickPre}
                />
              )}
              <div className={css(styles.kakaoPreviewDateBox)}>
                <div className={css(styles.dateLine)}/>
                {getToday()}
                <div className={css(styles.dateLine)}/>
              </div>
              <Slider
                ref={c => setSlider(c)}
                {...sliderSettings}
              >
                {templateList.map((item, index) =>
                  <div key={'template-list_' + index}>
                    <AlimtalkPreview template={template}/>
                  </div>
                )}
              </Slider>
            </div>
          </div>
          {DefaultButton(
            {
              title: status.status === 'success' ? '발송완료!' : '발송하기',
              onClick: () => onClickSend(),
              success: status.status === 'success'
            })}
        </div>
      </div>
    </>
  )
}

const styles = CreateBetaStyle({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '15px'
  },
  rightArrow: {
    position: 'absolute',
    right: '20px',
    top: '45%',
    zIndex: 50,
    transform: 'rotate(180deg)',

    cursor: 'pointer'
  },
  leftArrow: {
    position: 'absolute',
    left: '20px',
    top: '45%',
    zIndex: 50,

    cursor: 'pointer'
  },
  alimtalkView: {
    width: '80vw',
    height: '95vh',

    borderRadius: '10px',

    backgroundColor: 'white',

    boxSizing: 'border-box',

    padding: '46px 60px',

    fontFamily: 'Pretendard',

    display: 'flex',
  },
  templateCount: {
    fontSize: '12px',
  },
  preview: {
    marginTop: '15px',
  },
  previewTitle: {
    color: '#666973',
    fontSize: '15px',
    fontWeight: 500,

    marginBottom: '16px',
  },
  kakaoPreviewFrame: {
    width: '400px',
    height: '650px',

    position: 'relative',

    marginBottom: '21px',
  },
  kakaoPreview: {
    width: '100%',
    height: '100%',

    backgroundColor: '#B9CBDA',

    borderRadius: '15px',

    paddingTop: '30px',

    boxSizing: 'border-box',

    overflowY: 'scroll',

    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: 'rgba(69, 79, 93, 0.9)',
      borderRadius: '15px',
      border: '3px solid transparent',
    },
    '::-webkit-scrollbar-track': {
      padding: '10px',

      background: 'transparent',
    },
  },
  kakaoPreviewDateBox: {
    boxSizing: 'border-box',
    width: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    color: '#666973',
    fontSize: '11px',
    fontWeight: 600,

    padding: '0px 13px',
    marginBottom: '29px',
  },
  dateLine: {
    width: '130px',
    borderTop: 'solid 1px #A6B6C4',
  },
});
