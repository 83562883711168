import React from 'react';
import {css} from 'aphrodite';

import {CreateBetaStyle} from '../CreateStyle';

interface NoticePopupProps {
  onClickConfirm?: () => void,
  content: string,
  button?: string | { title: string, onClick?: () => void }[],
  isLarge?: boolean,
}

interface ButtonProps {
  button?: string | { title: string, onClick?: () => void }[],
  onClickConfirm?: () => void,
}

export const NoticePopup = ({onClickConfirm, content, button, isLarge}: NoticePopupProps) => {
  return (
    <div className={css(styles.background)}>
      <div className={css(styles.frame, isLarge && styles.frameLarge)}>
        <div className={css(styles.content)}>
          {content.replace('Error:', '')}
        </div>
        <Button button={button} onClickConfirm={onClickConfirm}/>
      </div>
    </div>
  );
}

const Button = ({button, onClickConfirm}: ButtonProps) => {
  const onClickClose = () => {
    if (onClickConfirm === undefined || onClickConfirm === null || button === null) {
      window.close();
      return;
    }
    onClickConfirm();
  }

  return (
    <div className={css(styles.buttonFrame)}>
      {
        (typeof button === 'string' || button == null)
          ? (
            <div className={css(styles.button)} onClick={() => onClickClose()}>
              {button ?? '확인'}
            </div>
          )
          : (
            <>
              {button.map((item: any, index: number) => (
                <div
                    className={css(styles.buttons, index === 0 && styles.buttonsNotBackground)}
                    onClick={item.onClick}
                    key={'button_' + index}
                >
                  {item.title}
                </div>
                )
              )}
            </>
          )
      }
    </div>
  );
}

export const MasterNoticePopup = (
  {onClickConfirm, content, button, isLarge}: NoticePopupProps
) => {
  const contentSplit = content.split('\n');

  return (
    <div className={css(styles.background)}>
      <div className={css(styles.frame, isLarge && styles.frameLarge)}>
        <div className={css(styles.content)}>
          {
            content.length > 20
              ? (
                <>
                  {contentSplit.map((item: string, index: number) =>
                    <div
                      key={'content_' + index}
                      className={css(
                        (index === 0 || index === 2)
                          ? styles.contentTitle
                          : styles.contentContent,
                        index === 1 && styles.contentSpace
                      )}
                    >
                      {item}
                    </div>
                  )}
                </>
              )
              : content.replace('Error:', '')}
        </div>
        <Button button={button} onClickConfirm={onClickConfirm}/>
      </div>
    </div>
  );
}

const styles = CreateBetaStyle({
  background: {
    width: '100%',
    height: '100%',

    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 100,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  frame: {
    width: '321px',
    height: 'auto',

    backgroundColor: 'white',

    borderRadius: '15px',

    padding: '42px 35px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    boxSizing: 'border-box',

    fontFamily: 'Pretendard',
  },
  frameLarge: {
    width: '500px',
  },
  content: {
    width: '100%',

    fontSize: '22px',
    color: '#30363D',
    lineHeight: '30px',
    fontWeight: 700,

    wordBreak: 'keep-all',
    whiteSpace: 'pre-wrap',

    marginBottom: '25px',
  },
  contentTitle: {
    color: '#E20F23',
    marginBottom: '2px',
  },
  contentSpace: {
    marginBottom: '8px',
  },
  contentContent: {
    fontWeight: 500,
    fontSize: 17,
  },
  buttonFrame: {
    width: '100%',
    height: '47px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    width: '45%',

    backgroundColor: '#E20F23',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: '10px',

    fontSize: '18px',
    fontWeight: 600,
    color: 'white',

    cursor: 'pointer',
  },
  buttonsNotBackground: {
    backgroundColor: 'transparent',
    color: 'black',
  },
  button: {
    width: '100%',
    backgroundColor: '#E20F23',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: '10px',

    fontSize: '18px',
    fontWeight: 600,
    color: 'white',

    cursor: 'pointer',
  }
})
