import {css} from 'aphrodite';
import * as _ from 'lodash';
import React from 'react';

import {Image} from '../../shared/components/Image';
import {CreateBetaStyle} from '../../shared/CreateStyle';

export const RequestInfoList = (
  {
    requestInfo,
    dropdown,
    setDropdown,
    setRequestInfo
  }: {
    requestInfo: any,
    dropdown: string,
    setDropdown: (value: string) => void,
    setRequestInfo: (value: any) => void,
  }) => {
  return (
    <div className={css(styles.requestInfo)}>
      <div className={css(styles.requestTitle)}>신청 정보</div>
      <div>
        {requestInfo.map((item1: any, index: number) =>
          <div key={'request_' + index} className={css(styles.requestList)}>
            <Image
              src={item1.img}
              alt={'request-info_' + index}
              className={css(styles.requestListIcon)}
            />
            <div className={css(styles.requestListTitle)}>{item1.title}</div>
            {item1.isDropdown
              ? (
                <div
                  className={css(
                    styles.dropdownValue,
                    (item1.value === undefined || item1.value === '')
                      && styles.dropdownValuePlaceholder
                  )}
                  onClick={() => setDropdown(dropdown !== '' ? '' : item1.title)}
                >
                  {(item1.value === undefined || item1.value === '')
                    ? '옵션을 선택해주세요.'
                    : item1.value
                  }
                  {dropdown === item1.title
                    && (
                      <div className={css(styles.dropDownFrame)}>
                        {item1.options.map((team: any, index: number) => (
                          <div
                            key={index}
                            className={css(styles.dropDownList)}
                            onClick={() => {
                              const temp = _.cloneDeep(requestInfo);
                              const temp02 = temp.find((item: any) => item.title === item1.title);
                              temp02.value = team.name;
                              setRequestInfo(temp);
                              setDropdown('')
                            }}
                          >
                            {team.name}
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              )
              : (
                <input
                  value={item1.value === null ? undefined : item1.value}
                  placeholder={'내용을 입력해주세요.'}
                  className={css(styles.requestListContent)}
                  disabled={!item1.isEdit}
                  onChange={(e) => {
                    const temp = _.cloneDeep(requestInfo);
                    const temp02 = temp.find((v: any) => v.title === item1.title);
                    temp02.value = e.target.value;
                    setRequestInfo(temp);
                  }}
                />
              )}
          </div>
        )}
      </div>
    </div>
  )
}

const styles = CreateBetaStyle({
  requestInfo: {
    width: '665px',

    borderBottom: 'solid 1px #F4F4F4',

    paddingBottom: '30px',
    paddingTop: '30px',

    '@media(max-width: 1800px)': {
      width: '33vw',
    },
  },
  shopInfo: {
    paddingTop: '30px',
  },
  requestTitle: {
    fontSize: '17px',
    color: '#666973',
    fontWeight: 600,

    marginBottom: '20px',
  },
  requestList: {
    minHeight: '36px',

    display: 'flex',
    // alignItems: 'center',

    marginBottom: '5px',
  },
  requestListIcon: {
    width: '20px',
    height: '20px',

    marginRight: '5px',
    marginTop: '8px',
  },
  requestListTitle: {
    width: '205px',

    fontSize: '15px',
    color: '#666973',
    fontWeight: 500,

    marginTop: '10px',

    '@media(max-width: 1800px)': {
      width: '10vw',
    },
  },
  requestListContent: {
    width: '435px',
    minHeight: '36px',

    fontSize: '15px',
    fontWeight: 500,
    color: '#333333',
    fontFamily: 'Pretendard',

    whiteSpace: 'pre',

    border: 'none',
    borderRadius: '5px',
    padding: '0px 5px 0px 5px',
    boxSizing: 'border-box',

    ':focus': {
      outline: 'solid 1px #999999'
    },
    '::placeholder': {
      color: '#999999',
    },
    ':hover': {
      backgroundColor: '#F4F4F4',
    },
    ':disabled': {
      backgroundColor: 'transparent',
    },

    '@media(max-width: 1800px)': {
      width: '20vw',
    },
  },
  requestListContentTextarea: {
    height: '100px',

    resize: 'none',

    paddingTop: '5px',

    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar:horizontal': {
      height: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: '#A8A8A8',
      borderRadius: '15px',
      border: '3px solid transparent',
    },
    '::-webkit-scrollbar-track': {
      padding: '10px',

      background: 'transparent',
    },

    '@media(max-width: 1800px)': {
      height: '100px',
    },
  },
  dropDownFrame: {
    width: '220px',
    height: 'auto',
    maxHeight: '293px',

    overflowY: 'scroll',
    overflowX: 'hidden',

    position: 'absolute',
    left: 0,
    top: '40px',
    zIndex: 50,

    boxShadow: ' 5px 5px 10px rgba(0, 0, 0, 0.15)',
    border: '1px solid #ACACAC',
    borderRadius: '10px',
    backgroundColor: 'white',

    padding: '10px',

    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: '#A8A8A8',
      borderRadius: '15px',
      border: '3px solid transparent',
    },
    '::-webkit-scrollbar-track': {
      padding: '10px',

      background: 'transparent',
    },
  },
  dropdownValue: {
    position: 'relative',
    width: '435px',
    height: '36px',
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: 500,
    color: '#333333',
    paddingLeft: '5px',

    borderRadius: '5px',

    cursor: 'pointer',

    boxSizing: 'border-box',

    ':hover': {
      backgroundColor: '#FBFBFB',
    },

    '@media(max-width: 1800px)': {
      width: '20vw',
    },
  },
  dropdownValuePlaceholder: {
    color: '#999999'
  },
  dropDownList: {
    width: '100%',
    height: '40px',

    fontSize: '15px',
    fontWeight: 500,
    color: '#333333',

    paddingLeft: '10px',

    display: 'flex',
    alignItems: 'center',

    borderRadius: '5px',

    boxSizing: 'border-box',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: '#FAFAFA',
    },

    '@media(max-width: 1800px)': {
      width: '20vw',
    },
  },
})
