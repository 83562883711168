import {css} from 'aphrodite';

import {CreateBetaStyle} from '../../shared/CreateStyle';
import Check from '../../../assets/images/svgs/check_white.svg';
import {Image} from '../../shared/components/Image';

interface SectionFrameProps {
  title: string;
  children?: React.ReactNode;
  isShown?: boolean;
  onClickHidden?: () => void;
  isSheet?: boolean;
  isSheetDetail?: boolean;
  checkSheetDetail?: () => void;
  addSheetList?: () => void;
  frameZIndex20?: boolean;
  frameZIndex40?: boolean;
}

export const SectionFrame = (
  {
    title,
    children,
    isShown,
    onClickHidden,
    isSheet,
    isSheetDetail,
    checkSheetDetail,
    addSheetList,
    frameZIndex20,
    frameZIndex40
  }: SectionFrameProps) => {
  // const isExistDropdown = title === '부자재 구성' || title === '월 할부금';

  return (
    <div
      className={css(
        styles.frame,
        (!isShown || children == null) && styles.frameHidden,
        frameZIndex20 && styles.frameZIndex20,
        frameZIndex40 && styles.frameZIndex40
      )}
    >
      <div className={css(styles.header)}>
        <div className={css(styles.headerTitle)}>{title}</div>
        <div className={css(styles.headerViewBox)}>
          <div className={css(styles.headerViewText)}>
            {!isShown ? '미표시' : '표시중'}
          </div>
          <div
            className={css(
              styles.headerViewBar,
              !isShown && styles.headerViewBarHidden
            )}
            onClick={onClickHidden}
          >
            <div
              className={css(
                styles.headerViewBarCircle,
                !isShown && styles.headerViewBarCircleHidden
              )}
            />
          </div>
        </div>
      </div>
      {isShown && (
        <div>
          {isSheet && (
            <div className={css(styles.sheetOption)}>
              <div
                className={css(styles.sheetOptionDetail)}
                onClick={checkSheetDetail}
              >
                <div
                  className={css(
                    styles.sheetOptionDetailCheckBox,
                    isSheetDetail && styles.sheetOptionDetailCheckBoxSelected
                  )}
                >
                  {isSheetDetail && <Image src={Check} alt={'check'}/>}
                </div>
                <div>견적서에 상세 내역 표시</div>
              </div>
              <div
                className={css(styles.sheetOptionPlus)}
                onClick={addSheetList}
              >
                항목추가
              </div>
            </div>
          )}
          {children}
        </div>
      )}
    </div>
  );
}

const styles = CreateBetaStyle({
  frame: {
    width: '750px',

    backgroundColor: 'white',

    padding: '20px 15px',
    marginTop: '20px',

    borderRadius: '5px',

    boxSizing: 'border-box',

    filter: 'drop-shadow(1px 1px 5px rgba(22, 22, 26, 0.224))',

    position: 'relative',
    zIndex: 10,
  },
  frameZIndex20: {
    zIndex: 20,
  },
  frameZIndex40: {
    zIndex: 40,
  },
  frameHidden: {
    paddingBottom: '0px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontSize: '17px',
    fontWeight: 700,

    marginBottom: '20px',
  },
  headerViewBox: {
    height: '24px',

    display: 'flex',
    alignItems: 'center',

    fontSize: '12px',
    fontWeight: 700,
  },
  headerViewText: {
    marginRight: '5px',
  },
  headerViewBar: {
    width: '42px',
    height: '20px',

    borderRadius: '100px',

    backgroundColor: '#403CFF',

    position: 'relative',

    display: 'flex',
    alignItems: 'center',

    boxSizing: 'border-box',

    cursor: 'pointer',
  },
  headerViewBarHidden: {
    backgroundColor: '#c2c2c2',
  },
  headerViewBarCircle: {
    width: '16px',
    height: '16px',

    backgroundColor: 'white',

    borderRadius: '100%',
    position: 'absolute',
    left: '23px',
    transition: 'left 0.3s',
  },
  headerViewBarCircleHidden: {
    left: '3px',
    transition: 'left 0.3s',
  },
  sheetOption: {
    width: '100%',

    display: 'flex',
    justifyContent: 'space-between',

    marginBottom: '11px',
  },
  sheetOptionDetail: {
    width: '155px',
    height: '35px',

    borderRadius: '5px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontSize: '12px',
    fontWeight: 500,
    color: '#333333',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: '#e9e9f4',
    }
  },
  sheetOptionDetailCheckBox: {
    width: '18px',
    height: '18px',

    backgroundColor: 'white',

    borderRadius: '5px',
    border: 'solid 1px #8E8E8E',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginRight: '5px',

    cursor: 'pointer',
  },
  sheetOptionDetailCheckBoxSelected: {
    backgroundColor: '#403CFF',

    border: 'none'
  },
  sheetOptionPlus: {
    width: '78px',
    height: '27px',

    borderRadius: '5px',

    backgroundColor: '#403CFF',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontSize: '12px',
    fontWeight: 700,
    color: 'white',

    cursor: 'pointer',
  },
})
