export const CONTRACT_INFO =
  '1. 모든 할인 금액은 약정 유지 조건 입니다.\n' +
  '2. 주문형 광고 플랫폼 소프트웨어 제공되며, 본 견적서는 태블릿 메뉴판 내에서 티오더가 정한 내용 및 방법으로 광고 송출 됩니다.\n' +
  '3. 계약만료일 당일 재계약 계약서 작성 지연시 라이센스가 자동적으로 잠김 처리 됩니다.\n' +
  '4. 월 할부금 납부 이체일로 7일 이후 미납 시 라이센스가 자동적으로 잠김 처리 됩니다.\n' +
  '5. 기준 렌탈사 이외의 렌탈사로 진행될 경우 해당 견적이 변경될 수 있습니다.\n' +
  '6. 견적서 내 모든 금액은 금액은 부가세 별도입니다.';

export const INSTALL_INFO =
  '1. 설치 이후 단순 변심으로 인한 태블릿 수량 변경은 불가합니다. 단, 추가설치는 가능합니다.\n' +
  '2. 설치 현장 상황에 따라 추가 부자재 및 별도 서비스 비용이 부과될 수 있습니다. \n' +
  '3. 안정적인 서비스 운영을 위한 인터넷 환경은 기업용 오피스넷 가입 및 1GB 이상 급으로 권장합니다. 또한, MAC제한 IP는 테이블 수의 2배이상으로 열어주셔야 합니다.';

export const ELECTRON_DEADLINES = [
  {
    title: '전기공사 진행',
    content:
      '1. 제품 무상 A/S 기간 안내\n' +
      ' (1) 태블릿&공유기 : 약정 기간 내 무상 A/S\n' +
      ' (2) 충전기&케이블 외 소모품 : 설치일로부터 3개월 이내 무상 A/S\n' +
      '2. 전기 시공 A/S 안내\n' +
      ' (1) 설치일로부터 6개월 이내 무상 A/S\n' +
      '※ 무상 A/S는 제품의 하자일 경우에만 진행 가능합니다. 고객 과실로 인한 고장 및 파손은 무상 A/S에서 제외됩니다.',
  },
  {
    title: '보조배터리 사용',
    content:
      '1. 제품 무상 A/S 기간 안내\n' +
      ' (1) 태블릿&공유기 : 약정 기간 내 무상 A/S\n' +
      ' (2) 충전기&케이블 외 소모품 : 설치일로부터 3개월 이내 무상 A/S\n' +
      ' (3) 보조배터리(케이블 제외) : 설치일로부터 6개월 이내 무상 A/S\n' +
      '※ 사용 상의 소모로 판단되는 사용량 저하는 A/S로 진행되지 않으며, 무상 A/S는 제품의 하자일 경우에만 진행 가능합니다. 고객 과실로 인한 고장 및 파손은 무상 A/S에서 제외됩니다.',
  },
  {
    title: '자체 콘센트 사용',
    content:
      '1. 제품 무상 A/S 기간 안내\n' +
      ' (1) 태블릿&공유기 : 약정 기간 내 무상 A/S\n' +
      ' (2) 충전기&케이블 외 소모품 : 설치일로부터 3개월 이내 무상 A/S\n' +
      '※ 무상 A/S는 제품의 하자일 경우에만 진행 가능합니다. 고객 과실로 인한 고장 및 파손은 무상 A/S에서 제외됩니다.',
  }
];


export const INSTALLMENT_OPTIONS = ['태블릿', '마스터 태블릿'];

export const CONTRACT_PERIOD_OPTIONS = ['12', '24', '36'];


export const INSTALLMENT_DEFAULT_OPTIONS = [
  {
    name: '태블릿',
    month: '36',
    price: '18000',
    quantity: '',
    total: '',
  },
  {
    name: '마스터 태블릿',
    month: '36',
    price: '18000',
    quantity: '',
    total: '',
  },
];

export const SUB_GOODS_DEFAULT_OPTIONS = [
  {
    type: '',
    name: '',
    color: '',
    price: '',
    quantity: '',
    total: '',
  }
];

export const SALE_DEFAULT_OPTIONS = [
  {
    type: '',
    name: '',
    price: '',
    quantity: '',
  }
];
export const PROMOTION_DEFAULT_OPTIONS = [{
  title: '',
  price: '',
  quantity: '',
}];


export const INITIAL_COMMENT = `안녕하세요 대표님.
티오더입니다.`;

export const THIRD_COMMENT = `설치까지 불편함 없도록 도와드릴 것이며,
궁금한 사항은 대표번호로 전화 주시거나 이 단톡방에서 남겨주시면 실시간 응대되도록 최선을 다하겠습니다.`;

export const getGoodsInfo = ({period, tablet, masterTablet, price, customer}: {period: string, tablet: number, masterTablet: number, price: string, customer: any}) => {
  const contractYears = `${Number(period) / 12}`;
  const tabletInfo = masterTablet ? `${tablet}+${masterTablet}(마스터)=${Number(tablet)+Number(masterTablet)}대` : `${tablet}대`;
  return `${contractYears}년 약정 / ${tabletInfo} / ${price}원 / ${customer.holderColor} / ${customer.electronType} / ${customer.paymentType}  견적서입니다`;
}

export const getCustomer = (phone: string, key: string) => {
  return `매장 대표님 연락처: ${phone}
일련번호: ${key}`;
}

export const getAccountInfo = (prepayment: string) => {
  return `[선납금(초기 계약금) 금액안내]
  
예금주 : ㈜티오더
계좌은행 : 신한은행
계좌번호 : 140-012-934957
선납금(초기 계약금) ${prepayment}원
매장 + 지점명으로 입금 후 톡 주시면 담당자 접수 요청해드리겠습니다.`
}

export const WORKING_TIME_INFO = `[티오더 업무시간 안내]

<영업지원팀>
■ 운영시간 : 평일 10시~19시
■ 점심시간 : 오후 1시~2시
※ 영업지원팀 비운영 시간 중 문의가 필요하신 경우 아래 고객지원팀으로 문의 부탁드리며,
접수 순차적으로 영업일에 담당자를 통해 신속히 답변 드리겠습니다.

<고객지원팀>
■ 대표전화 : 1644-4425
■ 운영시간 : 매일 오전 10시~익일 02시

해당 단톡방은 설치 전까지만 이용가능하며,
설치 후에는 고객지원 번호 "1644-4425" 또는 설치 후 발송되는 해피톡을 이용하여 응대드리는 점 참고부탁드립니다.`;

export const LAST_INFO = `안녕하세요 대표님 상담내용 정리하여 전달드렸습니다. 추가적인 문의사항의 경우 카톡방 혹은 전화주시면 빠르게 응대드릴 수 있도록 하겠습니다!`;
export const YOUTUBE_INFO = `[유튜브 소개영상]
https://www.youtube.com/watch?v=NIWGkOArYCs`;

