import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {css} from 'aphrodite';
import * as _ from 'lodash';

import Loading from '../../shared/components/Loading';
import {LogisticsNoticePopup} from './LogisticsNoticePopup';
import {GoodsPreview} from './SelectItem';
import {PreviewPopup} from './PreviewPopup';
import {GoodsStock} from './GoodsStock';
import {GoodsList} from './GoodsList';
import {RequestInfoList} from './RequestInfoList';
import {ShopInfoList} from './ShopInfoList';
import {CreateBetaStyle} from "../../shared/CreateStyle";

import {
  getCustomerInfo,
  getGoodsNameByRecordId_,
  getGoodsTypes,
  saveCustomerInfo,
  updateStatusToComplete,
} from '../services/LogisticsService';

import {
  addIsErrorInObject,
  addIsSelectedInObject,
  getGoodsList,
  getRequestInfo,
  getShopInfo,
  setCustomerInfos
} from '../helpers/LogisticsHelper';

export const BasicForm = () => {
  const [preview, setPreview] = useState('');
  const [isPreview, setIsPreview] = useState(false);
  const [popup, setPopup] = useState({
    isShow: false,
    message: '',
    buttonType: 'loading'
  });

  const [recordId, setRecordId] = useState('');

  const [customerInfo, setCustomerInfo] = useState<any>({});
  const [requestInfo, setRequestInfo] = useState<any>([]);
  const [shopInfo, setShopInfo] = useState<any>([]);

  const [selected, setSelected] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [dropdown, setDropdown] = useState('');
  const [screw, setScrew] = useState('');
  const [typeDropdownNumber, setTypeDropdownNumber] = useState(0);

  const [goodsList, setGoodsList] = useState([]);
  const [goodsFocus, setGoodsFocus] = useState('');

  const {id} = useParams<{ id: string }>();

  const onClickComplete = async (isComplete: boolean) => {
    setIsLoading(true);

    try {
      // 작성완료 시, 신청 정보 유효성 체크
      const isValidForRequestInfo = requestInfo.filter((item: any) => (
        item.value === undefined
          || item.value === null
          || item.value === ''
        )).length === 0;

      // 작성완료 시, 매장 정보 유효성 체크
      const isValidForShopInfo = shopInfo.filter((item: any) => (
        item.value === undefined
          || item.value === null
          || item.value === ''
        )).length === 0;

      if (!isValidForRequestInfo || !isValidForShopInfo) {
        throw Error('신청 정보 또는 매장 정보에\n누락된 정보가 있습니다.');
      }

      selected.map((item1: any) => {
        const filterByType: any = goodsList.filter((item2: any) => (
            item2.type === item1.type
          ));
        const filterById: any = filterByType[0].items.filter((item2: any) => (
            item2.id === item1.id
          ));

        if (item1.amount === 0) {
          setSelected(addIsErrorInObject({
            array: selected,
            id: item1.id,
            add: ['isZero'],
            isAdd: true,
          }))
          throw Error('');
        }

        if (filterById[0].stock < Number(item1.amount)) {
          setSelected(addIsErrorInObject({
            array: selected,
            id: item1.id,
            add: ['isError'],
            isAdd: true,
          }))
          // throw Error(`${item.title}의 수량이\n재고를 초과했습니다.`);
          throw Error('');
        }
      });

      await saveCustomerInfo({
        recordId,
        info: setCustomerInfos({requestInfo, shopInfo}),
        goods: getGoodsList(selected, screw),
        isComplete
      });

      if (isComplete) {
        await updateStatusToComplete(recordId);
      }
      setPopup({
        isShow: true,
        message: isComplete ? '작성완료🥳' : '임시저장 완료',
        buttonType: isComplete ? 'closed' : '',
      });
    } catch (e: any) {
      if (e.message === '') {
        return;
      }
      if (e.message.includes('504')) {
        setPopup({
          isShow: true,
          message: '접수요청에 시간이 오래 걸렸습니다.\n정상적으로 접수되었는지 확인해주세요.',
          buttonType: 'error',
        });
        return;
      }
      setPopup({
        isShow: true,
        message: e.message,
        buttonType: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (id === undefined || id === null || id === '') {
      setPopup({
        isShow: true,
        message: '레코드 아이디가 없습니다.',
        buttonType: 'closed',
      })
      return;
    }
    setRecordId(id)
  }, [id]);

  useEffect(() => {
    if (id === undefined || id === null || id === '') {
      return;
    }
    setIsLoading(true);
    getGoodsTypes().then((goods: any) => {
      // console.log(goods)
      getCustomerInfo(id).then((res) => {
        if (res == null) {
          setPopup({
            isShow: true,
            message: '레코드 아이디에 해당하는 \n고객이 없습니다.',
            buttonType: 'closed',
          });
          setIsLoading(false);
          return
        }
        // console.log(res)
        setCustomerInfo(res);
        setScrew(res.screw ? String(res.screw) : '');

        const requestInfo = getRequestInfo(res);
        const shopInfo = getShopInfo(res);

        const sel = _.cloneDeep(selected);
        let newGoodsList = _.cloneDeep(goods);

        // res.goods.map((v: any) => {
        res.goods.map((item1: any) => {
          // console.log(index)
          if (item1.type === '12.나사') {
            return;
          }
          // v.value.map((v2: any) => {
          item1.value.map((item2: any) => {
            // if (v2.id == null) {
            //   return false22;
            // }
            // console.log(index2)
            const recordId = item2.id != null ? item2.id[0] : '';

            getGoodsNameByRecordId_(recordId, goods).then((res) => {
              if (res == null) {
                setGoodsList(newGoodsList);
                return;
              }

              // console.log(res)
              // const isExist = Array.isArray(item2.id)
              //   && item2.id.length > 0
              //   && sel.some((item3: any) => (
              //       item3.id === item2.id[0]
              //     ));
              const isExist = sel.filter((item3: any) => (
                item3.id !== undefined
                && item2.id !== undefined
                && item3.id === item2.id[0]
              )).length > 0;

              if (isExist) {
                return;
              }
              newGoodsList = addIsSelectedInObject({
                array: newGoodsList,
                target: {
                  type: item1.type,
                  item: res.item,
                },
                isSelected: true,
              })
              // console.log(goodsNew)
              setGoodsList(newGoodsList);

              if (item2.id === undefined) {
                return;
              }
              sel.push({
                id: item2.id[0],
                type: item1.type,
                title: res.item,
                amount: item2.amount,
                form: res.holderForm,
                color: res.color,
              });
            });
          });
        });
        setSelected(sel);
        setShopInfo(shopInfo);
        setRequestInfo(requestInfo);
        setIsLoading(false)
      })
    }).catch(() => setIsLoading(false))
  }, [id]);
  // }, );


  const onClickPreview = (item: any) => {
    if (item.photoUrl) {
      setPreview(item.photoUrl[0].url);
      return;
    }
    setPreview(item);
    return;
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>출고신청(일반)</title>
        </Helmet>
      </HelmetProvider>

      {isPreview
        && (
          <PreviewPopup
            customer={customerInfo}
            shopInfo={shopInfo}
            goods={selected}
            screw={screw}
            onClickClose={() => setIsPreview(false)}
          />
        )
      }

      {isLoading && <Loading/>}

      {popup.isShow
        && (
          <LogisticsNoticePopup
            content={popup.message}
            button={'확인'}
            onClick={() => {
              if (popup.buttonType === 'closed') {
                window.close();
                return;
              }
              setPopup({
                isShow: false,
                message: '',
                buttonType: ''
              })
            }}
          />
        )
      }

      <div className={css(styles.frame)}>
        {preview !== ''
          && (
            <GoodsPreview
              url={preview}
              onClickClose={() => onClickPreview('')}
            />
          )
        }
        <div className={css(styles.top)}>
          <div>
            <div className={css(styles.topCode)}>
              {customerInfo.key}
            </div>
            <div className={css(styles.topName)}>
              {customerInfo.shopName}({customerInfo.locationName})
            </div>
          </div>

          <div className={css(styles.topButtons)}>
            <div
              className={css(
                styles.topPreviewButton,
                styles.topPreSaveButton
              )}
              onClick={() => onClickComplete(false)}
            >
              임시저장
            </div>
            <div
              className={css(styles.topPreviewButton)}
              onClick={() => setIsPreview(true)}
            >
              미리보기
            </div>
            <div
              className={css(styles.topCompleteButton)}
              onClick={() => onClickComplete(true)}
            >
              출고신청서 작성완료
            </div>
          </div>

        </div>
        <div className={css(styles.basicInfoFrame)}>
          <div className={css(styles.basicInfo)}>
            <RequestInfoList
              requestInfo={requestInfo}
              dropdown={dropdown}
              setDropdown={(value: string) => setDropdown(value)}
              setRequestInfo={(value) => setRequestInfo(value)}
            />

            <ShopInfoList
              shopInfo={shopInfo}
              setShopInfo={(value) => setShopInfo(value)}
            />
          </div>

          <GoodsList
            goodsList={goodsList}
            setGoodsList={(value: any) => setGoodsList(value)}
            typeDropdownNumber={typeDropdownNumber}
            setTypeDropdownNumber={(value: number) => setTypeDropdownNumber(value)}
            selected={selected}
            setSelected={(value) => setSelected(value)}
            screw={screw}
            setScrew={(value: string) => setScrew(value)}
            setGoodsFocus={(value: string) => setGoodsFocus(value)}
            onClickPreview={(value) => onClickPreview(value)}
          />

          <GoodsStock
            goodsList={goodsList}
            focusedGoodsType={goodsFocus}
          />

        </div>
      </div>
    </>
  )
}

const styles = CreateBetaStyle({
  frame: {
    width: '83vw',
    height: '95vh',

    borderRadius: '10px',

    backgroundColor: 'white',

    boxSizing: 'border-box',

    fontFamily: 'Pretendard',

    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',

    '@media(max-width: 1800px)': {
      width: '80vw',
    },
  },
  top: {
    width: '100%',
    height: '114px',

    borderBottom: 'solid 1px #E8E8E8',

    padding: '30px',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    boxSizing: 'border-box',
  },
  topCode: {
    fontSize: '18px',
    color: '#999999',
    fontWeight: 600,

    marginBottom: '10px',
  },
  topName: {
    fontSize: '24px',
    fontWeight: 700,
    color: '#2E2E2E',
  },
  topButtons: {
    display: 'flex',
  },
  topPreSaveButton: {
    border: 'solid 2px #666973',
    color: '#666973',
  },
  topPreviewButton: {
    width: '105px',
    height: '48px',

    borderRadius: '10px',
    border: 'solid 2px black',

    fontSize: '17px',
    letterSpacing: '-1%',
    fontWeight: 600,
    color: 'black',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    marginRight: '10px',

    cursor: 'pointer',
  },
  topCompleteButton: {
    width: '175px',
    height: '48px',

    borderRadius: '10px',
    border: 'solid 2px black',

    backgroundColor: 'black',

    fontSize: '17px',
    letterSpacing: '-1%',
    fontWeight: 600,
    color: 'white',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    cursor: 'pointer',
  },
  basicInfoFrame: {
    height: 'calc(100% - 114px)',
    display: 'flex',

    '@media(max-width: 1800px)': {
      overflowX: 'scroll',
      overflowY: 'hidden',

      '::-webkit-scrollbar': {
        height: '14px',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundClip: 'padding-box',
        backgroundColor: '#A8A8A8',
        borderRadius: '15px',
        border: '4px solid transparent',
      },
      '::-webkit-scrollbar-track': {
        padding: '10px',

        background: 'transparent',
      },
    },
  },
  basicInfo: {
    width: '725px',
    height: '100%',

    overflowY: 'scroll',

    paddingLeft: '30px',

    borderRight: 'solid 1px #E8E8E8',

    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: '#A8A8A8',
      borderRadius: '15px',
      border: '3px solid transparent',
    },
    '::-webkit-scrollbar-track': {
      padding: '10px',

      background: 'transparent',
    },

    '@media(max-width: 1800px)': {
      width: '35vw',
      minWidth: '35vw',
    },

    '@media(max-width: 1600px)': {
      width: '37vw',
    },
  },
  requestInfo: {
    width: '665px',

    borderBottom: 'solid 1px #F4F4F4',

    paddingBottom: '30px',
    paddingTop: '30px',

    '@media(max-width: 1800px)': {
      width: '33vw',
    },
  },
})
