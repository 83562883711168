import React from 'react';
import {css} from 'aphrodite';

import {CreateBetaStyle} from '../../shared/CreateStyle';

export const GoodsStock = (
  {
    goodsList,
    focusedGoodsType
  }: {
    goodsList: any[],
    focusedGoodsType: string
  }) => {
  // console.log(goodsList)
  return (
    <div className={css(styles.frame)}>
      <div className={css(styles.title)}>재고 확인</div>
      <div className={css(styles.stockListFrame)}>
        {
          goodsList.map((item1: any, index1: number) =>
            (
              focusedGoodsType === ''
                ? true
                : item1.type === focusedGoodsType
            )
            && item1.type !== '12.나사'
            && (
              <div
                className={css(styles.stockList)}
                key={'stock-type_' + index1}
              >
                <div className={css(styles.stockListTitle)}>
                  {item1.type.split('.')[1]}
                </div>
                <div>
                  {item1.items.map((item2: any, index2: number) => (
                    <div
                      className={css(styles.stockListItem)}
                      key={'stock-type-item_' + index2}
                    >
                      <div className={css(styles.stockListItemNameFrame)}>
                        <div className={css(styles.stockListItemName)}>
                          {item2.item}
                        </div>
                        {
                          item2.holderForm
                          && (
                            <div className={css(styles.stockListItemNameType)}>
                              {item2.holderForm}
                            </div>
                          )
                        }
                      </div>
                      <div className={css(styles.stockListItemAmount)}>
                        {Math.floor(item2.stock)}
                      </div>
                    </div>
                    ))
                  }
                </div>
              </div>
            )
          )
        }
      </div>
    </div>
  )
}

const styles = CreateBetaStyle({
  title: {
    fontSize: '17px',
    color: '#666973',
    fontWeight: 600,

    marginBottom: '20px',
  },
  frame: {
    paddingLeft: '30px',
    paddingTop: '30px',
  },
  stockListFrame: {
    width: '340px',
    height: 'calc(100% - 40px)',

    overflowY: 'scroll',

    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: '#A8A8A8',
      borderRadius: '15px',
      border: '3px solid transparent',
    },
    '::-webkit-scrollbar-track': {
      padding: '10px',

      background: 'transparent',
    },
  },
  stockList: {
    width: '284px',
    marginBottom: '29px',

    color: '#666973',
    fontSize: '15px',
  },
  stockListTitle: {
    width: '100%',

    paddingBottom: '10px',

    borderBottom: 'solid 1px #E8E8E8',
  },
  stockListItem: {
    width: '100%',

    display: 'flex',
    justifyContent: 'space-between',

    marginTop: '16px'
  },
  stockListItemNameFrame: {
    display: 'flex',
    alignItems: 'center',
  },
  stockListItemName: {},
  stockListItemNameType: {
    width: 'auto',
    height: '15px',

    padding: '0px 5px',

    border: 'solid 1px #666973',
    borderRadius: '10px',

    display: 'flex',
    alignItems: 'center',

    fontSize: '9px',
    color: '#666973',
    fontWeight: 600,
    fontFamily: 'Pretendard',

    marginLeft: '3px',

    opacity: 0.6
  },
  stockListItemAmount: {
    textAlign: 'right',

    color: '#333333',
  },
});
