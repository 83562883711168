import axios from 'axios';
import Airtable from "airtable";
import moment from "moment/moment";

import {customerInfoForBasic, customerInfoForLogistics} from '../helpers/LogisticsHelper';

const base = new Airtable(
  {apiKey: process.env.REACT_APP_AIRTABLE_API_KEY!})
  .base(process.env.REACT_APP_AIRTABLE_BASE_ID ?? ''
);

export interface GoodsItemsProps {
  id: string,
  type: string,
  item: string,
  stock: number,
  photoUrl: string,
  isFavorites: boolean,
}

export interface GoodsListProps {
  type: String,
  items: GoodsItemsProps
}

export async function updateStatusToComplete(recordId: string) {
  // await axios.patch(`${process.env.API_URL}/logistics/complete?recordId=${recordId}`);

  const table = base(process.env.REACT_APP_AIRTABLE_BASIC_TABLE_ID!);
  return await table.update([{
    id: recordId,
    fields: {
      [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_STATUS_FIELD_ID!]: '8-1. 출고신청완료',
    }
  }])
}

export async function getCustomerInfo(recordId: string) {
  // const {data} = await axios.get(`${process.env.API_URL}/logistics/customer-info?recordId=${recordId}`);
  // return data;

  const table = base(process.env.REACT_APP_AIRTABLE_BASIC_TABLE_ID!);
  const res: any = await table.select({
      filterByFormula: `RECORD_ID() = '${recordId}'`,
      returnFieldsByFieldId: true,
      maxRecords: 1,
    }
  ).firstPage();
  if (res.length === 0) {
    return;
  }
  const options = await getOptionsByFieldId(
    {
    table: 'basic',
    fieldId: [
      process.env.REACT_APP_AIRTABLE_BASIC_TABLE_REQUEST_TEAM_FIELD_ID!,
      process.env.REACT_APP_AIRTABLE_BASIC_TABLE_RELEASE_TYPE_FIELD_ID!,
      process.env.REACT_APP_AIRTABLE_BASIC_TABLE_INSTALLER_FIELD_ID!,
      process.env.REACT_APP_AIRTABLE_BASIC_TABLE_DELIVERY_TYPE_FIELD_ID!
    ],
  }
  );
  const managerFieldValue = res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_BUSINESS_MANAGER_FIELD_ID!];
  return {
    key: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_KEY_FIELD_ID!],
    date: moment().format('YYYY-MM-DD'),
    requestTeam: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_REQUEST_TEAM_FIELD_ID!],
    requestTeams: options.find((v: any) => v.id === process.env.REACT_APP_AIRTABLE_BASIC_TABLE_REQUEST_TEAM_FIELD_ID!).options.choices,
    manager: managerFieldValue ? managerFieldValue.name : ' ',
    managerPhone: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_MANAGER_PHONE_FIELD_ID!],
    releaseType: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_RELEASE_TYPE_FIELD_ID!],
    releaseTypes: options.find((v: any) => v.id === process.env.REACT_APP_AIRTABLE_BASIC_TABLE_RELEASE_TYPE_FIELD_ID!).options.choices,
    shopName: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHOP_NAME_FIELD_ID!],
    locationName: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_LOCATION_NAME_FIELD_ID!],
    electronType: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_ELECTRON_TYPE_FIELD_ID!],
    shippingAddress: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHIPPING_ADDRESS_FIELD_ID!],
    shopPhone: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHOP_PHONE_FIELD_ID!],
    requestMessage: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_REQUEST_MESSAGE_FIELD_ID!],
    significantMessage: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SIGNIFICANT_MESSAGE_FIELD_ID!],
    installer: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_INSTALLER_FIELD_ID!],
    installDate: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_INSTALL_DATE_FIELD_ID!],
    installers: options.find((v: any) => v.id === process.env.REACT_APP_AIRTABLE_BASIC_TABLE_INSTALLER_FIELD_ID!).options.choices,
    deliveryType: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_DELIVERY_TYPE_FIELD_ID!],
    deliveryTypes: options.find((v: any) => v.id === process.env.REACT_APP_AIRTABLE_BASIC_TABLE_DELIVERY_TYPE_FIELD_ID!).options.choices,
    goods: [
      {
        type: '1.거치대',
        value: [
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_HOLDER_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_HOLDER_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_HOLDER_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_HOLDER_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_THIRD_HOLDER_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_THIRD_HOLDER_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '2.마스터용 거치대',
        value: [
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_MASTER_HOLDER_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_MASTER_HOLDER_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_MASTER_HOLDER_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_MASTER_HOLDER_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '3.케이스',
        value: [
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_CASE_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_CASE_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_CASE_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_CASE_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_THIRD_CASE_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_THIRD_CASE_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '4.태블릿',
        value: [
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_TABLET_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_TABLET_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_TABLET_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_TABLET_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '5.충전기&케이블',
        value: [
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CHARGE_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CHARGE_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CABLE_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CABLE_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '6.공유기',
        value: [
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE1_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE1_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE2_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE2_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE3_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE3_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE4_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE4_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '7.보조배터리',
        value: [
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_BATTERY_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_BATTERY_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_BATTERY_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_BATTERY_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '8.보조배터리 케이스',
        value: [
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_BATTERY_CASE_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_BATTERY_CASE_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_BATTERY_CASE_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_BATTERY_CASE_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '9.카드리더기',
        value: [
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER1_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER1_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER2_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER2_AMOUNT_FIELD_ID!],
          },
          // {
          //   id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER3_FIELD_ID!],
          //   amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER3_AMOUNT_FIELD_ID!],
          // },
        ]
      },
      {
        type: '10.카드리더기 케이스',
        value: [
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE1_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE1_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE2_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE2_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE3_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE3_AMOUNT_FIELD_ID!],
          },
          {
            id: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE4_FIELD_ID!],
            amount: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE4_AMOUNT_FIELD_ID!],
          },
        ]
      },
      {
        type: '12.나사',
        value: [
          {
            item: '긴/짧',
          },
          {
            item: '긴/짧/동/스',
          },
          {
            item: '긴/짧/동/스+피스',
          },
          {
            item: '긴/짧+피스',
          },
          {
            item: '피스',
          },
        ]
      }
    ],
    screw: res[0].fields[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SCREW_FIELD_ID!]
  }
}

export async function getCustomerInfoForPreview(recordId: string) {
  // const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/logistics/customer-preview-info?recordId=${recordId}`);
  // return data;

  const table = base(process.env.REACT_APP_AIRTABLE_BASIC_TABLE_ID!);
  const logisticsTable = base(process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_ID!);

  // 1접수에서 레코드를 찾는다
  const basic = await table.select({
      filterByFormula: `RECORD_ID() = '${recordId}'`,
      returnFieldsByFieldId: true,
      maxRecords: 1,
    }
  ).firstPage();

  // 1접수에서 레코드를 찾지 못하면 2물류에서 조회한다?
  if (basic.length === 0) {
    const logistics = await logisticsTable.select({
        filterByFormula: `RECORD_ID() = '${recordId}'`,
        returnFieldsByFieldId: true,
        maxRecords: 1,
      }
    ).firstPage();

    // 2물류에서도 찾지못하면 return?
    if (logistics.length === 0) {
      return;
    }

    // 2물류에서 찾았을 경우의 로직??
    if (logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_BASIC_RECORD_ID_FIELD_ID!]) {
      const basic = await table.select({
          filterByFormula: `RECORD_ID() = '${logistics[0].fields[process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_BASIC_RECORD_ID_FIELD_ID!]}'`,
          returnFieldsByFieldId: true,
          maxRecords: 1,
        }
      ).firstPage();
      if (basic.length === 0) {
        return;
      }
      return customerInfoForBasic(basic);
    }
    return customerInfoForLogistics(logistics);
  }
  return customerInfoForBasic(basic);
}

// export async function saveCustomerInfo(
//   {recordId, info, goods, isComplete}: { recordId: string, info: any, goods: any, isComplete: boolean }
// ) {
//   return await axios.patch(
//     `${process.env.REACT_APP_API_URL}/logistics/update`,
//     {
//       recordId,
//       info,
//       goods,
//       isComplete,
//     }
//   );
// }

export async function saveCustomerInfo(
  {
    recordId,
    info,
    goods,
    isComplete
  }: {
    recordId: string,
    info: any,
    goods: any,
    isComplete: boolean
  }) {

  const table = base(process.env.REACT_APP_AIRTABLE_BASIC_TABLE_ID!);
  const fields = {
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_REQUEST_TEAM_FIELD_ID!]: info.requestTeam,  //요청부서
    // [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_MANAGER_FIELD_ID!]: info.manager,  //담당자(출고신청서용)
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_MANAGER_PHONE_FIELD_ID!]: info.managerPhone,  //담당연락처(출고신청서용)
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_RELEASE_TYPE_FIELD_ID!]: info.releaseType,  //출고유형
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHOP_NAME_FIELD_ID!]: info.shopName.replace(/ /g, ''),  //상호명
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_LOCATION_NAME_FIELD_ID!]: info.locationName.replace(/ /g, ''),  //지점명
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHIPPING_ADDRESS_FIELD_ID!]: info.shippingAddress,  //배송지
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_DELIVERY_TYPE_FIELD_ID!]: info.deliveryType,  //배송방법
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_INSTALLER_FIELD_ID!]: info.installer,  //설치업체
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHOP_PHONE_FIELD_ID!]: info.shopPhone,  //연락처
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_REQUEST_MESSAGE_FIELD_ID!]: info.requestMessage,  //요청사항
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SIGNIFICANT_MESSAGE_FIELD_ID!]: info.significantMessage,  //물류신청서 특이사항(인쇄용)
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_HOLDER_FIELD_ID!]: goods.holder[0] ? [goods.holder[0].id] : [], //거치대
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_HOLDER_AMOUNT_FIELD_ID!]: goods.holder[0] ? Number(goods.holder[0].amount) : 0, //(거치대1)대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_HOLDER_FIELD_ID!]: goods.holder[1] ? [goods.holder[1].id] : [], //거치대2
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_HOLDER_AMOUNT_FIELD_ID!]: goods.holder[1] ? Number(goods.holder[1].amount) : 0, //(거치대2)대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_THIRD_HOLDER_FIELD_ID!]: goods.holder[2] ? [goods.holder[2].id] : [], //거치대3
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_THIRD_HOLDER_AMOUNT_FIELD_ID!]: goods.holder[2] ? Number(goods.holder[2].amount) : 0, //(거치대3)대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_MASTER_HOLDER_FIELD_ID!]: goods.masterHolder[0] ? [goods.masterHolder[0].id] : [], //마스터용
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_MASTER_HOLDER_AMOUNT_FIELD_ID!]: goods.masterHolder[0] ? Number(goods.masterHolder[0].amount) : 0, //마스터용 거치대수량
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_MASTER_HOLDER_FIELD_ID!]: goods.masterHolder[1] ? [goods.masterHolder[1].id] : [], //마스터용2
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_MASTER_HOLDER_AMOUNT_FIELD_ID!]: goods.masterHolder[1] ? Number(goods.masterHolder[1].amount) : 0, //마스터용 거치대수량2
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_CASE_FIELD_ID!]: goods.case_[0] ? [goods.case_[0].id] : [], //케이스
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_CASE_AMOUNT_FIELD_ID!]: goods.case_[0] ? Number(goods.case_[0].amount) : 0, //(케이스)대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_CASE_FIELD_ID!]: goods.case_[1] ? [goods.case_[1].id] : [], //케이스2
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_CASE_AMOUNT_FIELD_ID!]: goods.case_[1] ? Number(goods.case_[1].amount) : 0, //(케이스)대수2
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_THIRD_CASE_FIELD_ID!]: goods.case_[2] ? [goods.case_[2].id] : [], //케이스3
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_THIRD_CASE_AMOUNT_FIELD_ID!]: goods.case_[2] ? Number(goods.case_[2].amount) : 0, //(케이스3)대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_TABLET_FIELD_ID!]: goods.tablet[0] ? [goods.tablet[0].id] : [], //태블릿
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_TABLET_AMOUNT_FIELD_ID!]: goods.tablet[0] ? Number(goods.tablet[0].amount) : 0, //(태블릿)대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_TABLET_FIELD_ID!]: goods.tablet[1] ? [goods.tablet[1].id] : [], //태블릿2
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_TABLET_AMOUNT_FIELD_ID!]: goods.tablet[1] ? Number(goods.tablet[1].amount) : 0, //(태블릿2)대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CHARGE_FIELD_ID!]: goods.charge[0] ? [goods.charge[0].id] : [], //충전기
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CHARGE_AMOUNT_FIELD_ID!]: goods.charge[0] ? Number(goods.charge[0].amount) : 0, //(충전기)대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CABLE_FIELD_ID!]: goods.cable[0] ? [goods.cable[0].id] : [], //케이블
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CABLE_AMOUNT_FIELD_ID!]: goods.cable[0] ? Number(goods.cable[0].amount) : 0, //(케이블)대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE1_FIELD_ID!]: goods.share[0] ? [goods.share[0].id] : [], //공유기
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE1_AMOUNT_FIELD_ID!]: goods.share[0] ? Number(goods.share[0].amount) : 0, //(공유기)대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE2_FIELD_ID!]: goods.share[1] ? [goods.share[1].id] : [], //공유기2
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE2_AMOUNT_FIELD_ID!]: goods.share[1] ? Number(goods.share[1].amount) : 0, //(공유기2)대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE3_FIELD_ID!]: goods.share[2] ? [goods.share[2].id] : [], //공유기3
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE3_AMOUNT_FIELD_ID!]: goods.share[2] ? Number(goods.share[2].amount) : 0, //(공유기3)대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE4_FIELD_ID!]: goods.share[3] ? [goods.share[3].id] : [], //공유기4
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SHARE4_AMOUNT_FIELD_ID!]: goods.share[3] ? Number(goods.share[3].amount) : 0, //(공유기4)대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_BATTERY_FIELD_ID!]: goods.battery[0] ? [goods.battery[0].id] : [], //보조배터리
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_BATTERY_AMOUNT_FIELD_ID!]: goods.battery[0] ? Number(goods.battery[0].amount) : 0, //보조배터리 대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_BATTERY_FIELD_ID!]: goods.battery[1] ? [goods.battery[1].id] : [], //보조배터리2
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_BATTERY_AMOUNT_FIELD_ID!]: goods.battery[1] ? Number(goods.battery[1].amount) : 0, //보조배터리2 대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_BATTERY_CASE_FIELD_ID!]: goods.batteryCase[0] ? [goods.batteryCase[0].id] : [], //보조배터리 케이스
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_FIRST_BATTERY_CASE_AMOUNT_FIELD_ID!]: goods.batteryCase[0] ? Number(goods.batteryCase[0].amount) : 0, //보조배터리케이스 대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_BATTERY_CASE_FIELD_ID!]: goods.batteryCase[1] ? [goods.batteryCase[1].id] : [], //보조배터리 케이스2
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SECOND_BATTERY_CASE_AMOUNT_FIELD_ID!]: goods.batteryCase[1] ? Number(goods.batteryCase[1].amount) : 0, //보조배터리케이스2 대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER1_FIELD_ID!]: goods.card[0] ? [goods.card[0].id] : [], //카드리더기1
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER1_AMOUNT_FIELD_ID!]: goods.card[0] ? Number(goods.card[0].amount) : 0, //(카드리더기1) 대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER2_FIELD_ID!]: goods.card[1] ? [goods.card[1].id] : [], //카드리더기2
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER2_AMOUNT_FIELD_ID!]: goods.card[1] ? Number(goods.card[1].amount) : 0, //(카드리더기2) 대수
    // [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER3_FIELD_ID!]: goods.card[2] ? [goods.card[2].id] : [], //카드리더기3
    // [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER3_AMOUNT_FIELD_ID!]: goods.card[2] ? Number(goods.card[2].amount) : 0, //(카드리더기3) 대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE1_FIELD_ID!]: goods.cardCase[0] ? [goods.cardCase[0].id] : [], //카드리더기케이스1
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE1_AMOUNT_FIELD_ID!]: goods.cardCase[0] ? Number(goods.cardCase[0].amount) : 0, //(카드리더기케이스1) 대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE2_FIELD_ID!]: goods.cardCase[1] ? [goods.cardCase[1].id] : [], //카드리더기케이스2
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE2_AMOUNT_FIELD_ID!]: goods.cardCase[1] ? Number(goods.cardCase[1].amount) : 0, //(카드리더기케이스2) 대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE3_FIELD_ID!]: goods.cardCase[2] ? [goods.cardCase[2].id] : [], //카드리더기케이스3
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE3_AMOUNT_FIELD_ID!]: goods.cardCase[2] ? Number(goods.cardCase[2].amount) : 0, //(카드리더기케이스3) 대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE4_FIELD_ID!]: goods.cardCase[3] ? [goods.cardCase[3].id] : [], //카드리더기케이스4
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_CARD_READER_CASE4_AMOUNT_FIELD_ID!]: goods.cardCase[3] ? Number(goods.cardCase[3].amount) : 0, //(카드리더기케이스4) 대수
    [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_SCREW_FIELD_ID!]: goods.screw === '' ? null : goods.screw, //나사(물류)
  }
  const data = await table.update([{
    id: recordId,
    fields: isComplete ? {
        [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_WRITE_DATE_FIELD_ID!]: info.date,  //출고신청서 작성일자
        ...fields,
      }
      : fields
  }]);
  return data[0].id
}

// export async function getGoodsNameByRecordId(recordId: string): Promise<string> {
//   if (recordId === '') {
//     return '';
//   }
//   const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/logistics/goods-name?recordId=${recordId}`);
//   return data;
// }

export async function getGoodsNameByRecordId(recordId: string): Promise<string> {
  if (recordId === '') {
    return '';
  }
  const table = base(process.env.REACT_APP_AIRTABLE_GOODS_TABLE_ID!);
  const res = await table.select({
      filterByFormula: `RECORD_ID() = '${recordId}'`,
      returnFieldsByFieldId: true,
      maxRecords: 1,
    }
  ).firstPage()
  return String(res[0].fields[process.env.REACT_APP_AIRTABLE_GOODS_TABLE_NAME_FIELD_ID!]);
}

export async function getGoodsNameByRecordId_(recordId: string, goods: any): Promise<any> {
  let item = {};
  goods.map((v: any) => {
    const res = v.items.find((item: any) => item.id === recordId);
    if (res) {
      item = res;
    }
  })
  return item;
}

// export async function getGoodsTypes() {
//   const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/logistics/goods`);
//   return data;
// }

export async function getGoodsTypes(): Promise<GoodsListProps[]> {
  const table = base(process.env.REACT_APP_AIRTABLE_GOODS_TABLE_ID!)
  const allList = await table.select({returnFieldsByFieldId: true}).all();
  const res = allList.map((item: any, index: number) => {
    if (item.fields['fldrD8S1dJTXYOPhi'] === '11.ISTN') {
      return;
    }
    if (!item.fields[process.env.REACT_APP_AIRTABLE_GOODS_TABLE_DEV_TYPE_FIELD_ID!]) {
      return;
    }
    return {
      id: item.id,
      type: item.fields[process.env.REACT_APP_AIRTABLE_GOODS_TABLE_DEV_TYPE_FIELD_ID!],
      item: item.fields[process.env.REACT_APP_AIRTABLE_GOODS_TABLE_NAME_FIELD_ID!],
      stock: item.fields[process.env.REACT_APP_AIRTABLE_GOODS_TABLE_STOCK_FIELD_ID!],
      photoUrl: item.fields[process.env.REACT_APP_AIRTABLE_GOODS_TABLE_IMAGE_FIELD_ID!],
      isFavorites: item.fields[process.env.REACT_APP_AIRTABLE_GOODS_TABLE_FAVORITE_FIELD_ID!] ? true : false,
      holderForm: item.fields[process.env.REACT_APP_AIRTABLE_GOODS_TABLE_HOLDER_FORM_FIELD_ID!],
      color: item.fields[process.env.REACT_APP_AIRTABLE_GOODS_TABLE_ESTIMATE_COLOR_FIELD_ID!],
    }
  }).filter((v: any) => v != null);

  //TODO: 다시보기
  const sortedRes = res.reduce((acc: any, cur: any) => {
    const type = cur.type;
    const existingItem = acc.find((item: any) => item.type === type);

    if (existingItem) {
      existingItem.items.push(cur);
    } else {
      acc.push({type, items: [cur]});
    }
    return acc;
  }, []);

  sortedRes.map((v: any) => {
    return {
      type: v.type,
      items: v.items.sort((a: any, b: any) => {
        return a.item.localeCompare(b.item);
      }).sort((a: any, b: any) => {
        return b.isFavorites - a.isFavorites;
      })
    }
  });

  sortedRes.push({
    type: '12.나사',
    items: [
      {
        type: '12.나사',
        item: '긴/짧',
      },
      {
        type: '12.나사',
        item: '긴/짧/동/스',
      },
      {
        type: '12.나사',
        item: '긴/짧/동/스+피스',
      },
      {
        type: '12.나사',
        item: '긴/짧+피스',
      },
      {
        type: '12.나사',
        item: '피스',
      },
    ]
  });

  return sortedRes.sort(function (a: any, b: any) {
    const aNum = parseInt(a.type.split('.')[0]);
    const bNum = parseInt(b.type.split('.')[0]);
    return aNum - bNum;
  });
}


export async function getOptionsByFieldId(
  {
    table,
    fieldId
  }: {
    table: string,
    fieldId: string[]
  }) {

  const url = `https://api.airtable.com/v0/meta/bases/${process.env.REACT_APP_AIRTABLE_BASE_ID!}/tables`;
  const res = await axios.get(
    url,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY!}`,
      }
    }
  );

  let tableId = '';
  switch (table) {
    case 'basic':
      tableId = process.env.REACT_APP_AIRTABLE_BASIC_TABLE_ID!;
      break;
    case 'logistics':
      tableId = process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_ID!
      break;
    default:
      return tableId = process.env.REACT_APP_AIRTABLE_BASIC_TABLE_ID!;
  }

  return res.data.tables
    .find((v: any) => v.id === tableId).fields
    .filter((v: any) => fieldId.includes(v.id));
}

export async function createCustomerInfoInLogisticsTable(
  {
    info,
    goods
  }: {
    info: any,
    goods: any
  }) {
  // const url = process.env.REACT_APP_API_URL + '/logistics/create';
  // const res = await axios.post(url, {info, goods});
  // return res.data;

  const table = base(process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_ID!);
  const data = await table.create([{
    fields: {
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_WRITE_DATE_FIELD_ID!]: info.date,  //출고신청서 작성일자
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_REQUEST_TEAM_FIELD_ID!]: info.requestTeam,  //요청부서
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_MANAGER_FIELD_ID!]: info.manager,  //담당자(출고신청서용)
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_MANAGER_PHONE_FIELD_ID!]: info.managerPhone,  //담당연락처(출고신청서용)
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_RELEASE_TYPE_FIELD_ID!]: info.releaseType,  //출고유형
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHOP_NAME_FIELD_ID!]: info.shopName.replace(/ /g, ''),  //상호명
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_LOCATION_NAME_FIELD_ID!]: info.locationName.replace(/ /g, ''),  //지점명
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHIPPING_ADDRESS_FIELD_ID!]: info.shippingAddress,  //배송지
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHOP_PHONE_FIELD_ID!]: info.shopPhone,  //연락처
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_DELIVERY_TYPE_FIELD_ID!]: info.deliveryType,  //배송방법
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_INSTALLER_FIELD_ID!]: info.installer,  //설치업체
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_REQUEST_MESSAGE_FIELD_ID!]: info.requestMessage,  //요청사항
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SIGNIFICANT_MESSAGE_FIELD_ID!]: info.significantMessage,  //물류신청서 특이사항(인쇄용)
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_HOLDER_FIELD_ID!]: goods.holder[0] ? [goods.holder[0].id] : [], //거치대
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_HOLDER_AMOUNT_FIELD_ID!]: goods.holder[0] ? Number(goods.holder[0].amount) : 0, //(거치대1)대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_HOLDER_FIELD_ID!]: goods.holder[1] ? [goods.holder[1].id] : [], //거치대2
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_HOLDER_AMOUNT_FIELD_ID!]: goods.holder[1] ? Number(goods.holder[1].amount) : 0, //(거치대2)대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_THIRD_HOLDER_FIELD_ID!]: goods.holder[2] ? [goods.holder[2].id] : [], //거치대3
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_THIRD_HOLDER_AMOUNT_FIELD_ID!]: goods.holder[2] ? Number(goods.holder[2].amount) : 0, //(거치대3)대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_MASTER_HOLDER_FIELD_ID!]: goods.masterHolder[0] ? [goods.masterHolder[0].id] : [], //마스터용
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_MASTER_HOLDER_AMOUNT_FIELD_ID!]: goods.masterHolder[0] ? Number(goods.masterHolder[0].amount) : 0, //마스터용 거치대수량
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_MASTER_HOLDER_FIELD_ID!]: goods.masterHolder[1] ? [goods.masterHolder[1].id] : [], //마스터용2
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_MASTER_HOLDER_AMOUNT_FIELD_ID!]: goods.masterHolder[1] ? Number(goods.masterHolder[1].amount) : 0, //마스터용 거치대수량2
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_CASE_FIELD_ID!]: goods.case_[0] ? [goods.case_[0].id] : [], //케이스
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_CASE_AMOUNT_FIELD_ID!]: goods.case_[0] ? Number(goods.case_[0].amount) : 0, //(케이스)대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_CASE_FIELD_ID!]: goods.case_[1] ? [goods.case_[1].id] : [], //케이스2
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_CASE_AMOUNT_FIELD_ID!]: goods.case_[1] ? Number(goods.case_[1].amount) : 0, //(케이스)대수2
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_THIRD_CASE_FIELD_ID!]: goods.case_[2] ? [goods.case_[2].id] : [], //케이스3
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_THIRD_CASE_AMOUNT_FIELD_ID!]: goods.case_[2] ? Number(goods.case_[2].amount) : 0, //(케이스3)대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_TABLET_FIELD_ID!]: goods.tablet[0] ? [goods.tablet[0].id] : [], //태블릿
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_TABLET_AMOUNT_FIELD_ID!]: goods.tablet[0] ? Number(goods.tablet[0].amount) : 0, //(태블릿)대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_TABLET_FIELD_ID!]: goods.tablet[1] ? [goods.tablet[1].id] : [], //태블릿2
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_TABLET_AMOUNT_FIELD_ID!]: goods.tablet[1] ? Number(goods.tablet[1].amount) : 0, //(태블릿2)대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CHARGE_FIELD_ID!]: goods.charge[0] ? [goods.charge[0].id] : [], //충전기
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CHARGE_AMOUNT_FIELD_ID!]: goods.charge[0] ? Number(goods.charge[0].amount) : 0, //(충전기)대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CABLE_FIELD_ID!]: goods.cable[0] ? [goods.cable[0].id] : [], //케이블
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CABLE_AMOUNT_FIELD_ID!]: goods.cable[0] ? Number(goods.cable[0].amount) : 0, //(케이블)대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE1_FIELD_ID!]: goods.share[0] ? [goods.share[0].id] : [], //공유기
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE1_AMOUNT_FIELD_ID!]: goods.share[0] ? Number(goods.share[0].amount) : 0, //(공유기)대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE2_FIELD_ID!]: goods.share[1] ? [goods.share[1].id] : [], //공유기2
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE2_AMOUNT_FIELD_ID!]: goods.share[1] ? Number(goods.share[1].amount) : 0, //(공유기2)대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE3_FIELD_ID!]: goods.share[2] ? [goods.share[2].id] : [], //공유기3
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE3_AMOUNT_FIELD_ID!]: goods.share[2] ? Number(goods.share[2].amount) : 0, //(공유기3)대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE4_FIELD_ID!]: goods.share[3] ? [goods.share[3].id] : [], //공유기4
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SHARE4_AMOUNT_FIELD_ID!]: goods.share[3] ? Number(goods.share[3].amount) : 0, //(공유기4)대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_BATTERY_FIELD_ID!]: goods.battery[0] ? [goods.battery[0].id] : [], //보조배터리
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_BATTERY_AMOUNT_FIELD_ID!]: goods.battery[0] ? Number(goods.battery[0].amount) : 0, //보조배터리 대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_BATTERY_FIELD_ID!]: goods.battery[1] ? [goods.battery[1].id] : [], //보조배터리2
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_BATTERY_AMOUNT_FIELD_ID!]: goods.battery[1] ? Number(goods.battery[1].amount) : 0, //보조배터리2 대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_BATTERY_CASE_FIELD_ID!]: goods.batteryCase[0] ? [goods.batteryCase[0].id] : [], //보조배터리 케이스
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_FIRST_BATTERY_CASE_AMOUNT_FIELD_ID!]: goods.batteryCase[0] ? Number(goods.batteryCase[0].amount) : 0, //보조배터리케이스 대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_BATTERY_CASE_FIELD_ID!]: goods.batteryCase[1] ? [goods.batteryCase[1].id] : [], //보조배터리 케이스2
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SECOND_BATTERY_CASE_AMOUNT_FIELD_ID!]: goods.batteryCase[1] ? Number(goods.batteryCase[1].amount) : 0, //보조배터리케이스2 대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER1_FIELD_ID!]: goods.card[0] ? [goods.card[0].id] : [], //카드리더기1
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER1_AMOUNT_FIELD_ID!]: goods.card[0] ? Number(goods.card[0].amount) : 0, //(카드단말기1) 대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER2_FIELD_ID!]: goods.card[1] ? [goods.card[1].id] : [], //카드리더기2
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER2_AMOUNT_FIELD_ID!]: goods.card[1] ? Number(goods.card[1].amount) : 0, //(카드단말기2) 대수
      // [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER3_FIELD_ID!]: goods.card[2] ? [goods.card[2].id] : [], //카드리더기3
      // [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER3_AMOUNT_FIELD_ID!]: goods.card[2] ? Number(goods.card[2].amount) : 0, //(카드단말기3) 대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE1_FIELD_ID!]: goods.cardCase[0] ? [goods.cardCase[0].id] : [], //카드리더기케이스1
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE1_AMOUNT_FIELD_ID!]: goods.cardCase[0] ? Number(goods.cardCase[0].amount) : 0, //(카드리더기케이스1) 대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE2_FIELD_ID!]: goods.cardCase[1] ? [goods.cardCase[1].id] : [], //카드리더기케이스2
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE2_AMOUNT_FIELD_ID!]: goods.cardCase[1] ? Number(goods.cardCase[1].amount) : 0, //(카드리더기케이스2) 대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE3_FIELD_ID!]: goods.cardCase[2] ? [goods.cardCase[2].id] : [], //카드리더기케이스3
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE3_AMOUNT_FIELD_ID!]: goods.cardCase[2] ? Number(goods.cardCase[2].amount) : 0, //(카드리더기케이스3) 대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE4_FIELD_ID!]: goods.cardCase[3] ? [goods.cardCase[3].id] : [], //카드리더기케이스4
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_CARD_READER_CASE4_AMOUNT_FIELD_ID!]: goods.cardCase[3] ? Number(goods.cardCase[3].amount) : 0, //(카드리더기케이스4) 대수
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_SCREW_FIELD_ID!]: goods.screw === '' ? null : goods.screw, //나사(물류)
      [process.env.REACT_APP_AIRTABLE_LOGISTICS_TABLE_STATUS_FIELD_ID!]: '8-1. 출고신청완료',
    }
  }]);
  return data[0].id
}
