import {css} from 'aphrodite';
import * as _ from 'lodash';
import React from 'react';

import {Image} from '../../shared/components/Image';
import {SelectItem} from './SelectItem';
import {CreateBetaStyle} from '../../shared/CreateStyle';

import {
  addIsErrorInObject,
  addIsSelectedInObject
} from '../helpers/LogisticsHelper';

import Plus from '../../../assets/images/svgs/plus.svg';
import Delete from '../../../assets/images/svgs/delete.svg';

export const GoodsList = (
  {
    goodsList,
    setGoodsList,
    typeDropdownNumber,
    setTypeDropdownNumber,
    selected,
    setSelected,
    screw,
    setScrew,
    setGoodsFocus,
    onClickPreview,
  }: {
    goodsList: any,
    setGoodsList: (value: any) => void,
    typeDropdownNumber: number,
    setTypeDropdownNumber: (value: number) => void,
    selected: any[],
    setSelected: (value: any) => void,
    screw: string,
    setScrew: (value: string) => void,
    setGoodsFocus: (value: string) => void,
    onClickPreview: (value: any) => void
  }) => {

  // console.log(goodsList)
  // console.log(selected)

  return (
    <div className={css(styles.goodsInfo)}>
      <div className={css(styles.title)}>물류 정보</div>
      <div className={css(styles.goodsListsBox)}>

        {goodsList.map((item: any, index: number) => (
          <div
            className={css(styles.goodsListFrame)}
            key={'goods-info_' + index}
          >
            <div className={css(styles.goodsList)}>
              <div className={css(styles.goodsListTitle)}>
                {item.type.split('.')[1]}
              </div>
              <div className={css(styles.goodsListHr)}/>
              <div
                className={css(styles.goodsListAdd)}
                onClick={() => {
                  if (index + 1 === typeDropdownNumber) {
                    setTypeDropdownNumber(0);
                    return;
                  }
                  setTypeDropdownNumber(index + 1)
                }}
              >
                <Image
                  src={Plus}
                  alt={'plus'}
                  className={css(styles.goodsListAddImg)}
                />
              </div>
              {index === (typeDropdownNumber - 1)
                && (
                  <SelectItem
                    items={item.items}
                    isUp={index > 6}
                    onClickPreview={(item: any) => onClickPreview(item)}
                    onClickSelected={(
                      {
                        id,
                        name,
                        type,
                        form,
                        color
                      }: {
                        id: string,
                        name: string,
                        type: string,
                        form?: string,
                        color?: string
                      }) => {

                        if (type === '12.나사') {
                          setScrew(name);
                          setTypeDropdownNumber(0);
                          return;
                        }
                        // console.log(selected)
                        const sel = _.cloneDeep(selected);
                        // console.log(sel)
                        const isExist = sel.filter((v: any) => (
                            v.type === type && v.title === name
                          )).length > 0;
                        if (isExist) {
                          setTypeDropdownNumber(0);
                          return;
                        }
                        sel.push({
                          id,
                          type,
                          title: name,
                          amount: null,
                          form,
                          color
                        });
                        setSelected(sel);
                        setGoodsList(addIsSelectedInObject({
                          array: goodsList,
                          target: {item: name, type},
                          isSelected: true
                        }))
                        setTypeDropdownNumber(0);
                      }
                    }
                  />
                )
              }
            </div>
            {item.type === '12.나사'
              ? (
                screw !== ''
                  && (
                    <div className={css(styles.goodsListItem)}>
                      {screw}
                      <Image
                        src={Delete}
                        alt={'delete'}
                        className={css(styles.goodsListItemDelete)}
                        onClick={() => setScrew('')}
                      />
                    </div>
                  )
              )
              : (
                selected.map((item2: any, index: number) => (
                  item.type === item2.type
                    && (
                      <div
                        className={css(styles.goodsListItem)}
                        key={'selected-item_' + index}
                      >
                        <div>{item2.title}</div>
                        <input
                          className={css(
                            styles.goodsListItemAmount,
                            (item2.isError || item2.isZero) && styles.goodsListItemError
                          )}
                          placeholder={'수량을 입력해주세요'}
                          onFocus={() => {
                            setSelected(addIsErrorInObject({
                              array: selected,
                              id: item2.id,
                              add: ['isError', 'isZero'],
                              isAdd: false,
                            }));
                            setGoodsFocus(item.type);
                          }}
                          value={
                            item2.amount === null
                              ? ''
                              : item2.amount
                          }
                          onChange={(e) => {
                            const filterValue = e.target.value.replace(/[^0-9]/g, '');
                            const copy = _.cloneDeep(selected);
                            const temp1 = copy.find((v: any) => v.title === item2.title);
                            temp1.amount = filterValue;
                            setSelected(copy);
                          }}
                        />
                        {item2.isError
                          && (
                            <div className={css(styles.goodsListItemErrorAlert)}>
                              재고부족
                            </div>
                          )
                        }
                        {!item2.isError
                          && (
                            <Image
                              src={Delete}
                              alt={'delete'}
                              className={css(styles.goodsListItemDelete)}
                              onClick={() => {
                                const deleteArray = selected.filter((v: any) => (
                                    v.type !== item.type || v.title !== item2.title
                                  ));
                                setSelected(deleteArray);
                                setGoodsList(addIsSelectedInObject({
                                  array: goodsList,
                                  target: {item: item2.title, type: item.type},
                                  isSelected: false
                                }))
                              }}
                            />
                          )
                        }
                      </div>
                    )
                ))
              )
            }
          </div>
        ))}
      </div>
    </div>
  )
}

const styles = CreateBetaStyle({
  title: {
    fontSize: '17px',
    color: '#666973',
    fontWeight: 600,

    marginBottom: '20px',
  },
  goodsInfo: {
    width: '462px',

    borderRight: 'solid 1px #E8E8E8',

    paddingLeft: '30px',
    paddingTop: '30px',
  },
  goodsListsBox: {
    width: '442px',
    height: 'calc(100% - 40px)',

    overflowY: 'scroll',

    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: '#A8A8A8',
      borderRadius: '15px',
      border: '3px solid transparent',
    },
    '::-webkit-scrollbar-track': {
      padding: '10px',

      background: 'transparent',
    },

    // '@media(max-width: 1800px)': {
    //   height: 'calc',
    // },
  },
  goodsListFrame: {
    width: '402px',

    marginBottom: '24px',

    boxSizing: 'border-box',
  },
  goodsList: {
    marginBottom: '12px',

    display: 'flex',
    alignItems: 'center',

    position: 'relative',
  },
  goodsListTitle: {
    width: '115px',
    height: '28px',

    borderRadius: '100px',

    marginRight: '10px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    color: '#333333',
    fontSize: '13px',
    letterSpacing: '-1%',
    fontWeight: 600,

    backgroundColor: '#F1F1F1',
  },
  goodsListHr: {
    width: '225px',
    borderTop: 'solid 1px #E8E8E8',

    marginRight: '20px',
  },
  goodsListAdd: {
    width: '32px',
    height: '32px',

    borderRadius: '5px',
    border: '1px solid #D7D7D7',

    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    cursor: 'pointer',
  },
  goodsListAddImg: {
    width: '16px',
    height: '16px',
  },
  goodsListItem: {
    width: '402px',
    height: '42px',

    borderRadius: '5px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    paddingLeft: '10px',
    paddingRight: '3px',
    marginBottom: '5px',

    color: '#666973',
    fontSize: '15px',
    fontWeight: 500,

    boxSizing: 'border-box',

    position: 'relative',

    ':hover': {
      backgroundColor: '#FBFBFB',
    }
  },
  goodsListItemAmount: {
    width: '145px',
    height: '36px',

    paddingLeft: '10px',

    borderRadius: '5px',

    border: 'none',

    backgroundColor: 'transparent',

    ':focus': {
      outline: '1.5px solid #999999',
    },
    ':placeholder': {
      color: '#999999',
    },
    '::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    }
  },
  goodsListItemError: {
    border: '1.5px solid #E20F23'
  },
  goodsListItemErrorAlert: {
    width: '42px',
    height: '16px',

    borderRadius: '10px',

    backgroundColor: '#E20F23',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    fontSize: '10px',
    fontWeight: 700,
    color: 'white',
    letterSpacing: '-0.1%',

    position: 'absolute',
    right: '6px',
  },
  goodsListItemDelete: {
    width: '20px',
    height: '20px',

    position: 'absolute',
    right: '12px',

    cursor: 'pointer',
  },
})
