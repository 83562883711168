import {css} from 'aphrodite';
import React from 'react';

import {Image} from '../../shared/components/Image';
import {CreateBetaStyle} from '../../shared/CreateStyle';
import {getTime} from '../utils/DateTimeUtils';
import {
  AddContentSection,
  BasicContentSection,
  ButtonSection,
  EmphasizeSection,
  HighlightSections,
  ImageSections,
  ItemListSection,
  ItemTitleSections
} from './Sections';

import {ITemplateInfo} from '../services/AlimtalkService';

import Profile from '../../../assets/images/svgs/kakao-profile.svg';
import Kakao from '../../../assets/images/pngs/kakao-circle.png';

export const AlimtalkPreview = ({template}: {template: ITemplateInfo}) => {
  return (
    <div className={css(styles.frame)}>
      <div className={css(styles.profile)}>
        <Image src={Profile} alt={'torder-kakao'}/>
      </div>
      <div className={css(styles.alimtalkBox)}>
        <div className={css(styles.alimtalkBoxName)}>
          {template.channelName}
        </div>
        <div className={css(styles.alimtalkBoxView)}>
          <Image src={Kakao} alt={'kakao'} className={css(styles.alimtalkBoxViewKakao)}/>
          <div className={css(styles.alimtalkBoxViewTime)}>{getTime()}</div>
          <div className={css(styles.alimtalkBoxViewTop)}>알림톡 도착</div>
          {template.imageURL && ImageSections(template.imageURL)}
          <div className={css(styles.alimtalkBoxViewContent)}>
            {template.emphasizeTitle && EmphasizeSection({
              title: template.emphasizeTitle,
              subTitle: template.emphasizeSubtitle
            })}
            {template.header && ItemTitleSections(template.header)}
            {template.highlight?.title && HighlightSections(template.highlight)}
            {template.items?.list.length > 0 && ItemListSection(template.items.list, template.items.summary)}
            {template.content && BasicContentSection(template.content)}
            {template.extra && AddContentSection(template.extra)}
            {template.buttons && ButtonSection(template.buttons)}
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = CreateBetaStyle({
  frame: {
    display: 'flex',

    paddingLeft: '16px',

    transformOrigin: '0 0',
    scale: '0.9',
  },
  profile: {
    width: '40px',
    height: '40px',

    borderRadius: '15px',

    backgroundColor: 'white',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginRight: '13px',
  },
  alimtalkBox: {
    paddingTop: '5px',
  },
  alimtalkBoxName: {
    color: '#444444',
    fontSize: '12px',
    fontWeight: 600,

    marginBottom: '11px',
  },
  alimtalkBoxView: {
    width: '230px',
    height: 'auto',

    paddingBottom: '11px',

    backgroundColor: 'white',

    borderRadius: '5px',

    position: 'relative',
  },
  alimtalkBoxViewKakao: {
    width: '30px',
    height: '30px',

    position: 'absolute',
    right: '-10px',
    top: '-10px',
  },
  alimtalkBoxViewTime: {
    position: 'absolute',
    left: '239px',
    bottom: '5px',

    color: '#777777',
    fontSize: '11px',
    fontWeight: 600,

    whiteSpace: 'pre',
  },
  alimtalkBoxViewTop: {
    width: '100%',
    height: '35px',

    backgroundColor: '#FEE500',

    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',

    color: '#444444',
    fontSize: '11px',
    fontWeight: 600,

    display: 'flex',
    alignItems: 'center',

    paddingLeft: '14px',

    boxSizing: 'border-box',
  },
  alimtalkBoxViewContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '15px'
  },
})
