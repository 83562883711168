import React from 'react';
import {css} from 'aphrodite';

import {Image} from '../../shared/components/Image';
import {CreateBetaStyle} from '../../shared/CreateStyle';

export const ImageSections = (src: string) => {
  return (
    <div className={css(styles.imgFrame)}>
      <Image src={src} alt={'img'} className={css(styles.imgStyle)}/>
    </div>
  )
}

export const BasicContentSection = (content: string) => {
  return (
    <div className={css(styles.basicContentFrame)}>
      {content}
    </div>
  )
}

export const AddContentSection = (content: string) => {
  return (
    <div className={css(styles.addContentFrame)}>
      {content}
    </div>
  )
}

export const ButtonSection = (buttons: { buttonName: string }[]) => {
  return (
    <div className={css(styles.buttonFrame)}>
      {buttons.map((item, index) =>
        <div className={css(styles.buttonStyles, index !== (buttons.length-1) && styles.buttonMarginBottom)} key={'buttons_' + index}>
          {item.buttonName}
        </div>
      )}
    </div>
  )
}

export const ItemListSection = (itemList: { title: string, description: string }[], summary: { title: string, description: string} | undefined) => {
  return (
    <div className={css(styles.itemListFrame)}>
      {itemList.map(({title, description}, index) =>
        <div className={css(styles.itemListList, index !== itemList.length && styles.itemListListMarginBottom)} key={'item-list_' + index}>
          <div className={css(styles.itemListListTitle)}>{title}</div>
          <div>{description}</div>
        </div>
      )}
      {summary?.title != null && (
        <div className={css(styles.itemListList, styles.itemListListSummary)}>
          <div className={css(styles.itemListListTitle)}>{summary.title}</div>
          <div className={css(styles.itemListListDescriptionSummary)}>{summary.description}</div>
        </div>
      )}
    </div>
  )
}

export const ItemTitleSections = (value: string) => {
  return (
    <div className={css(styles.itemTitleFrame)}>
      {value}
    </div>
  )
}

export const HighlightSections = ({title, description}: { title: string, description: string }) => {
  return (
    <div className={css(styles.highlightFrame)}>
      <div className={css(styles.highlightTitle)}>{title}</div>
      <div className={css(styles.highlightDescription)}>{description}</div>
    </div>
  )
}

export const EmphasizeSection = ({title, subTitle}: {title: string, subTitle: string}) => {
  return (
    <div className={css(styles.emphasizeFrame)}>
      <div className={css(styles.emphasizeSubtitle)}>{subTitle}</div>
      <div className={css(styles.emphasizeTitle)}>{title}</div>
    </div>
  )
}
const styles = CreateBetaStyle({
  emphasizeFrame: {
    width: '210px',

    fontSize: '14px',
    color: '#333333',

    paddingBottom: '10px',
    marginBottom: '12px',

    borderBottom: 'solid 1px rgba(127, 127, 127, 0.5)',
  },
  emphasizeSubtitle: {
    marginBottom: '4px',
  },
  emphasizeTitle: {
    fontWeight: 700,
    fontSize: '17px',

  },
  highlightFrame: {
    width: '210px',

    paddingBottom: '10px',
    marginBottom: '12px',

    borderBottom: 'solid 1px rgba(127, 127, 127, 0.5)',
  },
  highlightTitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#333333',

    marginBottom: '5px',
  },
  highlightDescription: {
    fontSize: '13px',
    color: '#7F7F7F',
  },
  imgFrame: {
    width: '100%',
    height: 'auto',

    // marginBottom: '6px',
  },
  imgStyle: {
    width: '100%',
    height: '100%',

    objectFit: 'contain',
  },
  basicContentFrame: {
    width: '210px',

    whiteSpace: 'pre-wrap',
    // wordBreak: 'keep-all',

    boxSizing: 'border-box',

    marginBottom: '7px',

    fontSize: '13px',
    fontWeight: 500,
    color: '#333333',
    lineHeight: '18px',
  },
  addContentFrame: {
    width: '210px',

    marginBottom: '13px',
    // marginTop: '10px',

    fontSize: '12px',
    fontWeight: 500,
    color: '#777777',
    whiteSpace: 'pre-wrap',
    lineHeight: '18px',
  },
  buttonFrame: {
    // display: 'flex',
  },
  buttonStyles: {
    width: '210px',
    height: '34px',

    backgroundColor: '#D9D9D9',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    // marginBottom: '10px',

    fontSize: '11px',
    fontWeight: 600,
    color: '#333333',

    borderRadius: '3px',
  },
  itemListFrame: {
    width: '210px',

    // marginLeft: '14px',
    marginBottom: '10px',

    boxSizing: 'border-box',

    borderBottom: 'solid 1px rgba(127, 127, 127, 0.5)',
  },
  itemListList: {
    display: 'flex',

    fontSize: '12px',
    fontWeight: 600,
    color: '#333333',

    // marginBottom: '10px',
  },
  itemListListMarginBottom: {
    marginBottom: '10px',
  },
  itemListListSummary: {
    paddingTop: '4px',
    marginBottom: '10px',
  },
  buttonMarginBottom: {
    marginBottom: '10px',
  },
  itemListListTitle: {
    width: '68px',

    color: '#7F7F7F',
  },
  itemListListDescriptionSummary: {
    fontSize: '15px',
  },
  itemTitleFrame: {
    width: '210px',

    paddingBottom: '10px',
    marginBottom: '12px',

    borderBottom: 'solid 1px rgba(127, 127, 127, 0.5)',

    fontSize: '15px',
    fontWeight: 600,
    color: '#333333',

    boxSizing: 'border-box',
  }
})
