import React from 'react';
import {css} from 'aphrodite';

import {CreateBetaStyle} from '../shared/CreateStyle';
import {Navigatior} from './components/Navigator';
import {BasicForm} from './components/BasicForm';
import {AdditionalForm} from './components/AdditionalForm';

export const LogisticsMain = () => {
  const path = window.location.pathname;
  const type = path.includes('create') ? 'create' : 'basic';

  return (
    <div className={css(styles.main)}>
      <Navigatior type={type}/>
      {type === 'basic'
        ? <BasicForm/>
        : <AdditionalForm/>
      }
    </div>
  )
}

const styles = CreateBetaStyle({
  main: {
    width: '100%',
    height: '100vh',

    paddingLeft: '272px',

    backgroundColor: '#F8F9FD',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    boxSizing: 'border-box',

    fontFamily: 'Pretendard',

    '@media(max-width: 1800px)': {
      paddingLeft: '242px',
    },
  }
})
