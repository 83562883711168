import Airtable from 'airtable';
import * as _ from 'lodash';
import axios from 'axios';

const baseTorder = new Airtable({apiKey: process.env.REACT_APP_AIRTABLE_API_KEY!}).base(process.env.REACT_APP_AIRTABLE_BASE_ID!);

interface ITemplate {
  templateCode: string,
  beforeStatus: string,
  afterStatus: string,
  value?: string[],
}

export interface ITemplateInfo {
  content: string,
  buttons: {
    buttonName: string,
  }[],
  extra: string,
  header: string,
  emphasizeTitle: string,
  emphasizeSubtitle: string,
  highlight: {
    title: string,
    description: string,
  },
  items: {
    list: {
      title: string,
      description: string,
    }[],
    summary: {
      title: string,
      description: string,
    },
  }
  imageURL: string,
  channelName: string,
}

export interface IAlimtalkInputProps {
  name: string,
  phone: string,
  code: string,
  afterStatus: string,
  template: ITemplateInfo
  values: string[],
}

export async function getTemplateList() {
  const tableAlimtalkTemplate = baseTorder(process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_ID!);
  const array = await tableAlimtalkTemplate.select({returnFieldsByFieldId: true}).all();
  return array.map((v: any) => {
    return {
      code: v.fields[process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_TEMPLATE_CODE_FIELD_ID!],
      beforeStatus: v.fields[process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_BEFORE_STATUS_FIELD_ID!],
      afterStatus: v.fields[process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_AFTER_STATUS_FIELD_ID!],
      value: v.fields[process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_TEMPLATE_VALUE_FIELD_ID!] ? String(v.fields[process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_TEMPLATE_VALUE_FIELD_ID!]).split(',').map((v: any) => v.replace(/.*\((.*)\)/g, '$1')) : undefined,
      favorite: v.fields[process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_TEMPLATE_FAVORITE_FIELD_ID!],
    }
  }).filter((v: any) => v.code != null);
}

export async function getMasterTemplate({recordId}: { recordId: string }) {
  const templateList = await getTemplateList();
  const basicInfo = await getBasicInfo(recordId);
  const templateList02 = templateList.map((v: any) => {
    return {
      code: v.code,
      beforeStatus: v.beforeStatus,
      afterStatus: v.afterStatus,
      values: v.value
    }
  })
  return {
    name: basicInfo?.name,
    phone: basicInfo?.phone,
    templateList: templateList02,
  }
}

export async function getFavoriteTemplate({recordId}: { recordId: string }) {
  const templateList = await getTemplateList();
  const basicInfo = await getBasicInfo(recordId);

  const templateList02 = templateList.filter((v: any) => v.favorite === true).map((v: any) => {
    return {
      code: v.code,
      beforeStatus: v.beforeStatus,
      afterStatus: v.afterStatus,
      values: v.value
    }
  })
  return {
    name: basicInfo?.name,
    phone: basicInfo?.phone,
    templateList: templateList02,
  }
}

export async function sendAlimtalk(
    {
      templateCode,
      phone,
      value
    }: {
      templateCode: string,
      phone: string,
      value: string[] | undefined
    }) {
  const variable = getArrayOfReducedString(value!);

  return await axios.post(
      process.env.REACT_APP_SERVER_DOMAIN_URL! + 'simply',
      {
        templateCode,
        to: phone.replaceAll('-', '').trim(),
        // to: '01031929622',
        variable,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          serverKey: process.env.REACT_APP_SERVER_KEY!,
        }
      }
  )
}

export async function getAlimtalkSendInput(recordId: string): Promise<IAlimtalkInputProps | undefined> {
  try {
    const basicInfo = await getBasicInfo(recordId);
    if(basicInfo === null) {
      throw Error('해당 레코드에 대한 고객 정보가 존재하지 않습니다.');
    }
    const template = await findTemplateByStatusByFieldId(basicInfo.status);
    if (template == null) {
      throw Error('해당 진행상태에 맞는 템플릿이 존재하지 않습니다.')
    }
    if (template.afterStatus === 'undefined') {
      throw Error('발송 후 진행상황을 넣어주세요')
    }
    const values = await matchValue(recordId, template?.value);
    const templateInfo = await getTemplateDetailInfo({
      templateCode: template.templateCode,
      targetNumber: basicInfo.phone,
      values,
    })
    if (templateInfo == null) {
      return undefined;
    }
    return {
      name: basicInfo.name,
      phone: basicInfo.phone,
      values,
      afterStatus: template.afterStatus,
      code: template.templateCode,
      template: templateInfo,
    }
  } catch (e: any) {
    // console.log(e)
    throw Error(e.toString().replace('Error: ', ''))
  }
}

export async function getTemplateDetailInfo(
    {
      templateCode,
      targetNumber,
      values
    }: {
      templateCode: string,
      targetNumber: string,
      values: string[] | undefined
    }): Promise<ITemplateInfo | undefined> {
  try {
    const variable = getArrayOfReducedString(values);

    const {data} = await axios.post(
        process.env.REACT_APP_SERVER_DOMAIN_URL! + 'preview',
        {
          templateCode,
          to: targetNumber.replaceAll('-', '').trim(),
          variable,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            serverKey: process.env.REACT_APP_SERVER_KEY!,
          }
        }
    )
    return data.resultData;
  } catch (e: any) {
    console.log(e)
    if (e.response.status === 412) {
      throw Error('현재 정보들만으로는 알림톡을 전송할 수 없습니다.\n템플릿과 변수를 확인해주세요.');
    }
    throw Error(e.message)
  }
}

export async function getTemplateDetail(
    {
      recordId,
      templateCode,
      targetNumber,
      values
    }: {
      recordId: string,
      templateCode: string,
      targetNumber: string,
      values: string[]
    }) {
  const valueList = await matchValue(recordId, values);
  const template = await getTemplateDetailInfo({
    templateCode,
    targetNumber,
    values: valueList
  })
  return {
    template,
    values: valueList
  }
}

// 에어테이블API의 불필요한 호출방지 코드
let oneByRecordIdByFieldId: any = null;

async function findOneByRecordIdByFieldId(recordId: string) {
  let table = baseTorder(process.env.REACT_APP_AIRTABLE_BASIC_TABLE_ID!);

  const result = await table.select({
    filterByFormula: `{RECORD ID} = '${recordId}'`,
    returnFieldsByFieldId: true,
    maxRecords: 1,
  }).firstPage();
  // 에어테이블API의 불필요한 호출방지 코드
  oneByRecordIdByFieldId = result[0].fields;
  return result[0].fields;
}

async function matchValue(recordId: string, valueById: string[] | undefined): Promise<string[]> {
  // console.log(valueById, recordId)
  if (valueById == null || recordId === '') {
    return [];
  }

  // 에어테이블API의 불필요한 호출방지 코드
  let one: any;
  if (oneByRecordIdByFieldId === null) {
    oneByRecordIdByFieldId = await findOneByRecordIdByFieldId(recordId);
  }
  one = oneByRecordIdByFieldId;

  // console.log(one)
  return valueById.map((v: string) => {
    if (one[v] == null || one[v] === '' || one[v] === ' ') {
      // throw Error('value 값에 해당하는 필드가 없거나 해당 필드에 값이 없습니다.\nFIELD ID를 확인해주세요\nFIELD ID: ' + v);
      return 'ㅤ';
    }
    if (one[v].email != null) {
      return one[v].name;
    }
    if (_.isArray(one[v])) {
      return Array(one[v]).join('/');
    }
    return one[v];
  });
}

async function getBasicInfo(recordId: string) {
  try {

    // 에어테이블API의 불필요한 호출방지 코드
    let one: any;
    if (oneByRecordIdByFieldId === null) {
      oneByRecordIdByFieldId = await findOneByRecordIdByFieldId(recordId);
    }
    one = oneByRecordIdByFieldId;

    return {
      name: String(one[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_NAME_FIELD_ID!]),
      phone: String(one[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_PHONE_FIELD_ID!]),
      status: String(one[process.env.REACT_APP_AIRTABLE_BASIC_TABLE_STATUS_FIELD_ID!]),
    };
  } catch (e: any) {
    console.log(e)
    return null;
  }
}

export async function updateStatusByRecordId(
    {
      recordId,
      status
    }: {
      recordId: string,
      status: string
    }): Promise<string> {
  const table = baseTorder(process.env.REACT_APP_AIRTABLE_BASIC_TABLE_ID!)
  const data = await table.update([{
    id: recordId,
    fields: {
      [process.env.REACT_APP_AIRTABLE_BASIC_TABLE_STATUS_FIELD_ID!]: status
    }
  }]);
  return data[0].id;
}


async function findTemplateByStatusByFieldId(status: string): Promise<ITemplate | undefined> {
  const table = baseTorder(process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_ID!);
  try {
    const templates = await table.select({
      sort: [{field: process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_TEMPLATE_NAME_FIELD_ID!, direction: 'desc'}],
      returnFieldsByFieldId: true,
    }).firstPage();
    const result = templates.filter((v: any) => v.fields[process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_BEFORE_STATUS_FIELD_ID!] === status);
    if (result.length === 0) {
      return undefined;
    }
    const value: string[] | undefined = result[0].fields[process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_TEMPLATE_VALUE_FIELD_ID!] ? String(result[0].fields[process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_TEMPLATE_VALUE_FIELD_ID!]).split(',').map((v: any) => v.replace(/.*\((.*)\)/g, '$1')) : undefined;
    return {
      templateCode: String(result[0].fields[process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_TEMPLATE_CODE_FIELD_ID!]),
      beforeStatus: String(result[0].fields[process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_BEFORE_STATUS_FIELD_ID!]),
      afterStatus: String(result[0].fields[process.env.REACT_APP_AIRTABLE_TEMPLATE_TABLE_AFTER_STATUS_FIELD_ID!]),
      value,
    }
  } catch (e: any) {
    console.log(e);
    if (e.error === 'NOT_FOUND') {
      return undefined;
    }
  }
}

const getArrayOfReducedString = (stringArr?: string[]): string[] => {
  if(stringArr == null) return [];

  for(let i = 0; i < stringArr.length; i++) {
    if(stringArr[i].length > 23) {
      stringArr[i] = stringArr[i].substring(0, 22).concat('…');
    }
  }
  return stringArr;
}

const getStringSizeInBytes = (str: string): number => {
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(str);
  return encodedData.length;
};

