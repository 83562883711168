import {css} from 'aphrodite';

import {CreateBetaStyle} from '../../shared/CreateStyle';

export const LogisticsNoticePopup = (
    {
      content, button, onClick
    }: {
      content: string, button: string, onClick: () => void
    }) => {
  return (
    <div className={css(styles.background)}>
      <div className={css(styles.frame)}>
        <div className={css(styles.notice)}>
          {content}
        </div>
        <div className={css(styles.button)} onClick={onClick}>{button}</div>
      </div>
    </div>
  )
}

const styles = CreateBetaStyle({
  background: {
    width: '100%',
    height: '100%',

    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 100,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: 'rgba(0, 0, 0, 0.4)',

    boxSizing: 'border-box',
  },
  frame: {
    width: '322px',
    height: '215px',

    backgroundColor: '#FFFFFF',

    borderRadius: '10px',

    padding: '20px',

    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.15)',

    boxSizing: 'border-box',
  },
  notice: {
    width: '100%',
    height: '115px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginBottom: '15px',

    fontSize: '20px',
    lineHeight: '30px',
    color: '#30363D',
    fontWeight: 600,
    whiteSpace: 'pre',
    textAlign: 'center',
  },
  button: {
    width: '100%',
    height: '45px',

    backgroundColor: '#E20F23',

    borderRadius: '10px',

    color: 'white',
    fontSize: '17px',
    letterSpacing: '-0.1%',
    fontWeight: 700,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    cursor: 'pointer',
  },
})
