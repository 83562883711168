import {css} from 'aphrodite';

import {CreateBetaStyle} from '../../shared/CreateStyle';
import {Image} from '../../shared/components/Image';

import Logo from '../../../assets/images/svgs/torderLogo.svg';
import Docs from '../../../assets/images/svgs/docs.svg';
import Out from '../../../assets/images/svgs/out.svg';
import {ReactComponent as Install} from '../../../assets/images/svgs/install02.svg';

interface NavigatorProps {
  type: string
}

export const Navigatior = (props: NavigatorProps) => {
  const closeBrowser = () => {
    window.open('', '_self', '');
    window.close();
  }

  return (
    <div className={css(styles.frame)}>
      <Image src={Logo} alt={'logo'} className={css(styles.logo)}/>
      {props.type === 'basic'
        && (
          <>
            <div className={css(styles.button, styles.buttonSelected)}>
              <Image src={Docs} alt={'docs'} className={css(styles.buttonImg)}/>
              <p>출고신청(일반)</p>
            </div>
            <div className={css(styles.button)}>
              <PreparingIcon/>
              <p>출고신청(추가)</p>
            </div>
          </>
        )
      }
      {props.type === 'create'
        && (
          <>
            <div className={css(styles.button)}>
              <PreparingIcon/>
              <p>출고신청(일반)</p>
            </div>
            <div className={css(styles.button, styles.buttonSelected)}>
              <Install className={css(styles.buttonImg)}/>
              <p>출고신청(추가)</p>
            </div>
          </>
        )
      }
      <div className={css(styles.out)} onClick={() => closeBrowser()}>
        <Image src={Out} alt={'out'} className={css(styles.outImg)}/>
        종료하기
      </div>
    </div>
  )
}

const PreparingIcon = () => {
  return (
    <div className={css(styles.preparing)}>준비중</div>
  )
}

const styles = CreateBetaStyle({
  frame: {
    width: '272px',
    height: '100%',

    backgroundColor: 'white',

    padding: '20px',

    fontFamily: 'Pretendard',

    position: 'absolute',
    left: 0,
    top: 0,

    boxSizing: 'border-box',

    boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.25)',

    '@media(max-width: 1800px)': {
      width: '242px',
    },
  },
  logo: {
    width: '134px',
    height: '32px',

    objectFit: 'contain',

    marginBottom: '62px',
  },
  buttonBox: {},
  button: {
    width: '232px',
    height: '54px',

    display: 'flex',
    alignItems: 'center',

    paddingLeft: '15px',
    marginBottom: '20px',

    borderRadius: '10px',

    fontSize: '17px',
    fontWeight: 700,
    color: '#666973',

    cursor: 'pointer',

    boxSizing: 'border-box',

    fill: '#666973',

    '@media(max-width: 1800px)': {
      width: '202px',
      fontSize: '15px',
    },
  },
  buttonSelected: {
    backgroundColor: '#E20F23',
    color: 'white',

    fill: 'none',
    stroke: 'white',
  },
  buttonImg: {
    width: '23px',
    height: '23px',

    fill: 'inherit',
    stroke: 'inherit',

    marginRight: '5px',
  },
  out: {
    display: 'flex',
    alignItems: 'center',

    color: '#666973',
    fontSize: '15px',
    fontWeight: 500,

    position: 'absolute',
    bottom: '46px',

    marginLeft: '30px',

    cursor: 'pointer',
  },
  outImg: {
    width: '20px',
    height: 'auto',

    objectFit: 'contain',

    marginRight: '13px',
  },
  preparing: {
    width: '35px',
    height: '17px',

    backgroundColor: '#8D99AE',

    fontSize: '10px',
    letterSpacing: '-5%',
    fontWeight: 600,
    color: 'white',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: '100px',

    marginRight: '4px',

    '@media(max-width: 1800px)': {
      width: '28px',
      height: '14px',

      fontSize: '8px'
    },
  },
})
