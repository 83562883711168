import {css} from 'aphrodite';
import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import html2canvas from 'html2canvas';

import {CreateBetaStyle} from '../../shared/CreateStyle';
import {Image} from '../../shared/components/Image';
import {BackgroundClose} from './BackgroundClose';

import Address from '../../../assets/images/svgs/shop.svg'
import Phone from '../../../assets/images/svgs/phone.svg'
import Date from '../../../assets/images/svgs/calendar.svg';
import Logo from '../../../assets/images/pngs/torderLogo.png';

const INFO_TEXT = '대표님. 유선 상 안내드렸듯이,\n' +
  '해피콜 이후 견적서 변경은 불가하시며\n' +
  '태블릿 추가설치 및 소모품 추가구매건으로 진행되는 점 참고 부탁드립니다!\n' +
  '또한 설치 당일 오후 5시까지 계약서 전자서명이 미서명 되면 라이센스가 자동으로 잠기게 되므로 꼭 서명까지 완료 부탁드립니다:)'

export const PreviewPopup = (
  {
    customer,
    shopInfo,
    goods,
    screw,
    onClickClose
  }: {
    customer: any,
    shopInfo: any,
    goods: any[],
    screw?: string,
    onClickClose: () => void,
  }) => {
  const [tablet, setTablet] = useState<any>({});
  const [battery, setBattery] = useState<any>({});
  const [master, setMaster] = useState(1);
  const [leader, setLeader] = useState<any>({});
  const [isInput, setIsInput] = useState<any>(true);
  const [isCopy, setIsCopy] = useState(false);

  const masterRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTablet(getHolder(goods));
    setBattery(getSubBattery(goods));
    setLeader(getLeader(goods));
    masterRef.current?.focus();
  }, []);

  return (
    <>
      <div className={css(styles.notification, isCopy && styles.notificationDisplay)}>
        복사완료
      </div>
      <div className={css(styles.background)}>
        <BackgroundClose onClickClose={onClickClose}/>
        <div className={css(styles.frame)}>
          <div className={css(styles.framePreview)} id={'preview'}>
            <Image src={Logo} alt={'logo'} className={css(styles.logo)}/>
            <div className={css(styles.title)}>
              {getShopName(shopInfo)}
            </div>
            <div className={css(styles.shop)}>
              {
                getShopInfos(shopInfo, customer.installDate).map((item: any, index: number) => (
                  <div className={css(styles.shopList)} key={'shop_' + index}>
                    <Image src={item.icon} alt={''} className={css(styles.shopListImg)}/>
                    <div className={css(styles.shopListTitle)}>{item.title}</div>
                    <div className={css(styles.shopListValue)}>{item.value}</div>
                  </div>
                ))
              }
            </div>
            <table className={css(styles.goodsTable)}>
              <tbody>
              <tr>
                <td style={{width: '129px'}} className={css(styles.goodsTableTitle)}>
                  태블릿
                </td>
                <td style={{width: '129px'}}>
                  {getTabletNumber(goods) !== 0 ? `${getTabletNumber(goods) - master} 대` : '-'}
                </td>
                <td style={{width: '129px'}} className={css(styles.goodsTableTitle)}>
                  마스터
                </td>
                <td
                  colSpan={2}
                  onClick={() => {
                    setIsInput(true);
                    masterRef.current?.focus();
                  }}
                  onBlur={() => setIsInput(false)}
                >
                  {getTabletNumber(goods) !== 0
                    ? (
                      isInput
                        ? (
                          <>
                            <input
                              ref={masterRef}
                              value={master}
                              onChange={(e: any) => {
                                const target = e.target.value.replace(/[^0-9]/g, '');
                                setMaster(target)
                              }}
                              className={css(styles.masterInput)}
                            />
                            대
                          </>
                        )
                        : (
                          `${master.toString() === '0' || master.toString() === '' 
                            ? '-' 
                            : `${master} 대`}`
                        )
                    )
                    : '-'
                  }
                </td>
              </tr>
              <tr>
                <td rowSpan={4} className={css(styles.goodsTableTitle)}>거치대</td>
                <td rowSpan={4}>{tablet.color}</td>
                <td rowSpan={3} className={css(styles.goodsTableTitle)}>거치대 유형</td>
                <td style={{width: '100px'}}>고정형</td>
                <td style={{width: '100px'}}>{tablet.fixed}</td>
              </tr>
              <tr>
                <td>이동형</td>
                <td>{tablet.movedForm}</td>
              </tr>
              <tr>
                <td>벽걸이형</td>
                <td>{tablet.wall}</td>
              </tr>
              <tr>
                <td className={css(styles.goodsTableTitle)}>나사</td>
                <td colSpan={2}>{(screw || screw !== '') ? screw : '-'}</td>
              </tr>
              <tr>
                <td className={css(styles.goodsTableTitle)}>보조배터리</td>
                <td>{battery.subBattery}</td>
                <td className={css(styles.goodsTableTitle)}>케이스</td>
                <td colSpan={2}>{battery.subBatteryCase}</td>
              </tr>
              {leader.type !== undefined
                && (
                  <tr>
                    <td className={css(styles.goodsTableTitle)}>선결제 리더기</td>
                    <td>{leader.type}</td>
                    <td className={css(styles.goodsTableTitle)}>리더기 댓수</td>
                    <td colSpan={2}>{leader.amount}</td>
                  </tr>
                )
              }
              <tr>
                <td className={css(styles.goodsTableTitle)}>전기작업</td>
                <td colSpan={5}>{customer.electronType ?? '-'}</td>
              </tr>
              <tr>
                <td className={css(styles.goodsTableTitle)} style={{padding: '10px'}}>특이사항</td>
                <td colSpan={5}>{getComment(shopInfo)}</td>
              </tr>
              </tbody>
            </table>
            <div className={css(styles.warning)}>
              *위에 기재한 사항은 사실과 틀림이 없으며, 설치 시 제품에 대한 단순 교체 및<br/>
              반환은 불가한 점 양해부탁드립니다. 설치 후 포스사측 연동서버 에러 관련해서는<br/>
              당사에서의 보상 및 책임 여부는 없을 참고 부탁드립니다.
            </div>
          </div>
          <div className={css(styles.buttons)}>
            <div
              className={css(styles.button, styles.buttonRed)}
              onClick={() => {
                saveImageInClipBoard(document.querySelector('#preview')!)
                setIsCopy(true);
                setTimeout(() => setIsCopy(false), 1000);
              }}
            >
              이미지 복사
            </div>
            <div
              className={css(styles.button)}
              onClick={() => {
                copyText(INFO_TEXT)
                setIsCopy(true);
                setTimeout(() => setIsCopy(false), 1000);
              }}
            >
              안내 텍스트 복사
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function saveImageInClipBoard(selector: any) {
  html2canvas(selector).then((canvas) => {
    canvas.toBlob((blob) => (
        navigator.clipboard.write(
          [new ClipboardItem({'image/png': blob as Blob})]
        )
      )
    );
  });
}

function copyText(text: string) {
  navigator.clipboard.writeText(text);
}

function getLeader(goods: any) {
  const leader = goods.find((item: any) => item.type === '9.카드리더기');
  if (leader == null) {
    return {
      type: undefined,
      amount: undefined,
    }
  }
  return {
    type: leader.title,
    amount: leader.amount === 0 ? '-' : leader.amount.toString() + ' 개',
  }
}

function getHolder(goods: any) {
  const holders = goods.filter((item: any) => item.type === '1.거치대');
  const color = holders[0] ? holders[0].color : '-';
  let movedForm = 0;
  let wall = 0;
  let fixed = 0;

  holders.map((item: any) => {
    if (item.form === '고정형') {
      fixed += Number(item.amount);
      return;
    }
    if (item.form === '이동형') {
      movedForm += Number(item.amount);
      return;
    }
    if (item.form === '벽걸이형') {
      wall += Number(item.amount);
      return;
    }
  });

  return {
    color,
    movedForm: movedForm ? `${movedForm} 개` : '-',
    wall: wall ? `${wall} 개` : '-',
    fixed: fixed ? `${fixed} 개` : '-',
  };
}

function getSubBattery(goods: any) {
  let subBattery = 0;
  let subBatteryCase = 0;

  goods.map((item: any) => {
    if (item.type === '7.보조배터리') {
      subBattery += Number(item.amount);
      return;
    }
    if (item.type === '8.보조배터리 케이스') {
      subBatteryCase += Number(item.amount);
      return;
    }
  });

  return {
    subBattery: subBattery === 0
      ? '-'
      : subBattery.toString() + ' 개',
    subBatteryCase: subBatteryCase === 0
      ? '-'
      : subBatteryCase.toString() + ' 개'
  }
}

function getTabletNumber(goods: any) {
  const tablets = goods.filter((item: any) => item.type === '4.태블릿');
  let num = 0;

  tablets.map((item: any) => {
    if (item.title === undefined) {
      return;
    }
    if (item.title.includes('여유분')) {
      return;
    }
    num += Number(item.amount)
  });
  return num;
}

function getDate(date: string) {
  const targetDate = moment(date);
  const day = targetDate.day();
  const DAY = ['일', '월', '화', '수', '목', '금', '토'];
  return targetDate.format('YYYY년 MM월 DD일') + ' (' + DAY[day] + ')'
}

function getShopName(shop: any) {
  const name = shop.find((v: any) => v.title === '상호명').value;
  const location = shop.find((v: any) => v.title === '지점명').value;
  return name + '(' + location + ')'
}

function getComment(shop: any) {
  return shop.find((v: any) => v.title === '물류신청서 특이사항(인쇄용)').value;
}

function getShopInfos(shop: any, date: string) {
  const address = shop.find((v: any) => v.title === '배송지').value;
  const phone = shop.find((v: any) => v.title === '연락처').value;

  return [
    {
      title: '주소',
      value: address,
      icon: Address,
    },
    {
      title: '연락처',
      value: phone,
      icon: Phone,
    },
    {
      title: '설치일자',
      value: getDate(date),
      icon: Date,
    },
  ]
}

const styles = CreateBetaStyle({
  background: {
    width: '100%',
    height: '100%',

    position: 'absolute',
    left: 0,
    top: 0,

    zIndex: 200,

    backgroundColor: 'rgba(0, 0, 0, 0.5)',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  frame: {
    width: '674px',
  },
  framePreview: {
    width: '100%',

    padding: '40px',

    boxSizing: 'border-box',

    backgroundColor: 'white',

    borderRadius: '5px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    position: 'relative',

    fontFamily: 'Pretendard',
  },
  logo: {
    width: '120px',
    height: 'auto',

    position: 'absolute',
    right: '30px',
    top: '27px'
  },
  title: {
    width: '100%',

    fontWeight: 700,
    fontSize: '35px',

    marginBottom: '22px',
  },
  shop: {
    width: '100%',

    marginBottom: '15px',
  },
  shopList: {
    display: 'flex',
    alignItems: 'center',

    marginBottom: '12px',
  },
  shopListImg: {
    width: '20px',
    height: '20px',

    marginRight: '10px',
  },
  shopListTitle: {
    width: '100px',
    minWidth: '100px',

    fontWeight: 500,
    fontSize: '15px',
    color: '#666973',
  },
  shopListValue: {
    fontWeight: 500,
    fontSize: '15px',
    color: '#333333'
  },
  goodsTable: {
    borderCollapse: 'collapse',
    ':nth-child(n) > tr, td, th': {
      height: '38px',

      border: '1px solid #c5c5c9',

      fontWeight: 500,
      fontSize: '15px',
      textAlign: 'center',
    },

    marginBottom: '20px',
  },
  goodsTableTitle: {
    backgroundColor: '#F4F4F8',
    fontWeight: 600,
  },
  warning: {
    width: '528px',

    textAlign: 'center',

    fontSize: '15px',
    color: '#E20F23',
    letterSpacing: '-0.2px',
    fontWeight: 600,
    lineHeight: '22px',

    wordBreak: 'keep-all',
  },
  masterInput: {
    width: '15px',
    height: '15px',

    border: 'none',

    textAlign: 'right',

    marginLeft: '-7px',
    marginRight: '2px',

    fontWeight: 500,
    fontSize: '15px',
    fontFamily: 'Pretendard',

    ':focus': {
      outline: 'none',
      borderBottom: 'solid 1px #333333',
      // borderRadius: '5px',
    }
  },
  buttons: {
    width: '674px',

    display: 'flex',
    justifyContent: 'space-between',

    marginTop: '27px',
  },
  button: {
    width: '319px',
    height: '40px',
    backgroundColor: 'black',

    borderRadius: '5px',

    color: 'white',
    fontSize: '14px',
    fontWeight: 700,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    cursor: 'pointer',
  },
  buttonRed: {
    backgroundColor: '#EB1E32',
    border: 'solid 1px #B50F1F',
  },
  notification: {
    width: '150px',
    height: '45px',

    backgroundColor: 'rgba(235, 30, 50, 0.6)',

    position: 'fixed',
    right: '-220px',
    top: '20px',
    opacity: 0,
    zIndex: 205,

    fontSize: '15px',
    color: 'white',
    fontWeight: 700,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    borderRadius: '5px',

    transition: 'opacity 0.3s, right 0.3s'
  },
  notificationDisplay: {
    right: '20px',
    opacity: 1,
    transition: 'opacity 0.3s, right 0.3s'
  },
  backgroundClose: {
    width: '100vw',
    height: '100vh',

    position: 'fixed',
    left: 0,
    top: 0,

    backgroundColor: 'rgba(0, 0, 0, 0)',
    zIndex: -1,
    cursor: 'default',
  },
})
