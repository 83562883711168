export function getError(e: any): string {
  if (e.response != null) {
    switch (e.response.status) {
      case 500:
        return '서버에러';
      case 412:
        return '파라미터 오류';
      case 400:
        return '파라미터 오류';
      default:
        return e.message;
    }
  }
  return e.toString();
}
