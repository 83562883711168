import {css} from 'aphrodite';
import React from 'react';

import {Image} from '../../shared/components/Image';
import {CreateBetaStyle} from '../../shared/CreateStyle';

import {sortItemsByBlack} from '../helpers/LogisticsHelper';

import SelectedCheck from '../../../assets/images/svgs/check_red.svg';

export const SelectItem = (
  {
    items,
    onClickPreview,
    onClickSelected,
    isUp,
  }: {
    items: string[],
    onClickPreview: (item: any) => void,
    onClickSelected: (
      {
        id,
        name,
        type,
        form,
        color
      }: {
        id: string,
        name: string,
        type: string,
        form?: string,
        color: string
      }) => void,
    isUp: boolean
  }) => {
  const favorites = items.filter((v: any) => v.isFavorites === true);
  const isExistFavorites = favorites.length > 0;

  return (
    <div
      className={css(
        styles.selectItemsFrame,
        isUp && styles.selectItemsFrameUp,
        items.length < 6 && styles.selectItemsFrameSmall
      )}
    >
      {isExistFavorites
        && (
          <>
            <div className={css(styles.title)}>많이 찾는 항목</div>
            <div>
              {
                sortItemsByBlack(favorites).map((item1: any, index) => (
                  <div
                    className={css(styles.goodsList)}
                    key={'favorite_' + index}
                    // onClick={() => onClickSelected(item.item, item.type)}
                  >
                    <div className={css(styles.goodsImgFrame)}>
                      {item1.photoUrl && (
                        <Image
                          src={item1.photoUrl[0].url}
                          alt={'goods-list'}
                          className={css(styles.goodsImg)}
                          onClick={() => onClickPreview(item1)}
                        />
                      )}
                    </div>
                    <div
                      className={css(styles.goodsTitle, item1.isSelected && styles.colorRed)}
                      onClick={() => onClickSelected(
                        {
                          id: item1.id,
                          name: item1.item,
                          type: item1.type,
                          form: item1.holderForm,
                          color: item1.color
                        }
                      )}
                    >
                      {item1.item}
                    </div>
                    {item1.isSelected && (
                      <Image
                        src={SelectedCheck}
                        alt={'selected-check'}
                        className={css(styles.goodsSelectedCheck)}
                      />
                    )}
                  </div>
                ))
              }
            </div>
          </>
        )
      }
      <div className={css(styles.title)}>전체</div>
      <div>
        {items.map((item: any, index: number) =>
          <div
            className={css(styles.goodsList)}
            // onClick={() => onClickSelected(item.item, item.type)}
            key={'all-item_' + index}
          >
            <div className={css(styles.goodsImgFrame)}>
              {item.photoUrl &&
                <Image
                  src={item.photoUrl[0].url}
                  alt={'goods-list'}
                  className={css(styles.goodsImg)}
                  onClick={() => onClickPreview(item)}
                />}
            </div>
            <div
              className={css(styles.goodsTitle, item.isSelected && styles.colorRed)}
              onClick={() => onClickSelected(
                {
                  id: item.id,
                  name: item.item,
                  type: item.type,
                  form: item.holderForm,
                  color: item.color
                }
              )}
            >
              {item.item}
            </div>
            {item.isSelected && (
              <Image
                src={SelectedCheck}
                alt={'selected-check'}
                className={css(styles.goodsSelectedCheck)}
              />)}
          </div>
        )}
      </div>
    </div>
  )
}

export const GoodsPreview = (
  {
    url,
    onClickClose
  }: {
    url: string,
    onClickClose: any
  }) => {
  return (
    <div className={css(styles.previewWrapper)}>
      <div onClick={onClickClose} className={css(styles.backgroundClose)}/>
      <div className={css(styles.previewImgFrame)}>
        <Image
          src={url}
          alt={'goods'}
          className={css(styles.previewImg)}
        />
      </div>
    </div>
  )
}

const styles = CreateBetaStyle({
  colorRed: {
    color: '#E20F23',
  },
  backgroundClose: {
    width: '100vw',
    height: '100vh',

    position: 'fixed',
    left: 0,
    top: 0,

    backgroundColor: 'rgba(0, 0, 0, 0)',
    zIndex: -1,
    cursor: 'default',
  },
  selectItemsFrame: {
    width: '362px',
    height: '505px',
    backgroundColor: 'white',

    overflowY: 'scroll',
    overflowX: 'hidden',

    position: 'absolute',
    right: 0,
    top: '40px',
    zIndex: 50,

    border: '1px solid #ACACAC',
    borderRadius: '10px',

    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.15)',

    padding: '10px 10px 0px 10px',

    boxSizing: 'border-box',

    fontFamily: 'Pretendard',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: '#A8A8A8',
      borderRadius: '15px',
      border: '3px solid transparent',
    },
    '::-webkit-scrollbar-track': {
      padding: '10px',

      background: 'transparent',
    },
  },
  selectItemsFrameUp: {
    height: '365px',

    position: 'absolute',
    top: 'none',
    bottom: '40px',
    right: 0,
    zIndex: 100,
  },
  selectItemsFrameSmall: {
    height: '365px',
  },
  title: {
    width: '322px',

    paddingBottom: '10px',
    paddingTop: '20px',
    marginBottom: '10px',

    borderBottom: 'solid 1px #E8E8E8',

    color: '#666973',
  },
  goodsList: {
    width: '342px',
    height: '70px',

    paddingLeft: '10px',

    display: 'flex',
    alignItems: 'center',

    ':hover': {
      backgroundColor: '#FAFAFA',
    },

    boxSizing: 'border-box',

    cursor: 'pointer',

    position: 'relative',
  },
  goodsImgFrame: {
    width: '50px',
    height: '50px',

    borderRadius: '5px',


    backgroundColor: '#F4F4F4',

    overflow: 'hidden',

    marginRight: '10px',
  },
  goodsImg: {
    width: '100%',
    height: 'auto',
  },
  goodsTitle: {
    height: '100%',

    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  goodsSelectedCheck: {
    width: '20px',
    height: '20px',

    position: 'absolute',
    right: '10px'
  },
  previewWrapper: {
    width: '100%',
    height: '100%',

    backgroundColor: 'rgba(0, 0, 0, 0.5)',

    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 100,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewImgFrame: {
    width: '768px',
    height: '512px',
  },
  previewImg: {
    width: '100%',
    height: '100%',
  }
})
