export const CONTRACT_INFO =
  '1. 제품 구매 안내 사항\n' +
  ' (1) 고객 편의를 위해 당 사에서 IT 특별가로 구매 대행 해 드리고 있으며, A/S는 제조사로부터 받으셔야 합니다.\n' +
  ' (2) 티오더에서 발행된 구매 영수증 증빙 후 교환이 가능합니다.\n' +
  ' (3) A/S 3개월 이후 새 제품이 아닌 리퍼 제품으로 발송 됩니다.\n' +
  ' (4) 모든 제품 수령 후 반품이 불가합니다.\n' +
  '\n' +
  '2. 교환 안내\n' +
  ' (1) 교환에 관한 일반적인 사항은 판매자가 제시사항보다 관계법령이 우선합니다. 다만 판매자의 제시사항이 관계법령보다 소비자에게 유리한 경우에는 판매자 제시사항이 적용됩니다.\n' +
  '\n' +
  '3. 교환/반품 비용 (왕복비용)\n' +
  ' (1) 6,000원\n' +
  ' (2) 단, 고객 변심의 경우에만 발생\n' +
  ' (3) 도서 산간 및 일부 지역 추가 비용 발생\n' +
  '\n' +
  '4. 교환 신청 기준일\n' +
  ' (1) 단순 변심에 의한 교환/반품은 제품 수령 후 7일 이내까지, 교환 제한사항에 해당하지 않는 경우에만 가능 (배송비용과 교환/반품 비용, 왕복 배송비는 고객 부담)\n' +
  '\n' +
  '5. 교환/반품 제한사항\n' +
  ' (1) 주문/제작 상품의 경우, 상품의 제작이 이미 진행된 경우\n' +
  ' (2) 상품 포장을 개봉하여 사용 또는 설치 완료되어 상품의 가치가 훼손된 경우 (단, 내용 확인을 위한 포장 개봉의 경우는 제외)\n' +
  ' (3) 고객의 사용, 시간경과, 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우\n' +
  ' (4) 세트상품 일부 사용, 구성품을 분실하였거나 취급 부주의로 인한 파손/고장/오염으로 재판매 불가한 경우\n' +
  ' (5) 제조사의 사정(신모델 출시 등) 및 부품 가격 변동 등에 의해 무료 교환/반품으로 요청하는 경우'

export const INSTALL_INFO =
  '';

export const ELECTRON_DEADLINES = [
  {
    title: '택배발송',
    content:
      '1. 배송사 : CJ 대한통운\n' +
      '\n' +
      '2. 배송기간\n' +
      ' (1) 도서 산간 지역 등은 배송에 3~5일 더 소요될 수 있습니다.\n' +
      ' (2) 천재지변, 물량 수급 변동 등 예외적인 사유 발생 시, 다소 지연될 수 있는 점 양해 바랍니다.\n' +
      '\n' +
      '3. 택배비용\n' +
      ' (1) 극소형 : 80(x+y+z)cm 이하 / 2kg 이하 / 내륙(5,000원) / 제주(8,000원)\n' +
      ' (2) 소형 : 100(x+y+z)cm 이하 / 5kg 이하 / 내륙(6,000원) / 제주(9,000원)\n' +
      ' (3) 중형 : 100(x+y+z)cm 이하 / 10kg 이하 / 내륙(7,000원) / 제주(10,000원)\n' +
      ' (4) 대형 : 140(x+y+z)cm 이하 / 15kg 이하 / 내륙(8,000원) / 제주(11,000원)\n' +
      ' (5) 특대형 : 140(x+y+z)cm 이하 / 20kg 이하 / 내륙(9,000원) / 제주(12,000원)',
  },
  {
    title: '퀵 배송',
    content:
      '1. 화물범위 : 오토바이에 적재가 가능한가(유리, 자기류 및 고가의 물건은 주문시 확인절차 요구)\n' +
      '\n' +
      '2. 화물규격\n' +
      ' (1) 소 : 10cm x 60cm x 40cm 이내(과적요금 적용기준 - 중량 40kg 이내)\n' +
      ' (2) 중 : 90cm x 120cm x 50cm 이내(대형 오토바이 요청기준 - 중량 60kg 이내)\n' +
      ' (3) 대 : 100cm x 120cm x 60cm 이내(그 외 지역은 주문시 안내)\n' +
      '\n' +
      '3. 배송지역 : 서울, 경기 인접지역(그 외 지역은 주문시 안내)'
  }
];


export const SALE_DEFAULT_OPTIONS = [
  {
    type: '',
    name: '',
    price: '',
    quantity: '',
  }
];

export const SUB_GOODS_DEFAULT_OPTIONS = [
  {
    type: '',
    name: '',
    color: '',
    price: '',
    quantity: '',
    total: '',
  }
];


export const INITIAL_COMMENT = `안녕하세요 대표님.
티오더입니다.`;

export const THIRD_COMMENT = `설치까지 불편함 없도록 도와드릴 것이며,
궁금한 사항은 대표번호로 전화 주시거나 이 단톡방에서 남겨주시면 실시간 응대되도록 최선을 다하겠습니다.`;

export const getGoodsInfo = ({period, tablet, masterTablet, price, customer}: {period: string, tablet: number, masterTablet: number, price: string, customer: any}) => {
  const contractYears = `${Number(period) / 12}`;
  const tabletInfo = masterTablet ? `${tablet}+${masterTablet}(마스터)=${Number(tablet)+Number(masterTablet)}대` : `${tablet}대`;
  return `${contractYears}년 약정 / ${tabletInfo} / ${price}원 / ${customer.holderColor} / ${customer.electronType} / ${customer.paymentType}  견적서입니다`;
}

export const getCustomer = (phone: string, key: string) => {
  return `매장 대표님 연락처: ${phone}
일련번호: ${key}`;
}

export const getAccountInfo = (prepayment: string) => {
  return `[선납금(초기 계약금) 금액안내]
  
예금주 : ㈜티오더
계좌은행 : 신한은행
계좌번호 : 140-012-934957
선납금(초기 계약금) ${prepayment}원
매장 + 지점명으로 입금 후 톡 주시면 담당자 접수 요청해드리겠습니다.`
}

export const WORKING_TIME_INFO = `[티오더 업무시간 안내]

<영업지원팀>
■ 운영시간 : 평일 10시~19시
■ 점심시간 : 오후 1시~2시
※ 영업지원팀 비운영 시간 중 문의가 필요하신 경우 아래 고객지원팀으로 문의 부탁드리며,
접수 순차적으로 영업일에 담당자를 통해 신속히 답변 드리겠습니다.

<고객지원팀>
■ 대표전화 : 1644-4425
■ 운영시간 : 매일 오전 10시~익일 02시

해당 단톡방은 설치 전까지만 이용가능하며,
설치 후에는 고객지원 번호 "1644-4425" 또는 설치 후 발송되는 해피톡을 이용하여 응대드리는 점 참고부탁드립니다.`;

export const LAST_INFO = `안녕하세요 대표님 상담내용 정리하여 전달드렸습니다. 추가적인 문의사항의 경우 카톡방 혹은 전화주시면 빠르게 응대드릴 수 있도록 하겠습니다!`;
export const YOUTUBE_INFO = `[유튜브 소개영상]
https://www.youtube.com/watch?v=NIWGkOArYCs`;
