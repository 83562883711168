export const CONTRACT_INFO =
  '1. 모든 할인 금액은 약정 유지 조건 입니다.\n' +
  '2. 선납금을 제외한 나머지 금액은 부가세 별도입니다.\n' +
  '3. 보증보험료(매장 측 비용 부담) 발행 없이 총 금액의 5% 선납금(초기 계약금) 입금 후 렌탈 및 할부구매 계약 진행이 됩니다.\n' +
  '4. 선납금(초기계약금)을 제외한 총 금액의 95%를 36개월 분납하여 납부하는 방식으로 계약 체결 됩니다.\n' +
  '5. 약정 기간 36개월 이내 워런티(A/S) 무상 지원 (단, 사용자 과실 제외)\n' +
  '서빙로봇 초기 설치비, 주행 시나리오 셋팅 무상 지원';

export const INSTALL_INFO =
  '베어로보틱스\n' +
  '1. 설치 시 현장에서 확인 된 장애물 외 테이블 위치 변경, 매장 구조 변경 등으로 인한 방문 요청 시에는 출장 비용이 청구 될 수 있습니다.\n' +
  '2. 설치 전 무료 시연 기간은 최소 3일 ~ 최대 7일까지 제공되며, 실 계약 진행 시에는 새 제품으로 설치 진행 됩니다.';

export const ELECTRON_DEADLINES = [
  {
    content:
      '베어로보틱스 \n' +
      '1. 약정기간 내 무상 A/S (고객 사유 제외)\n' +
      '2. 주기적인 원격관리 / 전문 엔지니어의 설치 서비스\n' +
      '3. 소프트웨어 원격 관리 (월 2회 정기적인 사용량 체크, 테이블 포인트 조정, 속도 변경, 멘트 변경, 에러 확인 등)\n' +
      '4. 소프트웨어 정기 업데이트 등 소프트웨어 원격 수리 / 하드웨어의 경우 대체 로봇 설치하여 회수 후 수리\n' +
      '5. 약정 기간 내 영업 배상 보험 무상 (생산물 배상 책임 보험 보유)\n' +
      '6. 약정 종료 후 최소 유지 보수 비용으로 계약 연장 가능',
  },
  {
    content:
      '베어로보틱스 \n' +
      '1. 약정기간 내 무상 A/S (고객 사유 제외)\n' +
      '2. 주기적인 원격관리 / 전문 엔지니어의 설치 서비스\n' +
      '3. 소프트웨어 원격 관리 (월 2회 정기적인 사용량 체크, 테이블 포인트 조정, 속도 변경, 멘트 변경, 에러 확인 등)\n' +
      '4. 소프트웨어 정기 업데이트 등 소프트웨어 원격 수리 / 하드웨어의 경우 대체 로봇 설치하여 회수 후 수리\n' +
      '5. 약정 기간 내 영업 배상 보험 무상 (생산물 배상 책임 보험 보유)\n' +
      '6. 약정 종료 후 최소 유지 보수 비용으로 계약 연장 가능\n' +
      '   a. 소유권 이전 희망 시 : 200만원 유예금 납입 후 소유권 이전\n' +
      '      (2년 무상 a/s 추가 지원)\n' +
      '   b. 1년 단위 계약 연장 : 월 50% 요금 할인 후 12개월 연장',
  },
];


export const INSTALLMENT_OPTIONS = [
  'LG CLOi 3세대',
  '베어로보틱스 Servi Plus',
  '베어로보틱스 Servi',
];

export const CONTRACT_PERIOD_OPTIONS = ['12', '24', '36'];


export const INSTALLMENT_DEFAULT_OPTIONS = [
  {
    name: 'LG CLOi 3세대',
    month: '36',
    price: '550000',
    quantity: 1,
    total: '',
  },
];

export const SUB_GOODS_DEFAULT_OPTIONS = [
  {
    type: '',
    name: '',
    color: '',
    price: '',
    quantity: '',
    total: '',
  }
];

export const SALE_DEFAULT_OPTIONS = [
  {
    type: '',
    name: '',
    price: '',
    quantity: '',
  }
];

export const PROMOTION_DEFAULT_OPTIONS = [
  {
    title: '',
    price: '',
    quantity: '',
  }
];


export const INITIAL_COMMENT = `안녕하세요 대표님.
티오더입니다.`;

export const THIRD_COMMENT = `설치까지 불편함 없도록 도와드릴 것이며,
궁금한 사항은 대표번호로 전화 주시거나 이 단톡방에서 남겨주시면 실시간 응대되도록 최선을 다하겠습니다.`;

export const getGoodsInfo = ({period, tablet, masterTablet, price, customer}: {period: string, tablet: number, masterTablet: number, price: string, customer: any}) => {
  const contractYears = `${Number(period) / 12}`;
  const tabletInfo = masterTablet ? `${tablet}+${masterTablet}(마스터)=${Number(tablet)+Number(masterTablet)}대` : `${tablet}대`;
  return `${contractYears}년 약정 / ${tabletInfo} / ${price}원 / ${customer.holderColor} / ${customer.electronType} / ${customer.paymentType}  견적서입니다`;
}

export const getCustomer = (phone: string, key: string) => {
  return `매장 대표님 연락처: ${phone}
일련번호: ${key}`;
}

export const getAccountInfo = (prepayment: string) => {
  return `[선납금(초기 계약금) 금액안내]
  
예금주 : ㈜티오더
계좌은행 : 신한은행
계좌번호 : 140-012-934957
선납금(초기 계약금) ${prepayment}원
매장 + 지점명으로 입금 후 톡 주시면 담당자 접수 요청해드리겠습니다.`
}

export const WORKING_TIME_INFO = `[티오더 업무시간 안내]

<영업지원팀>
■ 운영시간 : 평일 10시~19시
■ 점심시간 : 오후 1시~2시
※ 영업지원팀 비운영 시간 중 문의가 필요하신 경우 아래 고객지원팀으로 문의 부탁드리며,
접수 순차적으로 영업일에 담당자를 통해 신속히 답변 드리겠습니다.

<고객지원팀>
■ 대표전화 : 1644-4425
■ 운영시간 : 매일 오전 10시~익일 02시

해당 단톡방은 설치 전까지만 이용가능하며,
설치 후에는 고객지원 번호 "1644-4425" 또는 설치 후 발송되는 해피톡을 이용하여 응대드리는 점 참고부탁드립니다.`;

export const LAST_INFO = `안녕하세요 대표님 상담내용 정리하여 전달드렸습니다. 추가적인 문의사항의 경우 카톡방 혹은 전화주시면 빠르게 응대드릴 수 있도록 하겠습니다!`;
export const YOUTUBE_INFO = `[유튜브 소개영상]
https://www.youtube.com/watch?v=NIWGkOArYCs`;
