import html2canvas from 'html2canvas';

// import {saveEstimateByRecord} from '../services/EstimateService';

export function getToLocalString(value: string | number, isPreview?: boolean, unit?: string) {
  if (isPreview && Number(value) === 0) {
    return '-';
  }
  if (value === '') {
    return value;
  }
  if (unit != null) {
    return Number(value).toLocaleString() + unit;
  }
  return Number(value).toLocaleString();
}

export function saveImageInClipBoard(selector: any, id: string) {
  setImage(selector, id);
  html2canvas(selector).then((canvas) => {
    canvas.toBlob(blob => {
      const item = new ClipboardItem({'image/png': blob as Blob});
      navigator.clipboard.write([item]).then(() => {
        console.log('이미지가 클립보드에 복사되었습니다.');
      }).catch(err => {
        console.error('클립보드에 이미지를 복사하는 동안 오류가 발생했습니다.', err);
      });
    });
  });
}

export async function setImage(selector: any, id: string) {
  const canvas = await html2canvas(selector, {
    logging: false,
    allowTaint: true,
    scale: window.devicePixelRatio,
    scrollY: 0,
    scrollX: 0,
    useCORS: true,
    backgroundColor: '#ffffff',
  })
  const link = window.document.createElement('a');
  link.href = canvas.toDataURL('image/png', 1);
  // saveEstimateByRecord({recordId: id, fileLink: link.href});
  return link;
}

export function saveImage(selector: any, id: string) {
  setImage(selector, id).then((link: any) => {
    link.download = '할부 견적서.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  })
}

export function copyText(text: string) {
  navigator.clipboard.writeText(text);
}

export async function copyLocalImageToClipboard(imagePath: string): Promise<void> {
  try {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    const item = new ClipboardItem({ 'image/png': blob });
    await navigator.clipboard.write([item]);
  } catch (error) {
    console.error('Error copying image to clipboard:', error);
  }
}
