export const BackgroundClose = ({onClickClose}: { onClickClose: () => void }) => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',

        position: 'fixed',
        left: 0,
        top: 0,

        backgroundColor: 'rgba(0, 0, 0, 0)',
        zIndex: -1,
        cursor: 'default',
      }}
      onClick={onClickClose}
    />
  )
}
